import { Box, Button, CircularProgress, withStyles } from '@material-ui/core';
import { CustomTheme, Loader, ResumeBuilder as ResumeBuilderContent, Typography } from '@orijinworks/frontend-commons';
import React from 'react';
import styles from './resume-builder.styles';
import { CustomIcons, icons } from '../../../shared/components/custom-icons';
import { useResumeBuilderHook } from './useResumeBuilderHook';
import { withRouter } from 'react-router-dom';
import UnleashProvider from '../../../core/unleash/unleashProvider';
import { UnleashService } from '../../../services';

const ResumeBuilder = (props) => {
  const { classes } = props;
  const {
    data,
    isLoading,
    isFetchingPDF,
    stateOptions,
    skillOptions,
    degreeOptions,
    learnerName,
    onViewPDFClick,
    onSave,
    onGoBackClick,
  } = useResumeBuilderHook(props);
  return (
    <Box>
      <UnleashProvider flagName={UnleashService.FLAGS.EDIT_RESUME}>
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <>
            <Box>
              <Button id="go-back-btn" onClick={onGoBackClick} className={[classes.allResumeBtn, classes.btn].join(' ')}>
                <CustomIcons icon={icons.ArrowBackIosNewIcon} className={classes.backIcon} />
                &nbsp; All Resumes
              </Button>
            </Box>
            <Box className={classes.builderContainer}>
              <Box className={classes.editingBackground}>
                <Box>
                  <Box>
                    <Typography variant='overlineLarge'>You're Editing</Typography>
                    <Typography variant="h1" id="resume-title">
                      {`${learnerName}'s `}
                      Resumé
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    id="view-pdf-btn"
                    onClick={onViewPDFClick}
                    disabled={isFetchingPDF}
                    className={[classes.viewPdfBtn, classes.btn].join(' ')}
                  >
                    {isFetchingPDF && <CircularProgress size={20} color="inherit" />}
                    {!isFetchingPDF && 'View as PDF'}
                  </Button>
                </Box>
              </Box>
              <Box className={classes.resumeBuliderContents}>
                <ResumeBuilderContent
                  {...data}
                  skillsOptions={skillOptions}
                  stateOptions={stateOptions}
                  degreeOptions={degreeOptions}
                  onSave={onSave}
                  allowReordering
                  personInformationProps={{
                    personalInformationTitle: 'Personal Information',
                    firstNameLabel: 'First Name',
                    lastNameLabel: 'Last Name',
                    personalInformationLocation: 'Location',
                    cityLabel: 'City',
                    stateLabel: 'State',
                    contactInformation: 'Contact Information',
                    phoneNumberLabel: 'Phone Number',
                    emailLabel: 'Email',
                    aboutMeLabel: 'About Me',
                    aboutMeDescriptionLabel: 'Description',
                    aboutMeIntroductionLabel: 'Introduction',
                    characterLimitLabel: 'Character Limit',
                    saveLabel: 'Save',
                    cancelLabel: 'Cancel',
                  }}
                  workExperienceProps={{
                    labels: {
                      reorganizeText: 'Reorganize',
                      workExperienceTitle: 'Work Experience',
                      addWorkExperienceLabel: 'Add Work Experience',
                      characterLimitLabel: 'Character Limit',
                      saveLabel: 'Save',
                      cancelLabel: 'Cancel',
                      jobTitleLabel: 'Job Title',
                      companyLabel: 'Company',
                      locationLabel: 'Location',
                      cityLabel: 'City',
                      stateLabel: 'State',
                      startDateLabel: 'Start Date',
                      endDateLabel: 'End Date',
                      monthLabel: 'Month',
                      yearLabel: 'Year',
                      monthOptions: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ],
                      currentlyWorkHereLabel: 'Currently Work Here',
                      jobDescriptionLabel: 'Job Description',
                      jobTitleCharacterLimitLabel: 'Character Limit',
                      companyCharacterLimitLabel: 'Character Limit',
                      workExperienceCharacterLimitLabel: 'Character Limit',
                    },
                  }}
                  achievementsProps={{
                    labels: {
                      reorganizeText: 'Reorganize',
                      achievementsTitle: 'Achievements',
                      addAchievementLabel: 'Add Achievement',
                      certificateNameLabel: 'Certificate Name',
                      organizationLabel: 'Issuing Organization',
                      issueDateLabel: 'Issue Date',
                      descriptionLabel: 'Description',
                      descriptionHelperText: 'Description Question',
                      certificateNameCharacterLimitLabel: 'Character Limit',
                      descriptionCharacterLimitLabel: 'Character Limit',
                      organizationCharacterLimitLabel: 'Character Limit',
                      monthLabel: 'Month',
                      yearLabel: 'Year',
                      saveLabel: 'Save',
                      cancelLabel: 'Cancel',
                      monthOptions: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ],
                    },
                  }}
                  educationProps={{
                    labels: {
                      reorganizeText: 'Reorganize',
                      educationTitle: 'Education',
                      addEducationLabel: 'Add Education',
                      degreeLabel: 'Degree',
                      fieldOfStudyLabel: 'Field of Study',
                      schoolLabel: 'School Name',
                      graduationYearLabel: 'Graduation Year',
                      characterLimitLabel: 'Character Limit',
                      saveLabel: 'Save',
                      cancelLabel: 'Cancel',
                    },
                  }}
                  skillProps={{
                    labels: {
                      reorganizeText: 'Reorganize',
                      skillsTitle: 'Skills',
                      addSkillLabel: 'Add Skill',
                      skillLabel: 'Skill',
                      characterLimitLabel: 'Character Limit',
                      saveLabel: 'Save',
                      cancelLabel: 'Cancel',
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </UnleashProvider>
    </Box>
  );
};

export default withStyles(styles)(withRouter(ResumeBuilder));
