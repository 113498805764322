import { useState, useEffect } from 'react';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { updateManagementAttributes, getManagementAttributes } from '../../../../util/APIUtils';

export const useContactManagementHook = ({ locationId }) => {
  const [allowContactRequestsAttribute, setAllowContactRequestsAttribute] = useState({});
  const [adminCanApproveContactsAttribute, setAdminCanApproveContactsAttribute] = useState({});
  const [isMaxContactsPerLearnerLimitEnabled, setIsMaxContactsPerLearnerLimitEnabled] = useState({});
  const [isMaxContactsPerLearnerLimitEnabledUpdated, setIsMaxContactsPerLearnerLimitEnabledUpdated] = useState(false);
  const [allowContactRequestsAttributeUpdated, setAllowContactRequestsAttributeUpdated] = useState(false);
  const [adminCanApproveContactsAttributeUpdated, setAdminCanApproveContactsAttributeUpdated] = useState(false); // This state is used to handle the initial mount of the component
  const [maxContactsPerLearnerLimit, setMaxContactsPerLearnerLimit] = useState({});
  const [groupScheduleDefault, setGroupScheduleDefault] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(false);
  useEffect(() => {
    fetchContactManagementAttributes();
  }, [locationId]);

  useEffect(() => {
    if (allowContactRequestsAttributeUpdated) {
      handleUpdateAllowContactRequestsAttribute();
    }
  }, [allowContactRequestsAttribute, allowContactRequestsAttributeUpdated]);

  useEffect(() => {
    if (adminCanApproveContactsAttributeUpdated) {
      handleUpdateAdminCanApproveContactsAttribute();
    }
  }, [adminCanApproveContactsAttribute, adminCanApproveContactsAttributeUpdated]);

  useEffect(() => {
    if (isMaxContactsPerLearnerLimitEnabledUpdated) {
      handleUpdateMaxContactsPerLearnerLimitEnabled();
    }
  }, [isMaxContactsPerLearnerLimitEnabled, isMaxContactsPerLearnerLimitEnabledUpdated]);



  const handleAllowContactRequestsAttributeChange = (event) => {
    const newValue = event.target.checked;
    setAllowContactRequestsAttribute({
      ...allowContactRequestsAttribute,
      attributeValue: newValue,
    });
    setAllowContactRequestsAttributeUpdated(true);

    // When contact requests are disabled, force admin approval to true
    if (!newValue) {
      setAdminCanApproveContactsAttribute({
        ...adminCanApproveContactsAttribute,
        attributeValue: true,
      });
      setAdminCanApproveContactsAttributeUpdated(true);
    }
  };

  const handleAdminCanApproveContactsAttributeChange = (event) => {
    setAdminCanApproveContactsAttribute({
      ...adminCanApproveContactsAttribute,
      attributeValue: event.target.checked,
    });
    setAdminCanApproveContactsAttributeUpdated(true);
  };

  const handleIsMaxContactsPerLearnerLimitEnabledChange = async (event) => {
    setIsMaxContactsPerLearnerLimitEnabled({
      ...isMaxContactsPerLearnerLimitEnabled,
      attributeValue: event.target.checked,
    });

    setIsMaxContactsPerLearnerLimitEnabledUpdated(true);
  };

  // when voiceGroupSchedule unleash flag is enabled
  const handleMaxContactsPerLearnerLimitChange_V2 = (newValue) => {
    setMaxContactsPerLearnerLimit((prevState) => ({
      ...prevState,
      attributeValue: newValue,
    }));
    handleUpdateMaxContactsPerLearnerLimit_V2(newValue);
  };

  const handleMaxContactsPerLearnerLimitChange = (event) => {
    setMaxContactsPerLearnerLimit({
      ...maxContactsPerLearnerLimit,
      attributeValue: event.target.value,
    });
  };

  const fetchContactManagementAttributes = async () => {
    try {
      // Reset state
      setAllowContactRequestsAttribute({});
      setAdminCanApproveContactsAttribute({});
      setAllowContactRequestsAttributeUpdated(false);
      setAdminCanApproveContactsAttributeUpdated(false);
      setIsMaxContactsPerLearnerLimitEnabledUpdated(false);
      setIsMaxContactsPerLearnerLimitEnabled({});
      setGroupScheduleDefault({});
      
      const managementAttributes = await getManagementAttributes(locationId);
      
      if (managementAttributes?.locationAttributes && Array.isArray(managementAttributes.locationAttributes)) {
        managementAttributes.locationAttributes.forEach((attribute) => {
          switch (attribute.attributeName) {
            case VOICE_CALL.ALLOW_CONTACT_REQUESTS_ATTRIBUTE_KEY:
              setAllowContactRequestsAttribute({
                attributeId: attribute.attributeId,
                attributeName: attribute.attributeName,
                attributeValue: attribute.attributeValue === 'true',
              });
              break;
            case VOICE_CALL.ADMIN_CAN_APPROVE_CONTACTS_ATTRIBUTE_KEY:
              setAdminCanApproveContactsAttribute({
                attributeId: attribute.attributeId,
                attributeName: attribute.attributeName,
                attributeValue: attribute.attributeValue === 'true',
              });
              break;
            
            case VOICE_CALL.MAX_CONTACTS_PER_LEARNER_ENABLED_LIMIT_ATTRIBUTE_KEY:
              setIsMaxContactsPerLearnerLimitEnabled({
                attributeId: attribute.attributeId,
                attributeName: attribute.attributeName,
                attributeValue: attribute.attributeValue === 'true',
              });
              break;
            
            case VOICE_CALL.MAX_CONTACTS_PER_LEARNER_LIMIT_ATTRIBUTE_KEY:
              setMaxContactsPerLearnerLimit({
                attributeId: attribute.attributeId,
                attributeName: attribute.attributeName,
                attributeValue: parseInt(attribute.attributeValue),
              });
              break;
            
            case VOICE_CALL.GROUP_SCHEDULE_DEFAULT_ATTRIBUTE_KEY:
              setGroupScheduleDefault({
                attributeId: attribute.attributeId,
                attributeName: attribute.attributeName,
                attributeValue: attribute.attributeValue,
              });
              break;
            
            default:
              break;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  const handleUpdateAllowContactRequestsAttribute = async () => {
    try {
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [allowContactRequestsAttribute],
      });
      setSnackbarMessage('Contact Request setting updated successfully');
      setIsSuccessSnackbar(true);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to update Contact Request setting');
      setIsSuccessSnackbar(false);
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  const handleUpdateAdminCanApproveContactsAttribute = async () => {
    try {
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [adminCanApproveContactsAttribute],
      });
      setSnackbarMessage('Contact Approval setting updated successfully');
      setIsSuccessSnackbar(true);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to update Contact Approval setting');
      setIsSuccessSnackbar(false);
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  const handleUpdateMaxContactsPerLearnerLimitEnabled = async () => {
    try {
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [isMaxContactsPerLearnerLimitEnabled],
      });
    } catch (error) {
      console.error(error);
    }
  };

  // when voiceGroupSchedule unleash flag is enabled
  const handleUpdateMaxContactsPerLearnerLimit_V2 = async (newValue) => {
    try {
      if (newValue < VOICE_CALL.MIN_NO_OF_CONTACTS_PER_LEARNER || newValue > VOICE_CALL.MAX_NO_OF_CONTACTS_PER_LEARNER) {
        setSnackbarMessage(
          `Max contacts per learner limit should be between ${VOICE_CALL.MIN_NO_OF_CONTACTS_PER_LEARNER} and ${VOICE_CALL.MAX_NO_OF_CONTACTS_PER_LEARNER}`
        );
        setIsSuccessSnackbar(false);
        setSnackbarOpen(true);
        return;
      }
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [{ ...maxContactsPerLearnerLimit, attributeValue: newValue }],
      });
      setIsSuccessSnackbar(true);
      setSnackbarMessage('Max contacts per learner limit updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateMaxContactsPerLearnerLimit = async () => {
    try {
      if (
        !maxContactsPerLearnerLimit.attributeValue ||
        maxContactsPerLearnerLimit.attributeValue <= 0 ||
        maxContactsPerLearnerLimit.attributeValue > 20
      ) {
        setSnackbarMessage('Max contacts per learner limit should be between 1 and 20');
        setIsSuccessSnackbar(false);
        setSnackbarOpen(true);
        return;
      }
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [maxContactsPerLearnerLimit],
      });
      setIsSuccessSnackbar(true);
      setSnackbarMessage('Max contacts per learner limit updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return {
    handleAllowContactRequestsAttributeChange,
    handleAdminCanApproveContactsAttributeChange,
    handleIsMaxContactsPerLearnerLimitEnabledChange,
    handleMaxContactsPerLearnerLimitChange_V2,
    handleUpdateMaxContactsPerLearnerLimit_V2,
    handleMaxContactsPerLearnerLimitChange,
    handleUpdateMaxContactsPerLearnerLimit,
    isMaxContactsPerLearnerLimitEnabled,
    maxContactsPerLearnerLimit,
    allowContactRequestsAttribute,
    adminCanApproveContactsAttribute,
    groupScheduleDefault,
    snackbarMessage,
    snackbarOpen,
    handleCloseSnackbar,
    isSuccessSnackbar,
  };
};
