const styles = {
  tableHeader: {
    '& .MuiToolbar-root': {
      paddingLeft: 0
    }
  },
  container: {
    width: '100%'
  },
  zoomContainer: {
    width: '100%'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

export default styles;
