import request from '../../../../util/APIUtils';
import { GATEWAY_BASE_URL } from '../../../../constants';

/**
 * Fetch all programs
 * @returns {Promise} Promise object represents the programs
 */
export const fetchPrograms = async () => {
  return request({
    url: `${GATEWAY_BASE_URL}/manage/programs?delivery_method=D2L`,
    method: 'GET',
    throw400Error: true
  });
};

/**
 * Create a new program
 * @param {FormData} programData - Form data containing program details
 * @returns {Promise} Promise object represents the created program
 */
export const createProgram = async (programData) => {
  return request({
    url: `${GATEWAY_BASE_URL}/manage/programs`,
    method: 'POST',
    body: programData,
    throw400Error: true
  });
};
