import { useFlag, useFlagsStatus, useUnleashClient, useVariant } from '@unleash/proxy-client-react';

/**
 * @name useUnleashHook
 * @param {string} flagName
 * @returns {object} - { isFlagEnabled, getClient, getVariant, getFlagsStatus }
 */

const useUnleashHook = (flagName) => {
  const enabled = useFlag(flagName);
  const unleashClient = useUnleashClient();
  const variant = useVariant(flagName);
  const { flagsReady, flagsError } = useFlagsStatus();

  const isFlagEnabled = () => enabled;
  const getVariant = () => variant;
  const getClient = () => unleashClient;
  const getFlagsStatus = () => ({ flagsReady, flagsError });

  return {
    isFlagEnabled,
    getClient,
    getVariant,
    getFlagsStatus,
  };
};

export default useUnleashHook;
