//
export const DIRECT_MESSAGE = 'contacts';
export const ANNOUNCEMENT = 'announcements';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const THREAD_TYPE = {
  [DIRECT_MESSAGE]: 'Message',
  [ANNOUNCEMENT]: 'Announcement',
};

export const LEARNER_ROLE = 'Learner';

export const INACTIVE_GROUP_ERROR = 'Removing all learners will deactivate this Learner Group';
export const DUPLICATE_LEARNER_ERROR = 'Name already used for one of your Learner Groups. Please choose a unique name';
export const UNREACHABLE_MEMBER_ERROR = 'This learner cannot receive announcements due to deactivation or location change';
export const UNREACHABLE_MEMBER_MESSAGE_ERROR =
  'Some learners will not receive this announcement due to deactivation or location change';
export const SETTINGS_LOCATION_TYPE = {
  FACILITY: 'facility',
  LEARNER: 'learner',
};
export const FACILITY = 'Correctional Facility';
export const SNACK_BAR_TIMEOUT = 6000;
export const SNACK_BAR_SUCCESS = 'success';
export const SNACK_BAR_ERROR = 'error';
export const MY_PROFILE_QUESTION_SECTIONS = {
  DEMOGRAPHIC: 'Demographic',
  PERSONALITY: 'Personal',
};

export const COMMUNICATION_TABS = {
  messages: 0,
  announcement: 1,
};

export const LEARNER_BLOCKED_COPY = 'Reply or Unblock to allow the learner to send additional messages';

export const UNBLOCK_LEARNER_AUTO_REPLY = '***AUTO-REPLY: You may send more messages now***';

export const EVENTS = {
  UNREAD_COUNT: 'unreadCount',
  LOCATION_CHANGE: 'locationChange'
};

export const LOCATION_CACHE_KEY = 'locationCache';
export const LOCATION_TYPE = {
  FACILITY: 'FACILITY',
  HOUSING_UNIT: 'HOUSING_UNIT',
  KEY: 'LOCATION_TYPE',
};
export const HU_CACHE_KEY = 'HUCache';
export const VOICE_CALLS_LOADER = 'voiceCallsScheduleStatusLoader';
export const MINIMUM_VOICE_CALL_SCHEDULE_TIME = 30;
export const MINIMUM_ENTERTAINMENT_SCHEDULE_TIME = 30;
export const PDQ_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SUBMITTED: 'SUBMITTED',
};

export const PDQ_NOT_STARTED_COPY = 'This learner has not started the Milestone Mapper.';
export const PDQ_IN_PROGRESS_COPY = "This learner's Milestone Mapper is still in progress";
export const VOICE_CALLS_FEATURE_KEY = 30;
export const VOICE_CALLS_FEATURE_NAME = 'Voice_Calls_Schedule';

export const SUCCESS_MESSAGE_SENT = 'Message Sent To';
export const FAILED_MESSAGE_SENT = 'Failed To Send Message To';

export const RECENT_MESSAGE = 'recent_message';

export const TIMEZONE = 'TimeZone';

export const COMMUNICATION_TYPE = {
  MESSAGE: 'Message',
  ANNOUNCEMENT: 'Announcement',
};
export const TABS = {
  USER: 0,
  PATHS: 1,
  FORMS: 2,
  SETTINGS: 3,
  STAFF: 4,
  COMMUNICATIONS: 5,
  SERVICES: 6,
  FA: 7,
  FNF: 8,
  CERTIFICATES: 9,
  RESUME: 10,
};

export const RESUME_DOWNLOAD_FORMATS = { DOCX: 'docx', PDF: 'pdf' };
export const USER_PAIR_DEVICES_ERROR = 'Could not reset paired devices. Please try again.';

export const DELETE_FRIENDS_AND_FAMILY_CONTACT_ERROR = 'Could not delete connections. Please try again.';
export const MAX_DAYS_ALLOWED = 365;
export const LOCATION_TYPE_IDS = {
  HOUSING_UNIT: 15,
  FACILITY: 3
}

export const LOCATION_STATUSES = {
  DEACTIVATED: 'DEACTIVATED',
  MOVED: 'MOVED',
  ACTIVE: 'ACTIVE'
}

export const AVAILABILITY_TYPES = {
  ALL_LEARNERS: 'ALL_LEARNERS',
  HIDDEN: 'HIDDEN',
  BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE: 'BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE',
  BY_ASSIGNMENT_DEACTIVATE_ACCOUNT_ON_MOVE: 'BY_ASSIGNMENT_DEACTIVATE_ACCOUNT_ON_MOVE'
}

export const SISENCE = {
  ID: 'sisence-menu',
  URL: 'https://apds.sisense.com/app/account/login', 
}

export const COURSE_DURATION = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
  LONG: 'LONG',
  VARIES: 'VARIES'
};

export const COURSE_DURATION_LABELS = {
  [COURSE_DURATION.SHORT]: 'Short (< 5 hours)',
  [COURSE_DURATION.MEDIUM]: 'Medium (5-10 hours)',
  [COURSE_DURATION.LONG]: 'Long (> 10 hours)',
  [COURSE_DURATION.VARIES]: 'Varies'
};

export const PROGRAM_CATEGORY = {
  EDUCATION: 'EDUCATION',
  WORKFORCE: 'WORKFORCE',
  REHABILITATION: 'REHABILITATION',
  PERSONAL_DEVELOPMENT: 'PERSONAL_DEVELOPMENT'
};

export const PROGRAM_CATEGORY_LABELS = {
  [PROGRAM_CATEGORY.EDUCATION]: 'Education',
  [PROGRAM_CATEGORY.WORKFORCE]: 'Workforce',
  [PROGRAM_CATEGORY.REHABILITATION]: 'Rehabilitation',
  [PROGRAM_CATEGORY.PERSONAL_DEVELOPMENT]: 'Personal Development'
};

export const CONTENT_PROVIDER = {
  WRAP : 'WRAP',
  ORIJIN : 'ORIJIN',
  TOPUCU : 'TOPUCU',
  INTERPLAY : 'INTERPLAY',
  SKILLSOFT : 'SKILLSOFT',
  TED_TALKS : 'TED_TALKS',
  MASTERCLASS : 'MASTERCLASS',
  AA_GRAPEVINE : 'AA_GRAPEVINE',
  SKILLS_180 : 'SKILLS_180',
  ORIJIN_CAREER_ACADEMY : 'ORIJIN_CAREER_ACADEMY',
  JURISDICTIONAL_CONTENT : 'JURISDICTIONAL_CONTENT',
  ADVOCATES_FOR_HUMAN_POTENTIAL : 'ADVOCATES_FOR_HUMAN_POTENTIAL'
};

export const CONTENT_PROVIDER_LABELS = {
  [CONTENT_PROVIDER.WRAP]: 'Wrap',
  [CONTENT_PROVIDER.ORIJIN]: 'Orijin',
  [CONTENT_PROVIDER.TOPUCU]: 'Topucu',
  [CONTENT_PROVIDER.INTERPLAY]: 'Interplay',
  [CONTENT_PROVIDER.SKILLSOFT]: 'Skill Soft',
  [CONTENT_PROVIDER.TED_TALKS]: 'Ted Talks',
  [CONTENT_PROVIDER.MASTERCLASS]: 'Masterclass',
  [CONTENT_PROVIDER.AA_GRAPEVINE]: 'AA Grapevine',
  [CONTENT_PROVIDER.SKILLS_180]: 'Skills 180',
  [CONTENT_PROVIDER.ORIJIN_CAREER_ACADEMY]: 'Orijin Career Academy',
  [CONTENT_PROVIDER.JURISDICTIONAL_CONTENT]: 'Jurisdictional Content',
  [CONTENT_PROVIDER.ADVOCATES_FOR_HUMAN_POTENTIAL]: 'Advocates for Human Potentials'
};