export default (theme) => ({
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldLabel: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.colors.lightCerulean,
    letterSpacing: '1.8px',
    textTransform: 'uppercase',
  },
  characterLimit: {
    color: theme.palette.colors.grey100,
    fontSize: '16px',
    fontStyle: 'italic',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    lineSpacing: '1.5',
  },
  textFieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(6),
    marginLeft: theme.spacing(3),
  },
  labelText: {
    fontWeight: 'bold',
    color: 'var(--primary-deep)',
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    alignSelf: 'stretch',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      '& input': {
        padding: '12px 16px',
        textOverflow: 'ellipsis',
      },
      width: '100%',
      position: 'relative',
    },
  },
  longDescriptionField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px !important',
      '& textarea': {
        padding: '12px 16px',
        paddingRight: '40px',
      },
    },
  },
  editIcon: {
    position: 'absolute',
    bottom: '12px',
    right: '12px',
    fontSize: '20px',
    color: theme.palette.greyScale.slateGray,
    pointerEvents: 'none',
  },
});
