import {
  API_BASE_URL,
  GATEWAY_BASE_URL,
  HERMES_BASE_URL,
  JANUS_BASE_URL,
  getJanusToken,
  APDS_GATEWAY_BASE_URL,
  SISENSE_CONFIGURATION,
  CAREER_BASE_URL,
  PDQ_TEMPLATE_ID,
  CDN_BASE_URL,
} from '../constants';
import { KeycloakService } from '../keycloak';
import { getAccessToken, getUseOktaAuth0, handleLogout } from '../v2/core/auth/utils';
import store, { showToast, showHideSessionModal } from '../v2/core/store/.';
import { COMMUNICATION_TYPE } from '../v2/services/constants';

export default async function request(options) {
  const headers = new Headers();
  headers.append('Authorization', 'Bearer ' + (await getAccessToken()));
  const janusToken = getJanusToken();
  if (janusToken) {
    headers.append('Janus-Authorization-Token', janusToken);
  }

  if (!(options.body instanceof FormData)) {
    headers.append('Content-type', 'application/json');
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  if (!getUseOktaAuth0()) {
    KeycloakService.Keycloak.updateToken(60)
      .then(function (refreshed) {
        // token refreshed or else token valid
      })
      .catch(function (e) {});
  }

  const logout = () => {
    store.dispatch(
      showHideSessionModal({
        onConfirm: () => {
          sessionStorage.setItem('isLogoutInitiated', true);
          handleLogout(KeycloakService.Keycloak);
        },
        isOpen: true,
        title: 'Session Expired',
        message: 'Your session has expired. Please login again to continue using APDS-Horizon.',
      })
    );
  };

  return fetch(options.url, options)
    .then((response) => {
      const contentType = response.headers.get('content-type');
      const throw400Error = Boolean(options.throw400Error);
      switch (response.status) {
        case 200:
        case 201:
        case 400:
          if (response.status === 400 && throw400Error) {
            throw response;
          } else if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then((data) => {
              // process your JSON data further
              return data;
            });
          } else if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
            return response.blob().then((blob) => {
              return blob;
            });
          } else {
            return response.text().then((text) => {
              // this is text, do something with it
              return text;
            });
          }
        case 403:
          if (contentType && contentType.indexOf('application/json') !== -1) {
            response.json().then((data) => {
              if (data.rolesPermissionForbidden) {
                store.dispatch(showToast('You are not authorized to perform this request'));
                throw data;
              } else {
                logout();
              }
            });
          } else {
            logout();
          }
          throw response;
        case 204:
          return response
        default:
          response
            .json()
            .then((res) => {
              if (res.hasOwnProperty('staffDetails')) {
                return;
              }
              if (res.errorDetails && res.errorDetails.includes('/messages')) {
                return;
              }
              store.dispatch(showToast('Sorry, something went wrong. Please refresh the page and try again.'));
            })
            .catch((err) => {
              throw err;
            });
          throw response;
      }
    })
    .catch((err) => {
      if (err.name === 'AbortError') {
        console.log('Request Cancelled.');
      }
      throw err;
    });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + '/oauth/token',
    method: 'POST',
    body: loginRequest,
  });
}

export function sisenseLogout() {
  try {
    const { url } = SISENSE_CONFIGURATION[0];
    navigator.sendBeacon(`${url}/api/auth/logout`);
  } catch (error) {
    console.log(`sisense Error:::`, error);
  }
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + '/auth/signup',
    method: 'POST',
    body: JSON.stringify(signupRequest),
  });
}

export function checkUsernameAvailability(username) {
  return request({
    url: API_BASE_URL + '/user/checkUsernameAvailability?username=' + username,
    method: 'GET',
  });
}

export function checkEmailAvailability(email) {
  return request({
    url: API_BASE_URL + '/user/checkEmailAvailability?email=' + email,
    method: 'GET',
  });
}

//Get user context from authserver
//Important application information such as role, userid, etc.
export function getCurrentUser() {
  return request({
    url: GATEWAY_BASE_URL + '/checkaccess',
    method: 'GET',
  });
}

// Get location address details
export function getLocationDetails(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/${locationId}/details`,
    method: 'GET',
  });
}

export function getServiceCodes(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/${locationId}/serviceCodes`,
    method: 'GET',
  });
}

export function validateServiceCodes(postBody) {
  return request({
    url: GATEWAY_BASE_URL + `/service/validatePartnerCodes`,
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function resetFacialAuthentication(putBody) {
  return request({
    url: `${GATEWAY_BASE_URL}/faceauth/reset`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function updateLocationDetails(locationId, putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/location/${locationId}/details`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function createNewLocation(putBody) {
  return request({
    url: GATEWAY_BASE_URL + '/location/createlocationv2',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function provisionCoreServices(postBody, locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/${locationId}/provisionCoreServices`,
    method: 'PUT',
    body: JSON.stringify(postBody),
  });
}

export function provisionServicesContentConfig(postBody, locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/${locationId}/service/configure`,
    method: 'PUT',
    body: JSON.stringify(postBody),
  });
}

//Gets timeontask information
export function gettimeontask(postBody) {
  let req = {
    url: GATEWAY_BASE_URL + '/gettimeontask',
    method: 'POST',
    body: JSON.stringify(postBody),
  };

  return request({
    ...req,
  });
}

//Gets timeontask information
export function listevents(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/listevents',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

//Gets user profile information
//metadata, such as Name, location, etc.
//Can this this be broken down further, like ../pathways ../achievements??
export function getUserProfile(username) {
  return request({
    url: API_BASE_URL + '/users/' + username,
    method: 'GET',
  });
}

{
  /*****  UMBRA API UTILITIES  *****/
}

export function listUsers(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/listusers',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function getUsers({ locationId, roleIds, userStatus }) {
  return request({
    url: GATEWAY_BASE_URL + `/users?locationIds=${locationId}&userStatus=${userStatus}&roleIds=${roleIds}`,
    method: 'GET',
  });
}

export function getPathFromLocation(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/resource/path?locationId=${locationId}`,
    method: 'GET',
  });
}

export function getAssignablePrograms(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/programs/assignable/list?locationKey=${locationId}`,
    method: 'GET',
  });
}

export function getUnAssignablePrograms(locationId, learnerKeys) {
  return request({
    url: GATEWAY_BASE_URL + `/programs/unassignable/list?locationKey=${locationId}&learnerKeys=${learnerKeys}`,
    method: 'GET',
  });
}

export function assignPathToUser(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/path/pathAssignment',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function assignProgramToUser(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/programs/assign',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function unassignProgramFromUser(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/programs/unassign',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function unAssignPathToUser(postBody, deleteBundles) {
  return request({
    url: GATEWAY_BASE_URL + `/path/unassignment?deletebundles=${deleteBundles}`,
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function omnisearch(postBody, isV2 = false, searchType = 'active') {
  return request({
    // isV2 determines the API version to use for the omnisearch. When true, it uses the v2 endpoint.
    url: GATEWAY_BASE_URL + `${isV2 ? '/v2/omnisearch/persons' : '/omnisearch'}?searchType=${searchType}`,
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function createUsers(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/createuser',
    method: 'POST',
    body: postBody,
  });
}

export function createAdminUsers(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/umbra/createadminuser',
    method: 'POST',
    body: postBody,
  });
}

export function healthCheck() {
  return request({
    url: GATEWAY_BASE_URL + '/healthcheck',
    method: 'GET',
  });
}

export function getSchedule(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/schedule/${locationId}`,
    method: 'GET',
  });
}

export function getManagementAttributes(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/attribute/${locationId}`,
    method: 'GET',
  });
}

export function updateManagementAttributes(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/attribute/update`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function deleteManagementAttributes(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/location/attribute/delete`,
    method: 'DELETE',
    body: JSON.stringify(putBody),
  });
}

export function getUserAttributes(userId) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/user/attribute/${userId}`,
    method: 'GET',
  });
}

export function updateUserAttributes(putBody) {
  return request({
    url: GATEWAY_BASE_URL + '/setting/user/attribute/update',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function getFeatures(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/feature/${locationId}`,
    method: 'GET',
  });
}

export function getLearnerFeatures(userName) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${userName}/locationFeature`,
    method: 'GET',
  });
}

export function getTimezone(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/timeZone?locationId=${locationId}`,
    method: 'GET',
  });
}

export function updateTimezone(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/location/timeZone`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function getUserAccess(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/location/timeZone?locationId=${locationId}`,
    method: 'GET',
  });
}

export function updateUserAccess(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/users/update`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function updateFeatures(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/feature/update`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function updateLearnerFeatures(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/user/feature/update`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function updateSchedule(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/schedule/update`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function listLocations(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/listlocations',
    method: 'POST',
    body: postBody,
  });
}

export function qryuser(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/qryuser',
    method: 'POST',
    body: postBody,
  });
}
export function updateServiceLocationFlag(putBody) {
  return request({
    url: GATEWAY_BASE_URL + '/location/service',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function qrylocation(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/qrylocation',
    method: 'POST',
    body: postBody,
  });
}

export function updateUsers(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/updateusers',
    method: 'POST',
    body: postBody,
  });
}

export function provisionService(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/provisionservice',
    method: 'POST',
    body: postBody,
  });
}

export function callDummyApi() {
  return request({
    url: 'http://dummy.restapiexample.com/api/v1/employee/1',
    method: 'GET',
  });
}

export function getFormList(locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/form/list?locationId=${locationId}`,
    method: 'GET',
  });
}

export function deleteForm(locationId, formList) {
  return request({
    url: GATEWAY_BASE_URL + `/form?ids=${formList.join(',')}&locationId=${locationId}`,
    method: 'DELETE',
  });
}

export function deleteFormSubmissions(formId, submissionIds) {
  return request({
    url: GATEWAY_BASE_URL + `/form/submission?ids=${submissionIds.join(',')}&formId=${formId}`,
    method: 'DELETE',
  });
}

export function getFormAssemblyFormsList() {
  return request({
    url: GATEWAY_BASE_URL + `/form/import`,
    method: 'GET',
  });
}

export function importFormInSelectedLocation(locationId, formPayload) {
  return request({
    url: GATEWAY_BASE_URL + `/form/imported/save?locationId=${locationId}`,
    method: 'PUT',
    body: JSON.stringify(formPayload),
  });
}

export function setAuthCode(authCode, redirectUrl) {
  return request({
    url: GATEWAY_BASE_URL + `/form/auth?authCode=${authCode}&redirectUrl=${redirectUrl}`,
    method: 'GET',
  });
}

export function getFormSubmissions(formId, locationId) {
  return request({
    url: GATEWAY_BASE_URL + `/form/submission/list?formId=${formId}&locationId=${locationId}`,
    method: 'GET',
  });
}

export function getSubmissionFormExport(formId, locationId, submissionIds) {
  return request({
    url:
      GATEWAY_BASE_URL +
      `/form/submission/export/list?formId=${formId}&locationId=${locationId}&submissionIds=${submissionIds.join(',')}`,
    method: 'GET',
  });
}

export function createForm(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/form',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function updateForm(putBody) {
  return request({
    url: GATEWAY_BASE_URL + '/form',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function toggleFormActivation(putBody) {
  return request({
    url: GATEWAY_BASE_URL + '/form/activate',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

/* <-------------- Messaging -----------------> */
export function fetchSearchLearner(searchkey, location) {
  return request({
    url: HERMES_BASE_URL + `/contacts/search?search_key=${searchkey}&location_id=${location}`,
    method: 'GET',
  });
}

export function getUserResources(searchkey, contentAreaString) {
  return request({
    url:
      APDS_GATEWAY_BASE_URL +
      `student/${searchkey}/resource/list?contentAreas=${contentAreaString !== '' ? contentAreaString : -1}`,
    method: 'GET',
  });
}

export function getUsersPaths(userCollection) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/paths?userIds=${userCollection}`,
    method: 'GET',
  });
}

export function getUserAcheivements(userId, contentAreas, startDate, endDate) {
  return request({
    url:
      APDS_GATEWAY_BASE_URL +
      `student/${userId}/achievement?startDate=${startDate}&endDate=${endDate}&contentAreas=${
        contentAreas !== '' ? contentAreas : -1
      }`,
    method: 'GET',
  });
}

export function getResourceDetails(personId, courseId) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${personId}/resource/${courseId}`,
    method: 'GET',
  });
}

export function getTranscriptData(personId) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${personId}/transcript`,
    method: 'GET',
  });
}

export function getStudentProfile(personId) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${personId}`,
    method: 'GET',
  });
}

export function getStudentTimeOnTaskData(personId, contentAreaString, startDate, endDate) {
  return request({
    url:
      APDS_GATEWAY_BASE_URL +
      `student/${personId}/timeontask?contentArea=${
        contentAreaString !== '' ? contentAreaString : -1
      }&startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
  });
}

export function fetchContacts(locationId, orderBy = 'recent_message') {
  try {
    return request({
      url: `${HERMES_BASE_URL}/v2/threads?location_id=${locationId}&order_by=${orderBy}&sort_by=${
        orderBy === 'recent_message' ? 'desc' : 'asc'
      }`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export function fetchAnnouncementsThreads(locationId, orderBy = 'recent_announcement', limit, beforeId, afterId) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/v2/announcements?locationId=${locationId}${beforeId ? `&before_id=${beforeId}` : ''}${
        afterId ? `&after_id=${afterId}` : ''
      }${limit ? `&limit=${limit}` : ''}&order_by=${orderBy}&sort_by=${orderBy === 'recent_announcement' ? 'desc' : 'asc'}`,
    });
  } catch (error) {}
}

export function fetchAnnouncementsThreadsForUser(userId, orderBy = 'recent_announcement', limit, beforeId, range) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/v2/announcements/${userId}?startDate=${range.startDate}&endDate=${range.endDate}${
        limit ? `&limit=${limit}` : ''
      }&orderBy=${orderBy === 'recent_announcement' ? 'desc' : 'asc'}${beforeId ? `&before_id=${beforeId}` : ''}`,
      method: 'GET',
    });
  } catch (error) {}
}

export async function fetchMessagesFromCommunication(body, { threadKey, beforeId, limit, afterId, signal }) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/messages/admin/thread/${threadKey}?${beforeId ? `before_id=${beforeId}` : ''}${
        afterId ? `&after_id=${afterId}` : ''
      }${limit ? `&limit=${limit}` : ''}`,
      method: 'POST',
      body: JSON.stringify(body),
      signal,
    });
  } catch (error) {
    return [];
  }
}

export async function fetchMessages({ threadKey, beforeId, limit, afterId, signal }) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/messages?thread_key=${threadKey}${beforeId ? `&before_id=${beforeId}` : ''}${
        afterId ? `&after_id=${afterId}` : ''
      }${limit ? `&limit=${limit}` : ''}`,
      method: 'GET',
      signal,
    });
  } catch (error) {
    return [];
  }
}

export function fetchAnnouncements({ threadKey, afterId, beforeId, limit, signal }) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/announcements?location_id=${threadKey}${beforeId ? `&before_id=${beforeId}` : ''}${
        afterId ? `&after_id=${afterId}` : ''
      }${limit ? `&limit=${limit}` : ''}`,
      method: 'GET',
      signal,
    });
  } catch (error) {
    return [];
  }
}

export async function getUnreadMessagesByLocationIds(commaSeparatedLocationIds) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/messages/location/unread_count?locations_ids=${commaSeparatedLocationIds}`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export async function fetchMessagingCharacterLimit(location) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/messages/location/${location}/characterslimit`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export async function fetchAnnouncementCharacterLimit(location) {
  try {
    return request({
      url: `${HERMES_BASE_URL}/announcements/location/${location}/characterslimit`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export function postMessage(body) {
  return request({
    url: `${HERMES_BASE_URL}/messages`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function saveDeviceInformation(body) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}device/tracking/awb`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getStudentAdditionalContent(personId, contentAreaString, startDate, endDate) {
  return request({
    url:
      APDS_GATEWAY_BASE_URL +
      `student/${personId}/additionalResults?contentAreas=${
        contentAreaString !== '' ? contentAreaString : -1
      }&startDate=${startDate}&endDate=${endDate}`,
    method: 'GET',
  });
}

export function postAnnouncement(body) {
  return request({
    url: `${HERMES_BASE_URL}/announcements`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function markMessagesRead(threadKey, recentMessageId) {
  return request({
    url: `${HERMES_BASE_URL}/messages?thread_key=${threadKey}&recent_message_id=${recentMessageId}`,
    method: 'PUT',
  });
}

export function markAllMessagesRead(locationId) {
  return request({
    url: `${HERMES_BASE_URL}/messages/mark-read?location_id=${locationId}`,
    method: 'PUT',
  });
}

export function markAnnouncementsRead(recentAnnouncementId) {
  return request({
    url: `${HERMES_BASE_URL}/v2/announcements/${recentAnnouncementId}`,
    method: 'PUT',
  });
}

export function markAllAnnouncementRead(locationId) {
  return request({
    url: `${HERMES_BASE_URL}/announcements/mark-read?location_id=${locationId}`,
    method: 'PUT',
  });
}

export function markAllCommunicationRead(locationId, isMessageActive, isAnnouncementActive) {
  if (isMessageActive) {
    return markAllMessagesRead(locationId);
  } else if (isAnnouncementActive) {
    return markAllAnnouncementRead(locationId);
  }
}

export function fetchSearchLocation(searchkey, location) {
  return request({
    url: HERMES_BASE_URL + `/announcements/locations/${location}/search?search_key=${searchkey}`,
    method: 'GET',
  });
}

export function getUnreadMessagesCount(locationId) {
  return request({
    url: HERMES_BASE_URL + `/messages/unread_count?location_id=${locationId}`,
    method: 'GET',
  });
}

export function deleteAnnouncement(announcementId) {
  return request({
    url: HERMES_BASE_URL + `/announcements?announcement_id=${announcementId}`,
    method: 'DELETE',
  });
}

export function getAchievementDetail(announcementId, locationId) {
  return request({
    url:
      HERMES_BASE_URL +
      `/announcements/status?announcement_id=${announcementId}&location_id=${locationId || 0}&isTargeted=${!Boolean(
        locationId
      )}`,
    method: 'GET',
  });
}

export function getStaffList(locationId, filterActive = true) {
  return request({
    url: GATEWAY_BASE_URL + `/staff/list/${locationId}?filterActive=${filterActive}`,
    method: 'GET',
  });
}

export function getStudentTransaction(personId) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${personId}/transactions?allTransactionTypes=true`,
    method: 'GET',
  });
}

export function createStaff(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/staff/create`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function updateStaff(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/staff/update`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function deactivateStaff(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/staff/deactivate`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function activateStaff(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/staff/reactivate`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function createWhitelisting(postBody) {
  return request({
    url: HERMES_BASE_URL + '/whitelisting',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function updateWhitelisting(putBody) {
  return request({
    url: HERMES_BASE_URL + '/whitelisting/update',
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function findUsers(name, isLearner, locationId) {
  return request({
    url:
      HERMES_BASE_URL + `/contacts/whitelisting/search?search_key=${name}&location_id=${locationId}&is_learner=${isLearner}`,
    method: 'GET',
  });
}

export function getRelationshipUsersList(locationId, limit = 20, page = 1, filters) {
  return request({
    url:
      HERMES_BASE_URL +
      `/whitelisting?location_id=${locationId}&limit=${limit}&page=${page}&adminFullName=${filters.adminFullName}&learnerFullName=${filters.learnerFullName}&learnerDocId=${filters.learnerDocId}&createdDate=${filters.createdDate}&recordStatus=${filters.recordStatus}`,
    method: 'GET',
  });
}

export function createRelationship(payload) {
  return request({
    url: HERMES_BASE_URL + '/whitelisting/create',
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export function deactivateRelationship(relationId, locationId) {
  return request({
    url: HERMES_BASE_URL + `/whitelisting?message_relationship_id=${relationId}&location_id=${locationId}`,
    method: 'DELETE',
  });
}

export function addBalance(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/token/addbalance`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function deductBalance(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/token/deductbalance`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getUsersList(body) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}student/usercards`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function submitEditUserCard(body) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}student/usercards/edit`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getCommunicationListAtLocation(location, range, searchTerm, userName, paginationData, recordStatus) {
  return request({
    url: `${HERMES_BASE_URL}/messages/v2/communications?locations_ids=${location}${`&page=${++paginationData.page}`}&limit=${
      paginationData.limit
    }&search_text=${searchTerm}&user_name=${userName}&start_date=${range.startDate}&end_date=${
      range.endDate
    }&record_status=${recordStatus}&sort_by=${paginationData.sortBy}&order_by=${paginationData.orderBy}`,
    method: 'GET',
  });
}

export function getCommunicationVisualization(location, range, searchTerm, userName) {
  return request({
    url: `${HERMES_BASE_URL}/messages/summary?locations_ids=${location}&user_name=${userName}&search_text=${searchTerm}&start_date=${range.startDate}&end_date=${range.endDate}`,
    method: 'GET',
  });
}

export function getCommunicationListAtProfile(personID) {
  return request({
    url: `${HERMES_BASE_URL}/threads/admin?user_id=${personID}`,
    method: 'GET',
  });
}

export function getStates() {
  return request({
    url: `/assets/json/us-states.json`,
    method: 'GET',
  });
}

export function getFRDevices() {
  return request({
    url: 'frDevices.json',
    method: 'GET',
  });
}

export function getFnFConnections(locationId, pageSize, sortBy, page, data) {
  let queryParams = '';
  if (pageSize) queryParams = queryParams + `?pageSize=${pageSize}`;
  if (sortBy) queryParams = queryParams + `${pageSize ? '&' : '?'}sorts=${sortBy}`;
  if (page) queryParams = queryParams + `${pageSize || sortBy ? '&' : '?'}page=${page}`;
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v2/location/relationships/${locationId}/query${queryParams}`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function getFnFMessages(locationId, pageSize, beforeId, afterId, data) {
  let queryParams = '';
  if (pageSize) queryParams = queryParams + `?pageSize=${pageSize}`;
  if (pageSize && beforeId) queryParams = queryParams + `&beforeId=${beforeId}`;
  if (pageSize && afterId) queryParams = queryParams + `&afterId=${afterId}`;
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/messages/${locationId}/query${queryParams}`,
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function getLearnerMessageHistory(locationId, learnerPersonId, contactPersonId, pageSize, beforeId, afterId) {
  let queryParams = '';
  if (pageSize) queryParams = queryParams + `?pageSize=${pageSize}`;
  if (pageSize && beforeId) queryParams = queryParams + `&beforeId=${beforeId}`;
  if (pageSize && afterId) queryParams = queryParams + `&afterId=${afterId}`;
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/messages/${locationId}/${learnerPersonId}/${contactPersonId}${queryParams}`,
    method: 'GET',
  });
}

export function downloadMessageHistory(locationId, learnerPersonId, contactPersonId) {
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/messages/${locationId}/${learnerPersonId}/${contactPersonId}/file`,
    method: 'GET',
  });
}

export function changeConnectionStatus(locationId, data) {
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/relationships/${locationId}`,
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function getRelationShipStatus(locationId, learnerPersonId, contactPersonId) {
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/relationships/${locationId}/${learnerPersonId}/${contactPersonId}`,
    method: 'GET',
  });
}

export function getCoreServicesCodes() {
  return request({
    url: 'coreServicesCodes.json',
    method: 'GET',
  });
}

export function getMsgSnippet(body, messageID) {
  return request({
    url: `${HERMES_BASE_URL}/messages/admin/message/${messageID}`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getFormLocation(formId) {
  try {
    return request({
      url: GATEWAY_BASE_URL + `/formlocations?formId=${formId}`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export function shareForm(postBody) {
  return request({
    url: GATEWAY_BASE_URL + '/formlocations',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function fetchFormSubmissions(personKey) {
  return request({
    url: `${GATEWAY_BASE_URL}/form/user/submissions?personKey=${personKey}`,
    method: 'GET',
  });
}

export function getRelationshipByUser(userID) {
  return request({
    url: `${HERMES_BASE_URL}/whitelisting/admin/getrelationships?userId=${userID}`,
    method: 'GET',
  });
}

export function getStaffDetails(userID) {
  return request({
    url: `${GATEWAY_BASE_URL}/staff/${userID}`,
  });
}

export function getCommunicationContactListByUser(userID, pageNo, pageSize) {
  return request({
    url: `${HERMES_BASE_URL}/threads/admin?user_id=${userID}6&page_size=${pageSize}&page_idx=${pageNo}`,
    method: 'GET',
  });
}

export function manageMessagingRelationship(postBody) {
  return request({
    url: HERMES_BASE_URL + '/whitelisting/bulkupsert',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function logMsgEvent(postBody) {
  return request({
    url: HERMES_BASE_URL + '/messages/event',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function whiteListStaff(postBody) {
  return request({
    url: HERMES_BASE_URL + '/whitelisting/staff/upsertbylocations',
    method: 'POST',
    body: JSON.stringify(postBody),
  });
}

export function getAvailableRelationships(userID, locationIds, query) {
  return request({
    url: `${HERMES_BASE_URL}/whitelisting/admin/getavailablerelationships?userId=${userID}&locationIds=${locationIds}${
      query ? `&query=${query}` : ''
    }`,
    method: 'GET',
  });
}

export function getTimeOnTaskDetails(userID, contentArea, page_idx = 1, page_size, startDate, endDate) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}student/${userID}/timeontaskresources?startDate=${startDate}&endDate=${endDate}&contentArea=${
      contentArea !== '' ? contentArea : -1
    }&page_idx=${page_idx}&page_size=${page_size}`,
    method: 'GET',
  });
}

export function getTimeOnTaskDetailsAtLocations(locationIds, contentArea, page_idx = 1, page_size, startDate, endDate) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}resources/timeontask?startDate=${startDate}&endDate=${endDate}&contentArea=${
      contentArea !== '' ? contentArea : -1
    }&locationIds=${locationIds}&page_idx=${page_idx}&page_size=${page_size}`,
    method: 'GET',
  });
}

export function exportTimeonTaskAtLocations(locationIds, contentArea, startDate, endDate) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}resources/export/timeontask?startDate=${startDate}&endDate=${endDate}&contentArea=${
      contentArea !== '' ? contentArea : -1
    }&locationIds=${locationIds}`,
    method: 'GET',
  });
}

export function updateBulkLearnerFeature(putBody) {
  return request({
    url: GATEWAY_BASE_URL + `/setting/user/feature/bulkupdate`,
    method: 'PUT',
    body: JSON.stringify(putBody),
  });
}

export function getRolesList() {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/location`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export function getUserFeatureStatus(userIds) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/users/feature?userIds=${userIds}`,
    method: 'GET',
  });
}

export function getRolesAssignmentList(staffName) {
  try {
    return request({
      url: JANUS_BASE_URL + `/user/roles/location?staff_name=${staffName}`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}

export function fetchPermissions() {
  try {
    return request({
      url: `${JANUS_BASE_URL}/permissions`,
      method: 'GET',
    });
  } catch (error) {
    return { cbacConfigFlag: false, permissions: [] };
  }
}

export function getUserFeedbackForms() {
  return new Promise((resolve, reject) => {
    request({
      url: `${APDS_GATEWAY_BASE_URL}feedback/user/admin`,
      method: 'GET',
    })
      .then((_successLog) => resolve(_successLog))
      .catch((_errorLog) => {
        resolve([]);
        console.log(_errorLog);
      });
  });
}

export function submitUserFeedback(selectedValues) {
  const promises = [];
  return new Promise((resolve, reject) => {
    selectedValues.forEach((userInput) => {
      const isReasonAvailable = !!userInput.reason;
      const payload = {
        feedbackKey: userInput.feedbackKey,
        feedbackResponse: userInput.value ? userInput.value + `${isReasonAvailable ? ` - ${userInput.reason}` : ''}` : null,
        meta: userInput.meta,
        isRecurring: userInput.isRecurring,
      };
      promises.push(
        request({
          url: `${APDS_GATEWAY_BASE_URL}feedback/user`,
          method: 'POST',
          body: JSON.stringify(payload),
        })
      );
    });

    Promise.all(promises)
      .then((_successLogs) => {
        resolve(true);
      })
      .catch((_errorLog) => {
        console.log(_errorLog);
        resolve(true);
      });
  });
}

export function noLocationAssociatedPermission() {
  try {
    return request({
      url: `${JANUS_BASE_URL}/user/permissions`,
      method: 'GET',
    });
  } catch (error) {
    return { cbacConfigFlag: false, dashboardPermissions: [] };
  }
}
export function deleteRoleAssignment(locationId, staffName) {
  return request({
    url: JANUS_BASE_URL + `/user/roles/location?location_id=${locationId}&staff_name=${staffName}`,
    method: 'DELETE',
  });
}

export function getAvailableAssignedRoles(locationId) {
  try {
    return request({
      url: JANUS_BASE_URL + `/location/roles?location_id=${locationId}`,
      method: 'GET',
    });
  } catch (error) {
    throw error;
  }
}

export function fetchRoleSummary(roleId) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/details?role_id=${roleId}`,
      method: 'GET',
    });
  } catch (error) {
    throw error;
  }
}

export function saveRoleDetails(roleDetails) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/details`,
      method: 'PUT',
      body: JSON.stringify(roleDetails),
    });
  } catch (error) {}
}

export function fetchUsersList(roleId, locationId) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/users?role_id=${roleId}&location_id=${locationId}`,
      method: 'GET',
    });
  } catch (error) {
    return [];
  }
}
export function addRolesAssignment(payload) {
  try {
    return request({
      url: JANUS_BASE_URL + '/user/roles/location',
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    return error;
  }
}

export function getPermittedLocations(locatoinId, permissionName) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/user/locations/permission?permission_name=${permissionName}&parent_location_key=${locatoinId}`,
    });
  } catch (error) {
    return error;
  }
}
export function duplicateRole(body) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/clone`,
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (error) {
    throw error;
  }
}

export function createRole(body) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/create`,
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (error) {
    throw error;
  }
}

export function fetchRoleTypes() {
  try {
    return request({
      url: `${JANUS_BASE_URL}/role/types`,
      method: 'GET',
    });
  } catch (error) {
    return {};
  }
}
export function fetchJanusToken(location) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/user/token?location_id=${location}`,
      method: 'GET',
    });
  } catch (error) {
    return {};
  }
}

export function getLearnerToken(learnerId) {
  return request({
    url: APDS_GATEWAY_BASE_URL + `student/${learnerId}/token/balance`,
    method: 'GET',
  });
}

export function approveFRRegistrationRequest(requestId, personId) {
  try {
    return request({
      url: `${GATEWAY_BASE_URL}/faceauth/approverequest?request_id=${requestId}&person_id=${personId}`,
      method: 'PUT',
    });
  } catch (error) {
    return {};
  }
}

export function getFAEvents() {
  return request({
    url: GATEWAY_BASE_URL + `/faeventtypes`,
    method: 'GET',
  });
}

export function getFACollection(body) {
  return request({
    url: GATEWAY_BASE_URL + '/faevents',
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function manageStudentServices(body) {
  return request({
    url: GATEWAY_BASE_URL + '/student/services',
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getPermittedFacilities(permissionName) {
  try {
    return request({
      url: `${JANUS_BASE_URL}/user/locations/permission?permission_name=${permissionName}`,
      method: 'GET',
    });
  } catch (error) {
    return error;
  }
}

export function getLocationFeatureStatus(locationId, featureName) {
  return request({
    url: `${GATEWAY_BASE_URL}/location/${locationId}/feature/status?feature_name=${featureName}`,
    method: 'GET',
  });
}

export function deleteSingleRoleAssignment(roleId, locationId, staffName) {
  return request({
    url: `${JANUS_BASE_URL}/user/roles/${roleId}/location?location_id=${locationId}&staff_name=${staffName}`,
    method: 'DELETE',
  });
}

export function getLearnerPrograms(personId) {
  return request({
    url: `${GATEWAY_BASE_URL}/student/${personId}/programs/list`,
    method: 'GET',
  });
}

export function getProgramCourses(personId, programId) {
  try {
    return request({
      url: `${GATEWAY_BASE_URL}/student/${personId}/programs/${programId}/courses`,
      method: 'GET',
    });
  } catch (error) {
    return {};
  }
}

export function getSisenseUser(username) {
  return request({
    url: GATEWAY_BASE_URL + `/sisense/user/${username}`,
    method: 'GET',
  });
}

export function getLearnerGroups(status) {
  return request({
    url: GATEWAY_BASE_URL + `/learner-groups${status ? `?status=${status}` : ''}`,
    method: 'GET',
  });
}

export function addLearnerGroups(body) {
  return request({
    url: GATEWAY_BASE_URL + `/learner-groups`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getLearnerGroupById(groupId) {
  return request({
    url: GATEWAY_BASE_URL + `/learner-groups/groups/${groupId}`,
    method: 'GET',
  });
}

export function updateLearnerGroup(groupId, body) {
  return request({
    url: GATEWAY_BASE_URL + `/learner-groups/groups/${groupId}`,
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function clearDevicePairing(personId) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/user/${personId}/device-pairing`,
    method: 'DELETE',
  });
}

export function getLearnerQuestionaire(personId) {
  return request({
    url: `${CAREER_BASE_URL}services/learner-profile/admin/v1/${personId}`,
    method: 'GET',
  });
}

export function getContactsList({ locationId, pageNo, pageSize, sortBy, sortOrder, callStatus, tbody }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/locations/${locationId}/contacts?${
      !!callStatus ? `statuses=${callStatus}&` : ''
    }size=${pageSize}&page=${pageNo}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    method: 'POST',
    body: JSON.stringify(tbody),
  });
}

export function getLearnersList({ locationId, pageNo, pageSize, tbody, sortBy, sortOrder }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/learners/${locationId}?size=${pageSize}&page=${pageNo}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    method: 'POST',
    body: JSON.stringify(tbody),
  });
}

export function getScheduleList({ locationId, scheduleId, pageNo, pageSize, sortBy, sortOrder }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/schedules?locationId=${locationId}&sort=createdDate,desc`,
    method: 'GET',
  });
}

export function createSchedule({ locationId, scheduleName }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/schedule`,
    method: 'POST',
    body: JSON.stringify({ facilityLocationKey: locationId, scheduleName, active: true }),
  });
}

export function updateVoiceCallSchedule(updatedScheduleObj) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/schedule/${updatedScheduleObj.id}`,
    method: 'PUT',
    body: JSON.stringify(updatedScheduleObj),
  });
}

export function updateContact(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/contact`,
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function createContact(body) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/contact`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getLiveCallsList({ locationId, status, size, pageNumber, sortBy, sortOrder, tbody }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/calls?locationId=${locationId}&callStatus=${status}&size=${size}&page=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    method: 'POST',
    body: JSON.stringify(tbody),
  });
}

export function adminJoinCall(meetingId) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/attendee?meetingId=${meetingId}`,
    method: 'GET',
  });
}

export function adminLeaveCall(meetingId, attendeeId) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/attendee?meetingId=${meetingId}&attendeeId=${attendeeId}`,
    method: 'DELETE',
  });
}

/**
 * @name endOutBoundCall
 * @desc end outbound call
 * @param {String} transactionId
 * @param {String} meetingId
 * @return {Promise}
 */
export function adminTerminateCall(meetingId, transactionId) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}voice/endCall`,
    method: 'POST',
    body: JSON.stringify({ transactionId, meetingId }),
  });
}
export function adminBlockUserAndTerminatesCall(meetingId, transactionId) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}voice/endCall`,
    method: 'POST',
    body: JSON.stringify({ transactionId, meetingId, blockRequest: true }),
  });
}

export function endVRSCall(meetingId, isBlockRequest) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}voice/endVrsCall`,
    method: 'PUT',
    body: JSON.stringify({ meetingId, isBlockRequest }),
  });
}

export function getExemptedDevicesList(locationId) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/location/${locationId}/device-pairing/exemptions`,
    method: 'GET',
  });
}

export function addExemptedDevice(locationId, body) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/location/${locationId}/device-pairing/exemptions`,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function deleteExemptedDevice(locationId, body) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/location/${locationId}/device-pairing/exemptions`,
    method: 'DELETE',
    body: JSON.stringify(body),
  });
}
export function getVoiceCallDetails(meetingId) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/callDetails?meetingId=${meetingId}`,
    method: 'GET',
  });
}
export function getLearnerMilestoneStatus(personId) {
  return request({
    url: `${CAREER_BASE_URL}services/learnosity/admin/v1/${personId}/assessments/statuses/${PDQ_TEMPLATE_ID}/pdq-locator`,
    method: 'GET',
  });
}

export function getLearnerMilestoneResults(personId) {
  return request({
    url: `${CAREER_BASE_URL}services/learnosity/admin/v1/${personId}/assessments/scores/${PDQ_TEMPLATE_ID}/pdq-locator`,
    method: 'GET',
  });
}

export function getCertificatesFromServer(locationId, queryParams) {
  return request({
    url: `${GATEWAY_BASE_URL}/programs/locations/${locationId}/certificates${queryParams}`,
    method: 'GET',
  });
}

export function getIndividualCertificate(programId, personId) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}admin/programs/${programId}/certificates/${personId}`,
  });
}

export function getResumesFromServer(locationId, queryParams) {
  return request({
    url: `${CAREER_BASE_URL}services/resumes/admin/v1/location/${locationId}${queryParams}`,
    method: 'GET',
  });
}

export function getResumeFile(personKey, format = 'pdf') {
  return request({
    url: `${CAREER_BASE_URL}services/resumes/admin/v1/${personKey}/resume-file?format=${format}`,
    method: 'GET',
  });
}

export function downloadCertificates(certificateIds) {
  return request({
    url: ` ${GATEWAY_BASE_URL}/download-certificates?certificateIds=${certificateIds.join(',')}`,
    method: 'GET',
  });
}

export function getResumeData(personId) {
  return request({
    url: `${CAREER_BASE_URL}services/resumes/admin/v1/${personId}`,
    method: 'GET',
  });
}

export function saveResumeData(personId, body) {
  return request({
    url: `${CAREER_BASE_URL}services/resumes/admin/v1/${personId}`,
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function getOptionsData() {
  return request({
    url: `${CAREER_BASE_URL}services/resumes/admin/v1/supports/options`,
    method: 'GET',
  });
}

export function bulkResetDevicePairing(locationId) {
  return request({
    url: `${GATEWAY_BASE_URL}/setting/location/${locationId}/bulk-reset-device-pairing`,
    method: 'DELETE',
  });
}

export function deleteFriendsAndFamilyContact(relationshipId) {
  return request({
    url: `${HERMES_BASE_URL}/services/messages/friends-family/v1/location/relationships/${relationshipId}`,
    method: 'DELETE',
  });
}
export function getContactsByLearnerId({ learnerId, pageSize, pageNumber, orderBy, orderDirection, requestPayload }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/contacts?learnerId=${learnerId}&size=${pageSize}&page=${pageNumber}&sort=${orderBy},${orderDirection}`,
    method: 'POST',
    body: JSON.stringify(requestPayload),
  });
}
export function getLearnerTranscript(personId) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}achievement/v2/transcript/${personId}`,
    method: 'GET',
  });
}

export function getLearnerTranscriptBinary(personId) {
  return request({
    url: `${APDS_GATEWAY_BASE_URL}admin/achievement/v2/transcript/${personId}/transcript-file`,
    method: 'GET',
  });
}

export function updateVoiceProfileIDStatus (voiceProfileIDPayload) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/voice-profile`,
    method: 'DELETE',
    body: JSON.stringify(voiceProfileIDPayload),
  });
}

export function assignSchedule(assignSchedulePayload) {
  console.log('schedule assignment payload', assignSchedulePayload);
  return request({
    url: `${GATEWAY_BASE_URL}/voice/schedule/assign`,
    method: 'POST',
    body: JSON.stringify(assignSchedulePayload),
  });
}

export function unassignSchedule(unassignSchedulePayload) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/schedule/unassign`,
    method: 'PATCH',
    body: JSON.stringify(unassignSchedulePayload),
  });
}

export function getKeywordsListByLocation({ locationId, sortBy, sortOrder, searchKeyword, page, pageSize }) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/keyword-dictionary?locationKey=${locationId}&search=${searchKeyword}&page=${page}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    method: 'GET',
  });
}

export function addKeywordsToDictionary(keywordsPayload, dictionaryId) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/keyword-dictionary/${dictionaryId}/update`,
    method: 'POST',
    body: JSON.stringify(keywordsPayload),
  });
}

export function getKeywordDetails({ dictionaryId, keyword, pageNumber, pageSize, sortBy, sortOrder }, filters) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/keyword-details?keyword_dictionary_id=${dictionaryId}&keyword=${keyword}&page=${pageNumber}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    method: 'POST',
    body: JSON.stringify({ ...filters }),
  });
}

export function getBaseVoiceKeywordsJson() {
  return fetch(`${CDN_BASE_URL}/assets/js/VOICE_BASE_KEYWORDS.json`, {
    method: 'GET',
  }).then((response) => response.json());
}

export function createKeywordDictionary(keywordDictionaryObj) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/keyword-dictionary`,
    method: 'POST',
    body: JSON.stringify(keywordDictionaryObj),
  });
}

export function getVoiceProfile(voiceProfileId) {
  return request({
    url: `${GATEWAY_BASE_URL}/voice/voice-profile/${voiceProfileId}`,
    method: 'GET',
  });
};
