import { useState, useEffect } from 'react';
import { fetchPrograms, createProgram, updateProgram } from './services/program.service';

const useManageProgramsHook = () => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddProgram, setShowAddProgram] = useState(false);


  useEffect(() => {
    const getPrograms = async () => {
      try {
        setLoading(true);
        const response = await fetchPrograms();
        setPrograms(response?.payload?.programs || []);
      } catch (err) {
        setError('Failed to fetch programs');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getPrograms();
  }, []);

  const handleEdit = async (programId, updatedData) => {
    console.log('Edit handler' + programId)
  };

  const handleAddNew = async (programData) => {
    if (!programData) {
      setShowAddProgram(true);
      return;
    }

    try {
      setLoading(true);
      const response = await createProgram(programData);
      const newProgram = response?.payload;
      if (newProgram) {
        setPrograms([...programs, newProgram]);
        handleBackToList();
      } else {
        throw new Error('Invalid program data received');
      }
    } catch (err) {
      setError('Failed to create program');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToList = () => {
    setShowAddProgram(false);
      const mainContainer = document.getElementById('main-container');
  
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0, 
        behavior: 'smooth',
      });
    }
  };
  
  return {
    programs,
    loading,
    error,
    handleEdit,
    handleAddNew,
    handleBackToList,
    showAddProgram
  };
};

export default useManageProgramsHook;
