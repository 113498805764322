export default (theme) => ({
  fieldLabel: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.colors.lightCerulean,
    letterSpacing: '1.8px',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    fontSize: '32px',
    fontWeight: 800,
    marginBottom: theme.spacing(4),
  },
  metadataSection: {
    marginBottom: theme.spacing(6),
    padding: '0px 0px 16px 24px',
  },
  metadataField: {
    marginBottom: theme.spacing(8),
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing(8),
    alignItems: 'start',
  },
  switchRoot: {
    width: 70,
    height: 30,
    padding: 0,
  },
  switchBase: {
    color: theme.palette.colors.grey100,
    padding: '3px',
    paddingLeft: '5px',
  },
  switchThumb: {
    color: 'black',
    width: 30,
    height: 30,
  },
  switchTrackOn: {
    borderRadius: '20px !important',
    backgroundColor: `${theme.palette.primary.blue.main} !important`,
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:after': {
      content: "'Yes'",
      right: '10px',
    },
  },
  switchTrackOff: {
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: theme.palette.colors.grey110,
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:before': {
      content: "'No'",
      left: '8px',
    },
  },
  switchChecked: {
    color: 'white',
    left: '-62px !important',
    transform: 'translateX(100px) !important',
  },
  switchThumbIcon: {
    fontSize: '30px !important',
    color: 'white',
    width: '20px !important',
    padding: '2px !important',
    height: '20px !important',
  },
  switchArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  switchText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  metadataLabelGroup: {
    flex: 1,
  },
  metadataLabel: {
    fontSize: '16px',
    fontWeight: 700,
    color:theme.palette.colors.grey110,
    marginBottom: theme.spacing(1),
  },
  metadataHelper: {
    color: theme.palette.colors.grey110,
    fontSize: '16px',
    marginBottom: theme.spacing(2),
    fontWeight: 400,
    letterSpacing: '2%',
  },
  metadataSelect: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      '& .MuiSelect-select': {
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center',
      },
      width: '100%',
      position: 'relative',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.colors.grey10,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.colors.grey10,
      transform: 'translate(14px, 16px)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
        color: theme.palette.primary.blue.dark,
      },
    },
    '& .MuiSelect-selectMenu': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  metadataTitle: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.colors.lightCerulean,
    letterSpacing: '1.8px',
    marginBottom: theme.spacing(4),
  },
});
