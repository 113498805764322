export default (theme) => ({
  errorText: {
    color: theme.palette.colors.redDark,
    fontSize: '0.875rem',
    fontStyle: 'italic',
    marginTop: theme.spacing(2),
    textAlign: 'right',
  },
  actionButtons: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    textTransform: 'none',
  },
  cancelButton: {
    textTransform: 'none',
  },
  saveButton: {
    textTransform: 'none',
  },
});
