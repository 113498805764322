import React from 'react';
import PropTypes from 'prop-types';
import {
  COURSE_DURATION,
  COURSE_DURATION_LABELS,
  PROGRAM_CATEGORY,
  PROGRAM_CATEGORY_LABELS,
  CONTENT_PROVIDER,
  CONTENT_PROVIDER_LABELS,
} from '../../../../../../services/constants';
import { Typography, FormControlLabel, Switch, Box } from '@material-ui/core';
import MetadataSelect from '../metadata-select/metadata-select';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import styles from './program-metadata.style';

const ProgramMetadata = ({ classes, formData, handleChange }) => (
  <Box>
    <Typography className={classes.fieldLabel}>PROGRAM METADATA</Typography>
    <Box className={classes.metadataSection}>
      <MetadataSelect
        label="Program Language"
        helperText="What is the primary language this program is taught in?"
        name="programLanguage"
        value={formData.programLanguage}
        onChange={handleChange}
        options={['english', 'spanish']}
        optionLabels={{ english: 'English', spanish: 'Spanish' }}
      />

      <Box className={classes.metadataField}>
        <Box className={classes.metadataLabelGroup}>
          <Typography className={classes.metadataLabel}>Certificate Eligibility</Typography>
          <Typography className={classes.metadataHelper}>
            Will the learner receive a certificate upon the completion of this program?
          </Typography>
        </Box>
        <Box className={classes.switchArea}>
          <FormControlLabel
            control={
              <Switch
                classes={{
                  root: classes.switchRoot,
                  switchBase: classes.switchBase,
                  thumb: classes.switchThumb,
                  track: formData.certificateEligibility ? classes.switchTrackOn : classes.switchTrackOff,
                  checked: classes.switchChecked,
                }}
                checked={formData.certificateEligibility}
                onChange={handleChange}
                name="certificateEligibility"
                color="primary"
                icon={<CancelRoundedIcon className={classes.swicthThumbIcon} />}
                checkedIcon={<CheckCircleIcon className={classes.switchThumbIcon} />}
              />
            }
          />
        </Box>
      </Box>

      <MetadataSelect
        label="Content Provider"
        helperText="Determines the logo and content provider filtering categorization."
        name="contentProvider"
        value={formData.contentProvider}
        onChange={handleChange}
        options={Object.values(CONTENT_PROVIDER)}
        optionLabels={CONTENT_PROVIDER_LABELS}
      />

      <MetadataSelect
        label="Program Category"
        helperText="Determines the program category that will help a learner find content of a specific type."
        name="programCategory"
        value={formData.programCategory}
        onChange={handleChange}
        options={Object.values(PROGRAM_CATEGORY)}
        optionLabels={PROGRAM_CATEGORY_LABELS}
      />

      <MetadataSelect
        label="Course Duration"
        helperText="How long will it typically take a learner to complete all courses in this program? A short program is less than 5 hours. A medium program is between 5 and 10 hours. A long program is any program that takes more than 10 hours. Select 'Varies' if you're unsure."
        name="courseDuration"
        value={formData.courseDuration}
        onChange={handleChange}
        options={Object.values(COURSE_DURATION)}
        optionLabels={COURSE_DURATION_LABELS}
      />
    </Box>
  </Box>
);

ProgramMetadata.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProgramMetadata);
