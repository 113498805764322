import DOMPurify from 'dompurify';
import { ANNOUNCEMENT, DIRECT_MESSAGE, LOCATION_CACHE_KEY } from './constants';
import { JURISDICTION_WITH_CHILDREN, SELECTED_HOUSING_UNIT, SELECTED_LOCATION, USER_CONTEXT } from '../../constants';
import { UserStorage } from '../../util/UserStorage';
import { getUnreadMessagesByLocationIds } from '../../util/APIUtils';

const getMessagingSelectedTabType = (selectedContact) => {
  if (selectedContact.id) {
    return ANNOUNCEMENT;
  } else if (selectedContact.threadId) {
    return DIRECT_MESSAGE;
  }
};

const transformMsg = (msgBody) => {
  return msgBody.replaceAll('\n', '<br />');
};

const getPlainHTML = (text = '') => {
  if (text) {
    text = text.replaceAll('<', ' <'); // ADDING A DUMMY SPACE SO THAT SPACE IS PRESERVED
    text = DOMPurify.sanitize(text, {
      ALLOWED_TAGS: [],
    });

    text = text
      .trim()
      .split(' ')
      .filter((item) => Boolean(item)) // REMOVING EMPTY ITEMS
      .join(' '); // JOINING BY SPACE TO CONSTRUCT STRING
  }
  return text || '';
};

const recipientUserFormat = (locationName, recipientUserFullNames = [], recipientGroupsNames = [], maxNamesToShow = 1) => {
  let names = [];
  if (locationName) {
    return locationName;
  } else if (recipientGroupsNames && recipientGroupsNames.length > 0) {
    //sort groups alphabetically
    recipientGroupsNames = recipientGroupsNames.sort();
    names = recipientGroupsNames;
  } else if (recipientUserFullNames && recipientUserFullNames.length > 0) {
    // sort by last names of recipents
    recipientUserFullNames = recipientUserFullNames.slice().sort((a, b) => {
      const lastNameA = a.split(' ').pop();
      const lastNameB = b.split(' ').pop();
      return lastNameA.localeCompare(lastNameB);
    });
    names = recipientUserFullNames;
  }

  if (names.length <= maxNamesToShow) {
    const joinedNames = names.join(', ');
    return joinedNames;
  } else {
    const firstListOfNames = names.slice(0, maxNamesToShow).join(', ');
    const remainingCount = names.length - maxNamesToShow;
    const ellipsis = remainingCount > 0 ? '...' : '';
    const formattedOutput = `${firstListOfNames}${ellipsis}+${remainingCount} more`;
    return formattedOutput;
  }
};

/**
 * @name getEnv
 * @param {string} name - name of the environment variable
 * @returns value of the environment variable or empty string
 */
const getEnv = (name) => process.env[name] || '';

/**
 * @desc Check for the errors in docID and returns the errors
 * @param {string} docID
 * @return {array}
 */
const getDocIdErrors = (docID = '', msg = 'Please enter a valid DOC ID (letters, digits, or dashes ONLY)') => {
  let errors = [];
  const isValid = RegExp(/^[A-Za-z0-9-]+$/).test(docID);
  if (!isValid) {
    errors.push(msg);
  }
  return errors;
};

/**
 * @desc Pre-parses the DOCID string before it sent to the server.
 * @param {string} docId
 * @return {string}
 */
const trimAndReplaceAll = (text = '', target, replacement) => {
  return text.trim().replaceAll(target, replacement);
};

const checkFirstCharOfOtherParam = (otherParam) => {
  if (otherParam[0] != '&') {
    throw new Error('Other param must start with "&" so that string can be formatted expectedly.');
  }
};

const validateOtherParams = (otherParam = '') => {
  if (otherParam.length > 0) {
    checkFirstCharOfOtherParam(otherParam);
  }
  return otherParam;
};

/**
 *
 * @param {orderDirection, orderBy, pageSize, page} queryParams This method expects query params. in order to generate initial query string
 * @param {string} otherParams (optional) This parameter only gets appended ONLY when provided.
 *                                        This should start with '&' so that following strings
 *                                        can be formed expectedly.
 * @throws {Error} When invalid otherParam is provided, i.e. without "&"
 * @returns {string} formattedQueryString
 */
const getQueryParamForServerSidePagination = ({ orderDirection, orderBy, pageSize, page }, otherParams) => {
  let queryString = `?size=${pageSize}&page=${page}${validateOtherParams(otherParams)}`;
  if (orderBy) {
    queryString += `&sortBy=${orderBy && orderBy.field}&sortOrder=${orderDirection}`;
  }
  return queryString;
};

/**
 * @name downloadBlobFile
 * @param {*} blobUrl URL constructed after initializing BLOB data
 * @param {*} fileName Name of the file that needs to be downloaded
 * @return {void}
 */
const downloadBlobFile = (blobUrl, fileName) => {
  // Create an anchor element
  const a = document.createElement('a');
  a.href = blobUrl;

  // Set the download attribute to the file name
  a.download = fileName;

  // Simulate a click on the anchor element to trigger the download
  a.click();

  // Cleanup: Remove the temporary URL
  URL.revokeObjectURL(blobUrl);
};

/**
 *
 * @param {*} binaryString
 * @param {*} type
 * @desc Converts binary (base64) string to blob URL
 * @returns {string}
 */
const convertBase64ToBlob = (binaryString, type) => {
  var byteString = atob(binaryString);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: type });
  // Create a URL for the Blob object
  const blobUrl = URL.createObjectURL(blob);

  return blobUrl;
};
const hasRole = (roles, targetRole) => (roles || []).includes(targetRole);

const getActiveLocationId = () => {
  let activeLocation = 0;
  const userStorage = new UserStorage(USER_CONTEXT.personId);
  let cachedLocation = userStorage.getItem(LOCATION_CACHE_KEY);
  if (!cachedLocation) {
    if (JURISDICTION_WITH_CHILDREN && JURISDICTION_WITH_CHILDREN.length > 0 && !SELECTED_LOCATION.locationId) {
      activeLocation = JURISDICTION_WITH_CHILDREN[0].children[0];
    }
  } else {
    activeLocation = cachedLocation;
  }
  if (activeLocation) {
    activeLocation = JSON.parse(activeLocation);
  }
  return activeLocation.locationId;
};

/**
 * Function to check if the current route is public
 *
 * @param {array} routes - Array of routes
 * @returns {boolean} - True if the route is public, false otherwise
 */
const isPublicRoute = (routes) => {
  let currentRoute = window.location.hash;
  return routes.some((route) => route.path === currentRoute.replace(/^#/, ''));
};

/**
 * @param {*} locations
 * @param {function} setUnreadCountByLocation
 * @desc Fetches unread count for each location and sets the unread count in the store
 */
const getUnreadCountByLocationsAndSetGlobally = async (locations, setUnreadCountByLocation) => {
  let unreadCountCollection = [];
  let locationIds = locations.map((location) => location.locationId);
  let unreadCountResponse = await Promise.all([
    getUnreadMessagesByLocationIds(locationIds.slice(0, 500).join(',')),
    getUnreadMessagesByLocationIds(locationIds.slice(500, 1000).join(',')),
    getUnreadMessagesByLocationIds(locationIds.slice(1000, 1500).join(',')),
  ]);
  unreadCountResponse.forEach((response) =>
    response.forEach((unreadData) => {
      unreadCountCollection.push(unreadData);
    })
  );
  setUnreadCountByLocation(unreadCountCollection);
};

export {
  recipientUserFormat,
  getEnv,
  getMessagingSelectedTabType,
  transformMsg,
  getPlainHTML,
  getDocIdErrors,
  trimAndReplaceAll,
  getQueryParamForServerSidePagination,
  convertBase64ToBlob,
  hasRole,
  downloadBlobFile,
  getActiveLocationId,
  isPublicRoute,
  getUnreadCountByLocationsAndSetGlobally
};
