import { Box, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React from 'reactn';
import AddContactModal from '../add-contact-modal/add-contact-modal.component';
import EditContactModal from '../edit-contact-modal/edit-contact-modal.component';
import styles from './contacts-list.component.style';
import useContactsListComponentHook from './useContactsListComponentHook';
import useAddContactModalHook from '../add-contact-modal/useAddContactModalHook';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';

const ContactsListComponent = ({ activeTab, locationId, classes, isDataRefresh, setDataRefresh }) => {
  const {
    tableColumns,
    pageSize,
    openSnackbar,
    snackbarAutoHideDuration,
    snackbarMessage,
    getContactsList,
    tableRef,
    editContact,
    setEditContact,
    setIsEditSucess,
    setIsEditError,
    isEditContactModalOpen,
    handleCloseSnackbar,
    handleExportCsv,
    addContactSuccess,
    setIsEditContactModalOpen,
    handleEditContactModalClose,
  } = useContactsListComponentHook(activeTab, locationId, classes, isDataRefresh, setDataRefresh);

  const {
    addContactModal,
    learners,
    searchLearnerLoading,
    contact,
    showAddContactSnackBar,
    learnerListFetched,
    addContactSuccessSnackbar,
    snackbarMessageSnackbar,
    handleAddModalClose,
    handleAddModalOpen,
    handleContactChange,
    handleAddContact,
    handleAddModalCloseSnackbar,
    handleSearch,
  } = useAddContactModalHook(locationId, null, setDataRefresh);
  const tableColumnsRef = React.useRef(tableColumns);

  return (
    <>
      <Box
        id="call-history-export-button"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '16px 0px',
          justifyContent: 'flex-end',
          gap: '16px',
          height: '40px',
          width: '100%',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disableElevation
          data-id="add-contact-button"
          onClick={handleAddModalOpen}
          startIcon={<CustomIcons icon={icons.AddIcon} />}
        >
          Add Contact
        </Button>
        <Button
          variant="outlined"
          className={classes.exportButton}
          disableElevation
          data-id="export-contact-button"
          onClick={()=>{
            handleExportCsv(tableColumnsRef.current)
          }}
          startIcon={<CustomIcons icon={icons.ExportIcon} />}
        >
          Export
        </Button>
      </Box>
      {locationId ? (
        <MaterialTable
          tableRef={tableRef}
          data-id="contacts-table"
          columns={tableColumnsRef.current}
          title={''}
          data={getContactsList}
          options={{
            filtering: true,
            pageSize: pageSize,
            search: false,
            thirdSortClick: false,
            toolbar: false,
          }}
        />
      ) : null}
      <AddContactModal
        addContactModal={addContactModal}
        handleAddModalClose={handleAddModalClose}
        learners={learners}
        handleSearch={handleSearch}
        searchLearnerLoading={searchLearnerLoading}
        contact={contact}
        handleContactChange={handleContactChange}
        learnerListFetched={learnerListFetched}
        handleAddContact={handleAddContact}
      />

      <EditContactModal
        isEditContactModalOpen={isEditContactModalOpen}
        setIsEditContactModalOpen={setIsEditContactModalOpen}
        handleEditContactModalClose={handleEditContactModalClose}
        editContact={editContact}
        setEditContact={setEditContact}
        setIsEditSucess={setIsEditSucess}
        setIsEditError={setIsEditError}
      />
      <Snackbar open={openSnackbar} autoHideDuration={snackbarAutoHideDuration} onClose={handleCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={addContactSuccess ? 'success' : 'error'}
        >
          {snackbarMessage}
          {addContactSuccess}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        id="add-contact-modal-snackbar"
        open={showAddContactSnackBar}
        autoHideDuration={snackbarAutoHideDuration}
        onClose={handleAddModalCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAddModalCloseSnackbar}
          severity={addContactSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessageSnackbar}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default withStyles(styles)(ContactsListComponent);
