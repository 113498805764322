import React from 'react';
import { Chip } from '@orijinworks/frontend-commons';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-insight-chip.style';

const VoiceInsightChip = ({ label, classes, className }) => {
  const isVRS = className === 'vrs';

  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      classes={{
        root: isVRS ? classes.rootChipVRS : classes.rootChip,
        outlined: isVRS ? classes.chipOutlinedVRS : classes.chipOutlined,
      }}
    />
  );
};

VoiceInsightChip.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string, // Optional class name
};

export default withStyles(styles)(VoiceInsightChip);
