import React, { Suspense, useEffect } from 'react';
import { withUnleash } from '../../../../v2/core/unleash';
import Loader from '../../../loader';
import { ContactsTabComponent } from '../../components';
import { UnleashService } from '../../../../v2/services';
import { fetchJanusToken } from '../../../../util/APIUtils';
import { setJanusToken } from '../../../../constants';
import { useContactsListContainerHook } from './useContactsListContainerHook';
import { connect } from 'react-redux';
const ContactsListContainer = (props) => {
  const { selectedLocation } = props;
  const locationId = selectedLocation ? selectedLocation.locationId : 0;
  const fetchreleventdata = async () => {
    try {
      const janusToken = await fetchJanusToken(locationId);
      setJanusToken(janusToken.token || '');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchreleventdata();
  }, [locationId]);

  const { activeTab, setActiveTab, renderTable, renderHeaderForActiveTab } = useContactsListContainerHook(locationId);

  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <ContactsTabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderHeaderForActiveTab()}
      {renderTable()}
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.app.selectedLocation,
  };
};

export default connect(mapStateToProps)(ContactsListContainer);
