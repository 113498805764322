import { useState, useCallback } from 'react';
import { FILE_UPLOAD, CHARACTER_LIMITS, FORM_FIELDS } from '../../constants/form-constants';

const useAddProgramHook = (handleBackToList, handleAddNew) => {
  const initialFormData = {
    [FORM_FIELDS.DISPLAY_NAME_EN]: '',
    [FORM_FIELDS.DISPLAY_NAME_ES]: '',
    [FORM_FIELDS.SHORT_DESCRIPTION_EN]: '',
    [FORM_FIELDS.SHORT_DESCRIPTION_ES]: '',
    [FORM_FIELDS.LONG_DESCRIPTION_EN]: '',
    [FORM_FIELDS.LONG_DESCRIPTION_ES]: '',
    [FORM_FIELDS.THUMBNAIL_IMAGE]: null,
    [FORM_FIELDS.THUMBNAIL_IMAGE_NAME]: '',
    [FORM_FIELDS.PROGRAM_LANGUAGE]: '',
    [FORM_FIELDS.CERTIFICATE_ELIGIBILITY]: false,
    [FORM_FIELDS.CONTENT_PROVIDER]: '',
    [FORM_FIELDS.PROGRAM_CATEGORY]: '',
    [FORM_FIELDS.COURSE_DURATION]: '',
    showDeleteDialog: false,
  };

  const [formData, setFormDataRaw] = useState(initialFormData);

  const [alertState, setAlertState] = useState({
    open: false,
    message: '',
    severity: 'error'
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = (data) => {
    try {
      // Check all required fields are present and not empty
      if (!data.displayNameEn?.trim() || data.displayNameEn.length > 100) return false;
      if (!data.displayNameEs?.trim() || data.displayNameEs.length > 100) return false;
      if (!data.shortDescriptionEn?.trim() || data.shortDescriptionEn.length > 300) return false;
      if (!data.shortDescriptionEs?.trim() || data.shortDescriptionEs.length > 300) return false;
      if (!data.longDescriptionEn?.trim() || data.longDescriptionEn.length > 1000) return false;
      if (!data.longDescriptionEs?.trim() || data.longDescriptionEs.length > 1000) return false;
      if (!data.thumbnailImage) return false;
      if (!data.programLanguage) return false;
      if (!data.contentProvider) return false;
      if (!data.programCategory) return false;
      if (!data.courseDuration) return false;

      return true;
    } catch (error) {
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    let value = type === 'checkbox' ? checked : e.target.value;
    
    if (name.includes('displayName')) {
      value = value.slice(0, CHARACTER_LIMITS.DISPLAY_NAME);
    } else if (name.includes('shortDescription')) {
      value = value.slice(0, CHARACTER_LIMITS.SHORT_DESCRIPTION);
    } else if (name.includes('longDescription')) {
      value = value.slice(0, CHARACTER_LIMITS.LONG_DESCRIPTION);
    }

    const newFormData = {
      ...formData,
      [name]: value
    };
    
    setFormData(newFormData);
    setIsFormValid(validateForm(newFormData));
  };

  const validateImageFile = (file) => {
    if (!file) return false;

    if (!FILE_UPLOAD.ALLOWED_TYPES.includes(file.type)) {
      setAlertState({
        open: true,
        message: FILE_UPLOAD.ERROR_MESSAGES.INVALID_TYPE,
        severity: 'error'
      });
      return false;
    }

    if (file.size > FILE_UPLOAD.MAX_SIZE) {
      setAlertState({
        open: true,
        message: 'Image file size should not exceed 1 MB',
        severity: 'error'
      });
      return false;
    }

    return true;
  };

  const handleImageUpload = async (input) => {
    let file;
    if (input instanceof File) {
      file = input;
    } else if (input?.preventDefault) {
      input.preventDefault();
      file = input.dataTransfer ? input.dataTransfer.files[0] : input.target.files[0];
    }

    // Validate file type and size first
    if (!validateImageFile(file)) return;

    // Then validate image dimensions
    try {
      await validateImageDimensions(file);
      
      const newFormData = {
        ...formData,
        thumbnailImage: file,
        thumbnailImageName: file.name,
      };
      
      setFormData(newFormData);
      setIsFormValid(validateForm(newFormData));

    } catch (error) {
      setAlertState({
        open: true,
        message: error.message,
        severity: 'error'
      });
    }
  };

  const validateImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      
      img.onload = () => {
        URL.revokeObjectURL(objectUrl);
        if (img.width < FILE_UPLOAD.MIN_WIDTH) {
          reject(new Error(FILE_UPLOAD.ERROR_MESSAGES.MIN_WIDTH));
        } else {
          resolve();
        }
      };

      img.onerror = () => {
        URL.revokeObjectURL(objectUrl);
        reject(new Error('Failed to load image'));
      };

      img.src = objectUrl;
    });
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer?.files?.[0];
    await handleImageUpload(file);
  };

  const handleAlertClose = () => {
    setAlertState({
      ...alertState,
      open: false
    });
  };

  const handleImageDelete = (confirmed) => {
    if (confirmed === undefined || confirmed === null) {
      setFormData(prev => ({
        ...prev,
        showDeleteDialog: true
      }));
      return;
    }
    
    if (confirmed) {
      const newFormData = {
        ...formData,
        thumbnailImage: null,
        thumbnailImageName: '',
        showDeleteDialog: false
      };
      setFormData(newFormData);
      setIsFormValid(validateForm(newFormData));
    } else {
      setFormData(prev => ({
        ...prev,
        showDeleteDialog: false
      }));
    }
  };

  const prepareFormData = () => {
    const formDataToSend = new FormData();
    formDataToSend.append('titleEN', formData.displayNameEn);
    formDataToSend.append('titleES', formData.displayNameEs);
    formDataToSend.append('descriptionShortEN', formData.shortDescriptionEn);
    formDataToSend.append('descriptionShortES', formData.shortDescriptionEs);
    formDataToSend.append('descriptionLongEN', formData.longDescriptionEn);
    formDataToSend.append('descriptionLongES', formData.longDescriptionEs);
    formDataToSend.append('thumbnail', formData.thumbnailImage);
    formDataToSend.append('metadata.certificateEligible', formData.certificateEligibility);
    formDataToSend.append('metadata.category', formData.programCategory);
    formDataToSend.append('metadata.courseDuration', formData.courseDuration);
    formDataToSend.append('metadata.contentProvider', formData.contentProvider);
    formDataToSend.append('metadata.primaryLanguage', formData.programLanguage.toUpperCase());
    formDataToSend.append('metadata.contentDeliveryMethod', 'D2L');
    formDataToSend.append('courses[0].id', '283848');
    formDataToSend.append('courses[0].displayOrder', '1'); // Adding dummy value for now

    return formDataToSend;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    
    const formDataToSend = prepareFormData();
    await handleAddNew(formDataToSend);
  };

  const setFormData = (newData) => {
    setFormDataRaw(newData);
    setIsFormValid(validateForm(newData));
  };

  const getImageUrl = useCallback((thumbnailImage) => {
    if (thumbnailImage instanceof File) {
      return typeof URL?.createObjectURL === 'function' ? URL.createObjectURL(thumbnailImage) : '';
    }
    return thumbnailImage?.name || thumbnailImage || '';
  }, []);

  return {
    formData,
    setFormData, 
    alertState, 
    setAlertState,
    isFormValid,
    handleChange,
    handleImageUpload,
    handleAlertClose,
    handleImageDelete,
    prepareFormData,
    handleSubmit,
    getImageUrl,
    handleDrop
  };
};

export default useAddProgramHook;
