export default (theme) => ({
  metadataField: {
    marginBottom: theme.spacing(8),
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: theme.spacing(8),
    alignItems: 'start',
  },
  metadataLabelGroup: {
    flex: 1,
  },
  metadataLabel: {
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.colors.grey110,
    marginBottom: theme.spacing(1),
  },
  metadataHelper: {
    color: theme.palette.colors.grey100,
    fontSize: '16px',
    marginBottom: theme.spacing(2),
    fontStyle: 'italic',
    fontWeight: 400,
    letterSpacing: '2%',
  },
  metadataSelect: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      '& .MuiSelect-select': {
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center',
      },
      width: '100%',
      position: 'relative',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.colors.grey10,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.colors.grey10,
      transform: 'translate(14px, 16px)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
        color: theme.palette.primary.deep,
      },
    },
    '& .MuiSelect-selectMenu': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
});
