import MaterialTable from 'material-table';
import React from 'react';
import withMeetingProvider from './../MeetingProvider/withMeetingProvider';
import { useLiveCallsListComponentHook } from './useLiveCallsListComponentHook';
import { UnleashService } from '../../../../v2/services';
import CallDetailsModal from '../call-details-modal/call-details-modal';
import CallDetailsDrawer from '../call-details-modal-v2/call-details-modal-v2';
import useUnleashHook from '../../../../v2/core/unleash/useUnleashHook';

const LiveCallsList = ({ locationId, isDataRefresh, setDataRefresh }) => {
  const isKeywordFlagEnabled = useUnleashHook(UnleashService.FLAGS.KEYWORD_MATCH_CONFIG).isFlagEnabled();
  const {
    tableColumns,
    getLiveCallsListData,
    isLoading,
    defaultPageSize,
    isDetailModalOpen,
    callData,
    attendeeId,
    meetingId,
    callStatus,
    setDetailModalState,
    tableRef,
    transactionId,
    handleMuteUnmuteMic,
    muteMic,
    liveVoiceCallInsights,
    isSpeakerOn,
    onSpeakerToggle
  } = useLiveCallsListComponentHook(locationId, isDataRefresh, setDataRefresh, isKeywordFlagEnabled);
  const tableColumnsRef = React.useRef(tableColumns);
  const isFlagEnabled = useUnleashHook(UnleashService.FLAGS.CALL_HISTORY_LIVE_CALLS_DESIGN_V2).isFlagEnabled();
  return (
    <>
      {isFlagEnabled ? (
        <CallDetailsDrawer
          isDetailModalOpen={isDetailModalOpen}
          setDetailModalState={setDetailModalState}
          callStatus={callStatus}
          {...callData}
          liveVoiceCallInsights={liveVoiceCallInsights}
          attendeeId={attendeeId}
          meetingId={meetingId}
          transactionId={transactionId}
          handleMuteUnmuteMic={handleMuteUnmuteMic}
          muteMic={muteMic}
          isSpeakerOn={isSpeakerOn}
          onSpeakerToggle={onSpeakerToggle}
        />
      ) : (
        <CallDetailsModal
          isDetailModalOpen={isDetailModalOpen}
          setDetailModalState={setDetailModalState}
          callStatus={callStatus}
          {...callData}
          attendeeId={attendeeId}
          meetingId={meetingId}
          transactionId={transactionId}
          handleMuteUnmuteMic={handleMuteUnmuteMic}
          muteMic={muteMic}
        />
      )}
      {locationId ? (
        <MaterialTable
          tableRef={tableRef}
          columns={tableColumnsRef.current}
          data={getLiveCallsListData}
          isLoading={isLoading}
          options={{
            filtering: true,
            pageSize: defaultPageSize,
            thirdSortClick: false,
            search: false,
            toolbar: false,
          }}
        />
      ) : null}
    </>
  );
};

export default withMeetingProvider(LiveCallsList);
