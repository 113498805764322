const styles = (theme) => ({
  rootChip: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.secondary.red.main}`,
      textTransform: 'capitalize',
      maxWidth: 'fit-content',
      marginBottom: '4px',
    },
  },
  chipOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.colors.red.deep,
      backgroundColor: '#FFECF0',
    },
  },
  // Define separate styles for VRS directly
  rootChipVRS: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.secondary.yellow.main}`,
      textTransform: 'capitalize',
      maxWidth: 'fit-content',
      marginBottom: '4px',
    },
  },
  chipOutlinedVRS: {
    '&.MuiChip-outlined': {
      color: theme.palette.colors.yellow.deep,
      backgroundColor: theme.palette.colors.yellow.pale,
    },
  },
});

export default styles;
