import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography, Box } from '@material-ui/core';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { withStyles } from '@material-ui/core/styles';
import styles from './description-field.style';

const DescriptionField = ({ 
  classes, 
  formData, 
  handleChange,
  title,
  characterLimit,
  fieldNameEn,
  fieldNameEs,
  multiline = false,
  rows = 1
}) => (
  <Box className={classes.fieldGroup}>
    <Typography className={classes.fieldLabel}>{title}</Typography>
    <Typography className={classes.characterLimit}>Max {characterLimit} characters</Typography>
    <Box className={classes.textFieldGroup}>
      <TextField
        fullWidth
        multiline={multiline}
        rows={rows}
        name={fieldNameEn}
        label={<span className={classes.labelText}>{`${title} (English)`.replace('Program ', '')}</span>}
        placeholder={`${title} (English)`.replace('Program ', '')}
        InputLabelProps={{ shrink: true }}
        value={formData[fieldNameEn]}
        onChange={handleChange}
        variant="outlined"
        className={multiline ? classes.longDescriptionField : classes.textField}
        InputProps={{
          endAdornment: multiline ? (
            <EditNoteIcon className={classes.editIcon} />
          ) : undefined,
        }}
        inputProps={{
          style: { textOverflow: 'ellipsis' },
          maxLength: characterLimit,
        }}
      />
      <TextField
        fullWidth
        multiline={multiline}
        rows={rows}
        name={fieldNameEs}
        label={<span className={classes.labelText}>{`${title} (Español)`.replace('Program ', '')}</span>}
        placeholder={`${title} (Español)`.replace('Program ', '')}
        InputLabelProps={{ shrink: true }}
        value={formData[fieldNameEs]}
        onChange={handleChange}
        variant="outlined"
        className={multiline ? classes.longDescriptionField : classes.textField}
        InputProps={{
          endAdornment: multiline ? (
            <EditNoteIcon className={classes.editIcon} />
          ) : undefined,
        }}
        inputProps={{
          style: { textOverflow: 'ellipsis' },
          maxLength: characterLimit,
        }}
      />
    </Box>
  </Box>
);

DescriptionField.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  characterLimit: PropTypes.number.isRequired,
  fieldNameEn: PropTypes.string.isRequired,
  fieldNameEs: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

export default withStyles(styles)(DescriptionField);
