import React, { useEffect, useState } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from '@orijinworks/frontend-commons';

/**
 * @name UnleashContextProvider
 * @description A centralized component responsible for setting the Unleash context once.
 * This component fetches necessary data (IP address, device info) and sets the Unleash context
 * with user and device information. It prevents redundant API calls by centralizing context setting.
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render after context is set
 * @param {string} [props.userName] - User's name for context
 * @param {string} [props.selectedLocation] - Selected location info
 * @param {string|number} [props.userId] - User's ID
 * @returns {React.ReactElement} The rendered component
 */
const UnleashContextProvider = ({ children, userId, username, selectedLocation }) => {
  const updateContext = useUnleashContext();
  const [isContextSet, setIsContextSet] = useState(false);

  useEffect(() => {
    /**
     * Sets the Unleash context with user and selected location information
     *
     * @async
     * @function setUnleashContext
     * @returns {Promise<void>}
     */
    const setUnleashContext = async () => {
      try {
        await updateContext({
          userId: userId ? parseInt(userId) : undefined,
          username,
          facilityCode: selectedLocation?.locationShortCode,
        });
      } catch (e) {
        console.error('Error setting Unleash context:', e);
      } finally {
        setIsContextSet(true);
      }
    };

    setUnleashContext();
  }, [userId, username, selectedLocation]);

  if (!isContextSet) {
    return <Loader isLoading />;
  }

  return <>{children}</>;
};

UnleashContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  username: PropTypes.string,
  selectedLocation: PropTypes.shape({
    locationShortCode: PropTypes.string,
  }),
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = ({ app }) => ({
  userId: app.userDetail.userId,
  username: app.userDetail.userName,
  selectedLocation: app.selectedLocation,
});

export default connect(mapStateToProps)(UnleashContextProvider);
