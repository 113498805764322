import React from 'reactn';
import { useState, useEffect } from 'react';
import { ContactsListComponent } from '../../components';
import { LiveCallsList } from '../../components';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { PendingContactsListComponent } from '../../components';
import CallHistoryList from '../../components/call-history/call-history';
import VoiceCallSettings from '../../components/voice-call-settings/voice-call-settings';
import LearnerManagementComponent from '../../components/learner-management/learner-management.component';
import { VoiceCallService } from '../../services/voice-call.service';
import { useDispatch } from 'react-redux';
import { setVoiceBaseKeywords } from '../../../../v2/core/store';
import { Typography } from '@orijinworks/frontend-commons';
import { Box, IconButton } from '@material-ui/core';
import { RefreshIcon } from '../../../../v2/shared/components/MaterialUIIcons';

export const useContactsListContainerHook = (locationId) => {
  const [activeTab, setActiveTab] = useState(VOICE_CALL.TABS.LIVE_CALLS);
  const [isDataRefresh, setDataRefresh] = React.useState(false);
  const [shouldExportCallHistory, setShouldExportCallHistory] = React.useState(false);
  const dispatch = useDispatch();
  const [lastRefreshed, setLastRefreshed] = useState(new Date().toLocaleTimeString());
  const voiceCallService = new VoiceCallService();

  useEffect(() => {
    setLastRefreshed(new Date().toLocaleTimeString());
  }, [activeTab]);
  

  useEffect(() => {
    const activeTabFromLocalStorage = localStorage.getItem('activeTab');
    if (activeTabFromLocalStorage && activeTabFromLocalStorage === 'LEARNER_PROFILE') {
      setActiveTab(VOICE_CALL.TABS.RESIDENT_MANAGEMENT);
      localStorage.removeItem('activeTab');
      localStorage.removeItem('selectedLearner');
    }
    loadBaseVoiceKeywordsJson();
  }, []);

  const renderTable = () => {
    switch (activeTab) {
      case VOICE_CALL.TABS.LIVE_CALLS:
        return <LiveCallsList isDataRefresh={isDataRefresh} setDataRefresh={setDataRefresh} locationId={locationId} />;
      case VOICE_CALL.TABS.CALL_HISTORY:
        return (
          <CallHistoryList
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
            shouldExportCallHistory={shouldExportCallHistory}
            setShouldExportCallHistory={setShouldExportCallHistory}
            locationId={locationId}
          />
        );
      case VOICE_CALL.TABS.ALL_CONTACTS:
        return (
          <ContactsListComponent
            activeTab={activeTab}
            locationId={locationId}
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
          />
        );
      case VOICE_CALL.TABS.RESIDENT_MANAGEMENT:
        return (
          <LearnerManagementComponent
            activeTab={activeTab}
            locationId={locationId}
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
            shouldExportCallHistory={shouldExportCallHistory}
            setShouldExportCallHistory={setShouldExportCallHistory}
          />
        );
      case VOICE_CALL.TABS.SETTINGS:
        return <VoiceCallSettings locationId={locationId} />;
      default:
        return (
          null
        );
    }
  };
  const renderHeaderForActiveTab = () => {
    // Define which tabs show refresh and export buttons
    const showRefreshButton = [
      VOICE_CALL.TABS.CALL_HISTORY,
      VOICE_CALL.TABS.LIVE_CALLS,
      VOICE_CALL.TABS.ALL_CONTACTS,
      VOICE_CALL.TABS.RESIDENT_MANAGEMENT,
    ].includes(activeTab);

    const title = VOICE_CALL.TABS_MAPPING[activeTab] || 'Live Calls';

    return (
      
        <Box
          id="header-row-1"
          sx={{
            gap: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            padding : "0px 0px 12px",
            height : '80px',
            justifyContent: 'flex-start',
            width: 'auto',
            marginLeft:'15px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
            }}
          >
            <Typography variant="h1">{title}</Typography>
            {showRefreshButton && (
              <Typography variant="caption" color="textSecondary">
                Last Refreshed {lastRefreshed}
              </Typography>
            )}
          </Box>
          {showRefreshButton && (
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setDataRefresh(true);
                setLastRefreshed(new Date().toLocaleTimeString());
              }}
              aria-label="refresh"
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
    
    );
  };
  const loadBaseVoiceKeywordsJson = async () => {
    try {
      const voiceBaseKeywords = await voiceCallService.getBaseVoiceKeywordsJson();
      dispatch(setVoiceBaseKeywords(voiceBaseKeywords));
    } catch (error) {
      console.error('Failed to fetch voice base keywords:', error);
    }
  };
  return {
    activeTab,
    setActiveTab,
    renderTable,
    setDataRefresh,
    setShouldExportCallHistory,
    renderHeaderForActiveTab,
  };
};
