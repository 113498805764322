import { useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { VoiceCallService } from '../../services/voice-call.service';
import PropTypes from 'prop-types';
import { joinMeetingRoom, registerAction } from '../../../../v2/socket';
import store, { changeToastType, showToast as storeShowToast } from '../../../../v2/core/store';

/**
 * Custom hook for managing call details modal functionality.
 * @param {boolean} isDetailModalOpen - Boolean to determine if the call details modal is open.
 * @param {Function} setDetailModalState - Function to set the state of the call details modal.
 * @param {string} meetingId - The ID of the meeting.
 * @param {string} attendeeId - The ID of the attendee.
 * @returns {Object} - Object containing the functions and state variables for the call details modal.
 */
const useCallDetailsModalHook = (isDetailModalOpen, setDetailModalState, meetingId, attendeeId, liveVoiceCallInsights,callType) => {
  const meetingManager = useMeetingManager();
  const voiceCallService = new VoiceCallService();
  const [terminateCall, setTerminateCall] = useState(false);
  const [detectedKeywords, setDetectedKeywords] = useState([]);
  const [activeCallInsights, setActiveCallInsights] = useState({});
  /**
   * Function to handle leaving the call.
   * @async
   * @function onLeaveCall
   * @returns {Promise<void>}
   */

  useEffect(() => {
    setTerminateCall(false);
    if (!isDetailModalOpen) {
      setActiveCallInsights({});
    }
  }, [isDetailModalOpen]);

  useEffect(() => {
    if (liveVoiceCallInsights && liveVoiceCallInsights.length > 0) {
      const activeCallInsights = liveVoiceCallInsights.find((call) => call.meetingId === meetingId);
      setActiveCallInsights(activeCallInsights);
    }
  }, [liveVoiceCallInsights, meetingId,isDetailModalOpen]);

  useEffect(() => {
    registerAction({
      event: 'voice_keyword_detected',
      action: handleVoiceKeywordDetected,
    });
  }, []);

  function handleVoiceKeywordDetected(data) {
    const activeCallmeetingId = localStorage.getItem('activeCallmeetingId');
    if (activeCallmeetingId !== data?.meeting_id) {
      return;
    }

    try {
      setDetectedKeywords((prev) => {
        return [...new Set([...prev, ...(data?.keywords || [])])];
      });
    } catch (error) {
      console.error('Error updating detected keywords:', error);
    }
  }

  useEffect(() => {
    if (!meetingId) return;

    localStorage.setItem('activeCallmeetingId', meetingId);

    setDetectedKeywords([]);
    voiceCallService
      .getVoiceCallDetails(meetingId)
      .then((data) => {
        setDetectedKeywords(data?.keywords);
      })
      .catch((error) => {
        console.error('Error getting voice call details:', error);
      });

    try {
      joinMeetingRoom(meetingId);
    } catch (error) {
      console.error('Error joining meeting room:', error);
    }
  }, [meetingId]);

  const onLeaveCall = async () => {
  
    try {
      setDetailModalState(false);
      setTerminateCall(false);
      if(callType === 'VRS') return;
      await voiceCallService.adminLeaveCall(meetingId, attendeeId);
    } catch (error) {
      console.error('Error leaving call:', error);
    } finally {
      meetingManager.leave();
    }
  };

  const showToast = (msg) => {
    store.dispatch(changeToastType('success'));
    store.dispatch(storeShowToast(msg));
  }

  /**
   * Terminates a call.
   *
   * @param {string} meetingId - The ID of the meeting.
   * @param {string} transactionId - The ID of the transaction.
   * @returns {Promise<void>} - A promise that resolves when the call is terminated.
   */
  const onTerminateCall = async (meetingId, transactionId) => {
    showToast("Call Terminated Successfully");
    setDetailModalState(false);
    try {
      await voiceCallService.adminTerminateCall(meetingId, transactionId, callType);
    } catch (error) {
      console.error('Error terminating call:', error);
    } finally {
      meetingManager.leave();
    }
  };

  /**
   * Function to block the user and terminates the call
   * @param {string} meetingId - The ID of the meeting.
   * @param {string} transactionId - The ID of the transaction.
   * @returns {Promise<void>} - A promise that resolves when the call is terminated and user is blocked
   *
   */
  const blockUserAndTerminateCall = async (meetingId, transactionId) => {
    showToast("Contact Blocked and Call Terminated Successfully");
    setDetailModalState(false);
    try {
      await voiceCallService.adminBlockUserAndTerminatesCall(meetingId, transactionId, callType);
    } catch (error) {
      console.error('Error terminating call:', error);
    } finally {
      meetingManager.leave();
    }
  };

  return {
    onLeaveCall,
    onTerminateCall,
    blockUserAndTerminateCall,
    terminateCall,
    setTerminateCall,
    detectedKeywords,
    activeCallInsights,
  };
};

useCallDetailsModalHook.propTypes = {
  setDetailModalState: PropTypes.func.isRequired,
  meetingId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
};

export { useCallDetailsModalHook };
