import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { Button } from '@orijinworks/frontend-commons';
import styles from './form-actions.style';
import { withStyles } from '@material-ui/styles';

const FormActions = ({ classes, isFormValid, handleBackToList, handleSubmit }) => (
  <Box>
    <Box className={classes.actionButtons}>
      <Button variant="outlined" onClick={() => handleBackToList()} data-testid="cancel-button" className={classes.cancelButton}>
        Cancel
      </Button>
      <Button 
        variant="contained" 
        color="primary" 
        disabled={!isFormValid} 
        onClick={handleSubmit}
        className={classes.saveButton}
      >
        Save Program
      </Button>
    </Box>
    {!isFormValid && (
      <Typography className={classes.errorText}>You must complete all fields correctly to save program.</Typography>
    )}
  </Box>
);

FormActions.propTypes = {
  classes: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  handleBackToList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(FormActions);
