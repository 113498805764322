import React, { Component, Suspense } from 'reactn';
import { Grid, withStyles } from '@material-ui/core';
import styles from './index.styles';
import { ManagementService } from '../management.service';
import { Loader } from '@orijinworks/frontend-commons';
import ConfirmationModal from '../../confirmation-modal';
import BiometricAuthentication from './sections/biometric-authentication';
import PostReleaseLearner from './sections/post-release-learner';
import UserDevicePairing from './sections/user-device-pairing';
import { SETTINGS_LOCATION_TYPE } from '../../../v2/services/constants';
import ResetDevicePairing from './sections/reset-device-pairing';
import UnleashProvider from '../../../v2/core/unleash/unleashProvider';
import { UnleashService } from '../../../v2/services';
import ResumeBuilder from './sections/resume-builder';
import { withUnleash } from '../../../v2/core/unleash';

const Play = React.lazy(() => import('./sections/play'));
const General = React.lazy(() => import('./sections/general'));
const Pathways = React.lazy(() => import('./sections/pathways'));
const Explore = React.lazy(() => import('./sections/explore'));
const IncentiveEngine = React.lazy(() => import('./sections/incentive-engine'));
const Forms = React.lazy(() => import('./sections/forms'));
const Messaging = React.lazy(() => import('./sections/messaging'));
const FFMessaging = React.lazy(() => import('./sections/ff-messaging'));
const VoiceCalls = React.lazy(() => import('./sections/voice-calls'));
const CareerCompass = React.lazy(() => import('./sections/career-compass'));
const PermissionControl = React.lazy(() => import('../../permission-control/permission-control'));
const ServiceCodes = React.lazy(() => import('./sections/service-codes'));
const ReEntryPreparation = React.lazy(() => import('./sections/re-entry-preparation'));

export class Settings extends Component {
  managementService = new ManagementService();
  loaderCntr = 0;
  state = {
    user: {},
    isLoading: false,
    loader: {},
    general: {
      status: false,
      adminAccess: false,
      learnerAccess: false,
      timezone: '',
    },
    pathways: {
      status: false,
    },
    explore: {
      status: false,
    },
    incentiveEngine: {
      status: false,
      newTokenName: '',
    },
    forms: {
      status: false,
    },
    userDevicePairing: {
      status: false,
    },
    clearDevicePairingOnMove: {
      status: false,
    },
    voiceCalls: {
      status: false,
    },
    careerCompass: {
      status: false,
    },
    voiceCallsSchedule: {
      status: false,
    },
    messaging: {
      status: false,
    },
    ffmessaging: {
      status: false,
    },
    messagingPerson: {
      status: false,
    },
    manageRelationships: {
      status: false,
    },
    messagingAttorney: {
      status: false,
    },
    autoCreateRelationships: {
      status: false,
    },
    clickTileToLaunch: {
      status: false,
    },
    explorePageIndividualized: {
      status: false,
    },
    play: {
      play: {
        status: false,
        value: {
          status: false,
          value: '',
        },
        scheduleStatus: false,
        url: '',
      },
      video: {
        status: false,
        value: {
          status: false,
          value: '',
        },
        scheduleStatus: false,
        url: '',
      },
      radio: {
        status: false,
        value: {
          status: false,
          value: '',
        },
        scheduleStatus: false,
        url: '',
      },
      game: {
        status: false,
        value: {
          status: false,
          value: '',
        },
        scheduleStatus: false,
        url: '',
      },
    },
    learnerAccessSchedule: {
      status: false,
    },
    resumeBuilder: {
      status: false,
    },
    reEntryPreparation: {
      status: false,
    },
    schedules: [],
    showConfirmationDialog: false,
    warningMessage: '',
    warningCallback: () => {},
  };

  debounceRef = null;

  /**
   * @name updatePromiseHandler
   * @param {string} type Type of handler (success | error)
   * @desc Features flag success promise handler, responsible for loader and misc. actions.
   * @return {void}
   */
  updatePromiseHandler = (type, loaderKey) => {
    if (type === 'success') {
      if (this.getLocationType() !== 'facility') {
        this.getFeatures();
      }
      this.setState({
        loader: {
          ...this.state.loader,
          [loaderKey]: false,
        },
      });
    } else {
      this.setState({
        loader: {
          ...this.state.loader,
          [loaderKey]: false,
        },
      });
      this.props.showSnackBar('Operation failed, something went wrong.');
    }
  };

  /**
   * @name updatePromiseErrorHandler
   * @desc Features flag error promise handler, responsible for loader and misc. actions.
   * @return {void}
   */
  updatePromiseSuccessHandler = () => {
    this.setGlobal({
      ...this.global,
      isLoading: false,
    });
  };

  /**
   * @name getFeatureDelta
   * @param {object} state Component state
   * @param {object} data Updated data
   * @param {string} parentKey State parent key
   * @param {string} subChild sub child component - if any
   * @desc Creates server object for feature deltas.
   * @return {array}
   */
  getFeatureDelta = (state, data, parentKey, deltaData) => {
    if (parentKey === 'play') {
      return deltaData;
    }
    return [
      {
        ...state[parentKey]._cacheData,
        ...data,
      },
    ];
  };

  /**
   * @name updateSettingsState
   * @param {string} type Expected (feature, schedule).
   * @param {object} data Data that needs to be updated.
   * @param {string} key State key / featureName respectively
   *                              be updated.
   * @param {string} parentKey Parent state key in which this needs to be updated.
   * @param {object} deltaData Server data that needs for updating.
   * @desc Updates settings immutably.
   * @retrun {void}
   */
  updateSettingsState = (type, data, key, parentKey, loadingKey, deltaData) => {
    const state = this.state;
    let promise = null;
    switch (type) {
      case 'schedule':
        const featureIndex = state[parentKey].schedules.findIndex((feature) => feature.featureName === key);
        state[parentKey].schedules[featureIndex] = {
          ...state[parentKey].schedules[featureIndex],
          ...data,
        };
        break;

      case 'feature':
        state[parentKey] = {
          ...state[parentKey],
          ...data,
        };
        clearTimeout(this.debounceRef);
        this.debounceRef = setTimeout(() => {
          if (key === 'url') {
            promise = this.managementService.updateManagementAttributes(this.props.location.active, state);
          } else if (['features'].indexOf(key) > -1) {
            promise = this.managementService.updateFeatures(
              this.getID(),
              this.getFeatureDelta(state, data, parentKey, deltaData),
              this.getLocationType()
            );
          } else if (key === 'timezone') {
            state.general.isLoading = true;
            promise = this.managementService.updateLocationTimezone(this.props.location.active, state);
          } else if (key === this.managementService.Admin_Access || key === this.managementService.Learner_Access) {
            state.general.isLoading = true;
            promise = this.managementService.updateGeneralToggle(this.props.location.active, state, key);
          } else if (key === 'generalToggle') {
            state.general.isLoading = true;
            promise = Promise.all([
              this.managementService.updateGeneralToggle(
                this.props.location.active,
                state,
                this.managementService.Admin_Access
              ),
              this.managementService.updateGeneralToggle(
                this.props.location.active,
                state,
                this.managementService.Learner_Access
              ),
            ]);
          }

          promise.then(
            () => this.updatePromiseHandler('success', loadingKey),
            () => this.updatePromiseHandler('error', loadingKey)
          );
        }, 500);

        break;

      default:
        break;
    }
    state.loader = {
      ...this.state.loader,
      [loadingKey]: true,
    };
    this.setState(state);
  };

  /**
   * @name updateSettingSchedule
   * @param {string} featureName
   * @param {array} newScheduleCollection
   * @desc Immutably replaces setting schedule.
   * @return {void}
   */
  updateSettingSchedule = (featureName, newScheduleCollection) => {
    const state = this.state;
    const featureIndex = state.schedules.findIndex((feature) => feature.featureName === featureName);
    if (featureIndex >= 0) {
      state.schedules[featureIndex].schedules = newScheduleCollection;
    }

    this.setState(state);
  };

  /**
   * @name updateSchedule
   * @desc Update's schedule in DB.
   * @return {void}
   */
  updateSchedule = (loaderKey) => {
    this.setState({
      loader: {
        [loaderKey]: 'hey',
      },
    });
    this.managementService.updateSchedule(this.props.location.active, this.state.schedules, this.props.showSnackBar).then(
      () => this.updatePromiseHandler('success', loaderKey),
      () => this.updatePromiseHandler('error', loaderKey)
    );
  };

  componentDidMount() {
    this.getFeatures();
    // if (this.props.facility) {
    //     this.getSchedule(this.props.location.active);
    // }
    this.initPropToState();
  }

  showLoader = () => {
    this.loaderCntr++;
    this.setState({
      isLoading: true,
    });
  };

  hideLoader = () => {
    this.loaderCntr--;
    if (this.loaderCntr === 0) {
      this.setState({
        isLoading: false,
      });
    }
  };

  /**
   * @name initPropToState
   * @desc A wrapper method to initialize properties from prop to state.
   * @return {void}
   */
  initPropToState = () => {
    const stateData = {};
    if (this.getLocationType() === 'facility') {
      stateData['location'] = this.props.location;
    } else if (this.getLocationType() === 'learner') {
      stateData['user'] = this.props.user;
    }
    this.setState({
      ...stateData,
    });
  };

  componentDidUpdate() {
    if (this.getLocationType() === 'facility' && this.props.location.active !== this.state.location.active) {
      this.getFeatures();
      this.initPropToState();
    } else if (this.getLocationType() === 'learner' && this.props.user.personId !== this.state.user.personId) {
      this.getFeatures();
      this.initPropToState();
    }
  }

  /**
   * @name getLocationTimezone
   * @param {numebr} locationId
   * @desc Fetches timezone based upon location.
   * @return {void}
   */
  getLocationTimezone = (locationId) => {
    this.managementService.getLocationTimezone(locationId).then((_successLog) => {
      this.setState({
        general: {
          ...this.state.general,
          ..._successLog,
        },
      });
    });
  };

  /**
   * @name getLocationType
   * @desc On-behalf of props, this method decides type of location.
   * @return {string}
   */
  getLocationType = () => {
    if (this.props.facility) {
      return 'facility';
    } else {
      return 'learner';
    }
  };

  /**
   * @name getID
   * @desc Based upon location check ID is returned
   * @return {number} id
   */
  getID = () => {
    if (this.getLocationType() === 'facility') {
      return this.props.location.active;
    } else {
      return this.props.user.id;
    }
  };

  /**
   * @name getIDOrUsername
   * @desc Based upon location ID or username is returned.
   * @return {number} id
   */
  getIDOrUsername = () => {
    if (this.getLocationType() === 'facility') {
      return this.props.location.active;
    } else {
      return this.props.user.userName;
    }
  };

  getFeatureSettings = (feature, settings, parentSettings) => {
    if (
      [
        'pathways',
        'explore',
        'forms',
        'messaging',
        'ffmessaging',
        'messagingPerson',
        'manageRelationships',
        'autoCreateRelationships',
        'learnerAccessSchedule',
        'incentiveEngine',
        'messagingAttorney',
        'clickTileToLaunch',
        'explorePageIndividualized',
        'userDevicePairing',
        'clearDevicePairingOnMove',
        'voiceCalls',
        'voiceCallsSchedule',
        'resumeBuilder',
        'careerCompass',
      ].includes(feature)
    ) {
      let setting = parentSettings[feature]['_cacheData'];
      if (setting && setting.locationType !== 15 && !setting.status) {
        return parentSettings[feature];
      }
      return settings[feature];
    } else if (feature === 'general') {
      let adminSetting = parentSettings['general']['_cacheDataAdmin'];
      let learnerSetting = parentSettings['general']['_cacheDataLearner'];
      let data = settings['general'];
      if (adminSetting && adminSetting.locationType !== 15 && !adminSetting.status) {
        data['_cacheDataAdmin'] = adminSetting;
        data['adminAccess'] = false;
      }
      if (learnerSetting && learnerSetting.locationType !== 15 && !learnerSetting.status) {
        data['_cacheDataLearner'] = learnerSetting;
        data['learnerAccess'] = false;
      }
      return data;
    } else if (feature === 'play') {
      let data = settings['play'];
      let playSetting = parentSettings['play']['play']['_cacheData'];
      let playScheduleSetting = parentSettings['play']['play']['_cacheDataSchedule'];
      let videoSetting = parentSettings['play']['video']['_cacheData'];
      let videoScheduleSetting = parentSettings['play']['video']['_cacheDataSchedule'];
      let gameSetting = parentSettings['play']['game']['_cacheData'];
      let gameScheduleSetting = parentSettings['play']['game']['_cacheDataSchedule'];
      let radioSetting = parentSettings['play']['play']['_cacheData'];
      let radioScheduleSetting = parentSettings['play']['play']['_cacheDataSchedule'];
      if (playSetting && playSetting.locationType !== 15 && !playSetting.status) {
        data['play']['_cacheData'] = playSetting;
        data['play']['status'] = false;
      }
      if (playScheduleSetting && playScheduleSetting.locationType !== 15 && !playScheduleSetting.status) {
        data['play']['_cacheDataSchedule'] = playScheduleSetting;
        data['play']['scheduleStatus'] = false;
      }
      if (videoSetting && videoSetting.locationType !== 15 && !videoSetting.status) {
        data['video']['_cacheData'] = videoSetting;
        data['video']['status'] = false;
      }
      if (videoScheduleSetting && videoScheduleSetting.locationType !== 15 && !videoScheduleSetting.status) {
        data['video']['_cacheDataSchedule'] = videoScheduleSetting;
        data['video']['scheduleStatus'] = false;
      }
      if (gameSetting && gameSetting.locationType !== 15 && !gameSetting.status) {
        data['game']['_cacheData'] = gameSetting;
        data['game']['status'] = false;
      }
      if (gameScheduleSetting && gameScheduleSetting.locationType !== 15 && !gameScheduleSetting.status) {
        data['game']['_cacheDataSchedule'] = gameScheduleSetting;
        data['game']['scheduleStatus'] = false;
      }
      if (radioSetting && radioSetting.locationType !== 15 && !radioSetting.status) {
        data['radio']['_cacheData'] = radioSetting;
        data['radio']['status'] = false;
      }
      if (radioScheduleSetting && radioScheduleSetting.locationType !== 15 && !radioScheduleSetting.status) {
        data['radio']['_cacheDataSchedule'] = radioScheduleSetting;
        data['radio']['scheduleStatus'] = false;
      }
      return data;
    }
  };

  /**
   * @name getFeatures
   * @desc Fethces location features
   * @return {void}
   */
  getFeatures = () => {
    this.showLoader();
    const isHousingUnit = !!(this.props.location && this.props.location.type === 'hu');
    let promises = [this.managementService.getFeatures(this.getIDOrUsername(), this.state, this.getLocationType())];
    if (isHousingUnit) {
      promises.push(
        this.managementService.getFeatures(this.props.location.parentLocationId, this.state, this.getLocationType())
      );
    }
    Promise.all(promises)
      .then((results) => {
        let settings = results[0];
        if (isHousingUnit) {
          const parentSettings = results[1];
          settings = {
            postLearnerAccess: settings.postLearnerAccess,
            pathways: this.getFeatureSettings('pathways', settings, parentSettings),
            explore: this.getFeatureSettings('explore', settings, parentSettings),
            forms: this.getFeatureSettings('forms', settings, parentSettings),
            messaging: this.getFeatureSettings('messaging', settings, parentSettings),
            ffmessaging: this.getFeatureSettings('ffmessaging', settings, parentSettings),
            messagingPerson: this.getFeatureSettings('messagingPerson', settings, parentSettings),
            manageRelationships: this.getFeatureSettings('manageRelationships', settings, parentSettings),
            messagingAttorney: this.getFeatureSettings('messagingAttorney', settings, parentSettings),
            autoCreateRelationships: this.getFeatureSettings('autoCreateRelationships', settings, parentSettings),
            clickTileToLaunch: this.getFeatureSettings('clickTileToLaunch', settings, parentSettings),
            explorePageIndividualized: this.getFeatureSettings('explorePageIndividualized', settings, parentSettings),
            general: this.getFeatureSettings('general', settings, parentSettings),
            learnerAccessSchedule: this.getFeatureSettings('learnerAccessSchedule', settings, parentSettings),
            incentiveEngine: this.getFeatureSettings('incentiveEngine', settings, parentSettings),
            play: this.getFeatureSettings('play', settings, parentSettings),
            userDevicePairing: this.getFeatureSettings('userDevicePairing', settings, parentSettings),
            voiceCalls: this.getFeatureSettings('voiceCalls', settings, parentSettings),
            careerCompass: this.getFeatureSettings('careerCompass', settings, parentSettings),
            voiceCallsSchedule: this.getFeatureSettings('voiceCallsSchedule', settings, parentSettings),
            resumeBuilder: this.getFeatureSettings('resumeBuilder', settings, parentSettings),
            clearDevicePairingOnMove: this.getFeatureSettings('clearDevicePairingOnMove', settings, parentSettings),
          };
        }
        this.setState(settings);
        if (this.props.facility) {
          this.getSchedule(this.props.location.active);
        }
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  /**
   * @name getSchedule
   * @param {number} locationId ID of location whose schedule needs to be fetched.
   * @desc Fetches schedule data from service.
   * @return {Promise}
   */
  getSchedule = (locationId) => {
    this.showLoader();
    this.managementService
      .getSchedule(locationId)
      .then((_successLog) => {
        this.setState({
          schedules: _successLog,
        });
        this.hideLoader();
      })
      .catch(() => {
        this.hideLoader();
      });
  };

  /**
   * @name checkFeatureExist
   * @param {array} featureList
   * @param {string} featureName
   * @desc Checks if features exists in list in order to
   * show menu.
   * @return {boolean}
   */
  checkFeatureExist = (featureList, featureName) => {
    // SHOW ALL FEATURES IN CASE
    // FEATURE LIST IS NOT DEFINED
    if (!featureList) {
      return true;
    }
    return featureList.indexOf(featureName) > -1;
  };

  disableFeatureToggle = (feature) => {
    if (this.getLocationType() === 'learner' && !this.props.featureStatusAtFacility[feature.featureName]) {
      return true;
    } else {
      if (this.props.location && this.props.location.type === 'hu') {
        if (feature.locationType === 3 && !feature.status) {
          return true;
        }
      }
    }
    return false;
  };

  showWarningIfApplicable = (status, cb) => {
    if (this.getLocationType() === 'facility') {
      if (this.props.location && this.props.location.type === 'hu') {
        this.setState({
          warningCallback: cb,
          showConfirmationDialog: true,
          warningMessage:
            'All housing units inherit their parent’s settings, these changes might be overridden by the facility settings.',
        });
      } else if (status === true) {
        this.setState({
          warningCallback: cb,
          showConfirmationDialog: true,
          warningMessage:
            'By turning this on all Housing Units will use their previous settings. Please review all housing unit settings to ensure they are configured as you expect.',
        });
      } else {
        cb();
      }
    } else {
      cb();
    }
  };

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      warningMessage: '',
      warningCallback: () => {},
    });
  };

  isUserDeviceAvailable = () => {
    return this.state.userDevicePairing.status || this.getLocationType() === 'learner';
  };
  shouldShowServiceCodes = () => {
    return this.props.location && this.props.location.type !== 'hu';
  };

  render() {
    const { classes, unleashProps } = this.props;
    const currentLocation = this.props.location ? this.props.location.locationName : this.props.currentLocationDisableText;
    const parentLocation = this.props.location
      ? this.props.location.parentLocationName
      : this.props.parentLocationDisableText;
    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <ConfirmationModal
          isOpen={this.state.showConfirmationDialog}
          onClose={this.closeConfirmationDialog}
          onContinue={() => {
            this.state.warningCallback();
            this.closeConfirmationDialog();
          }}
          heading="Warning!"
          description={this.state.warningMessage}
        />

        <Grid container className={classes.mainAccordion}>
          <Loader isLoading={this.state.isLoading} />
          {this.shouldShowServiceCodes() && this.global.isSuperAdmin && (
            <ServiceCodes locationId={this.props.location.active} />
          )}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.General) && (
            <General
              updateSettingsState={this.updateSettingsState}
              updateSettingSchedule={this.updateSettingSchedule}
              updateSchedule={this.updateSchedule}
              learnerAccessSchedule={this.state.learnerAccessSchedule}
              schedules={this.state.schedules}
              {...this.state.general}
              {...this.state.loader}
              disableFeatureToggle={this.disableFeatureToggle}
              currentLocation={currentLocation}
              parentLocation={parentLocation}
              sectionTitle={this.props.generalSectionTitle}
              showWarningIfApplicable={this.showWarningIfApplicable}
              postReleaseProps={{
                attributes: this.state.postLearnerAccess ? [this.state.postLearnerAccess] : null,
                id: this.props.location.active,
                locationType: this.getLocationType(),
              }}
              isSuperAdmin={this.global.isSuperAdmin}
            />
          )}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.IncentiveEngine) && (
            <IncentiveEngine
              updateSettingsState={this.updateSettingsState}
              {...this.state.incentiveEngine}
              {...this.state.loader}
              disableFeatureToggle={this.disableFeatureToggle}
              currentLocation={currentLocation}
              parentLocation={parentLocation}
              showWarningIfApplicable={this.showWarningIfApplicable}
            />
          )}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.Play) &&
            (this.getLocationType() === 'learner' ? (
              <PermissionControl permissionName="Lock_Student_Out_Of_Entertainment_Center">
                <Play
                  features={this.props.playFeatures}
                  updateSettingsState={this.updateSettingsState}
                  updateSettingSchedule={this.updateSettingSchedule}
                  updateSchedule={this.updateSchedule}
                  schedules={this.state.schedules}
                  {...this.state.play}
                  {...this.state.loader}
                  disableFeatureToggle={this.disableFeatureToggle}
                  currentLocation={currentLocation}
                  parentLocation={parentLocation}
                  showSnackBar={this.props.showSnackBar}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                />
              </PermissionControl>
            ) : (
              <Play
                features={this.props.playFeatures}
                updateSettingsState={this.updateSettingsState}
                updateSettingSchedule={this.updateSettingSchedule}
                updateSchedule={this.updateSchedule}
                schedules={this.state.schedules}
                {...this.state.play}
                {...this.state.loader}
                disableFeatureToggle={this.disableFeatureToggle}
                currentLocation={currentLocation}
                parentLocation={parentLocation}
                showSnackBar={this.props.showSnackBar}
                showWarningIfApplicable={this.showWarningIfApplicable}
              />
            ))}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.Forms) && (
            <Forms
              updateSettingsState={this.updateSettingsState}
              {...this.state.forms}
              {...this.state.loader}
              disableFeatureToggle={this.disableFeatureToggle}
              currentLocation={currentLocation}
              parentLocation={parentLocation}
              showWarningIfApplicable={this.showWarningIfApplicable}
            />
          )}
          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.FFMessaging) &&
            this.global.isSuperAdmin &&
            (this.getLocationType() === 'learner' ? (
              <PermissionControl permissionName="Lock_Student_Out_Of_Messaging">
                <FFMessaging
                  type={this.getLocationType()}
                  updateSettingsState={this.updateSettingsState}
                  {...this.state.ffmessaging}
                  {...this.state.loader}
                  disableFeatureToggle={this.disableFeatureToggle}
                  isSuperAdmin={this.global.isSuperAdmin}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                />
              </PermissionControl>
            ) : (
              <FFMessaging
                type={this.getLocationType()}
                updateSettingsState={this.updateSettingsState}
                {...this.state.ffmessaging}
                {...this.state.loader}
                disableFeatureToggle={this.disableFeatureToggle}
                isSuperAdmin={this.global.isSuperAdmin}
                showWarningIfApplicable={this.showWarningIfApplicable}
              />
            ))}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.Messaging) &&
            (this.getLocationType() === 'learner' ? (
              <PermissionControl permissionName="Lock_Student_Out_Of_Messaging">
                <Messaging
                  type={this.getLocationType()}
                  updateSettingsState={this.updateSettingsState}
                  {...this.state.messaging}
                  {...this.state.loader}
                  manageRelationships={this.state.manageRelationships}
                  messagingAttorney={this.state.messagingAttorney}
                  autoCreateRelationships={this.state.autoCreateRelationships}
                  disableFeatureToggle={this.disableFeatureToggle}
                  currentLocation={currentLocation}
                  parentLocation={parentLocation}
                  isSuperAdmin={this.global.isSuperAdmin}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                />
              </PermissionControl>
            ) : (
              <Messaging
                type={this.getLocationType()}
                updateSettingsState={this.updateSettingsState}
                {...this.state.messaging}
                {...this.state.loader}
                manageRelationships={this.state.manageRelationships}
                messagingAttorney={this.state.messagingAttorney}
                autoCreateRelationships={this.state.autoCreateRelationships}
                disableFeatureToggle={this.disableFeatureToggle}
                currentLocation={currentLocation}
                parentLocation={parentLocation}
                isSuperAdmin={this.global.isSuperAdmin}
                showWarningIfApplicable={this.showWarningIfApplicable}
              />
            ))}
          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.ResumeBuilder) &&
            this.getLocationType() === 'facility' &&
            this.global.isSuperAdmin && (
              <UnleashProvider flagName={UnleashService.FLAGS.RESUME_BUILDER_TOGGLE}>
                <>
                  <ResumeBuilder
                    updateSettingsState={this.updateSettingsState}
                    {...this.state.resumeBuilder}
                    {...this.state.loader}
                    disableFeatureToggle={this.disableFeatureToggle}
                    currentLocation={currentLocation}
                    parentLocation={parentLocation}
                    showWarningIfApplicable={this.showWarningIfApplicable}
                    locationType={this.getLocationType()}
                  />
                </>
              </UnleashProvider>
            )}

          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.VoiceCalls) &&
            this.global.isSuperAdmin && (
              <UnleashProvider flagName={UnleashService.FLAGS.VOICE_CALL}>
                <VoiceCalls
                  type={this.getLocationType()}
                  updateSettingsState={this.updateSettingsState}
                  updateSettingSchedule={this.updateSettingSchedule}
                  updateSchedule={this.updateSchedule}
                  schedules={this.state.schedules}
                  voiceCallsSchedule={this.state.voiceCallsSchedule}
                  {...this.state.voiceCalls}
                  {...this.state.loader}
                  disableFeatureToggle={this.disableFeatureToggle}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                />
              </UnleashProvider>
            )}
          {this.getLocationType() === 'learner' && (
            <PermissionControl permissionName="Access_Biometric_Authentication_At_Person_Level">
              <BiometricAuthentication userAttributes={this.props.userAttributes} userId={this.props.user.id} />
            </PermissionControl>
          )}
          {this.getLocationType() === 'learner' && this.global.isSuperAdmin && (
            <PostReleaseLearner
              attributes={this.props.userAttributes}
              id={this.props.user.id}
              locationType={this.getLocationType()}
            />
          )}
          {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.UserDevicePairing) &&
            (this.global.isSuperAdmin ||
              ((this.global.isFacilityAdmin || (this.global.isTeacher && unleashProps.isFlagEnabled())) &&
                this.isUserDeviceAvailable())) && (
              <>
                <UserDevicePairing
                  updateSettingsState={this.updateSettingsState}
                  {...this.state.userDevicePairing}
                  clearDevicePairingOnMove={this.state.clearDevicePairingOnMove}
                  {...this.state.loader}
                  disableFeatureToggle={this.disableFeatureToggle}
                  locationName={currentLocation}
                  locationId={this.props.location ? this.props.location.active : null}
                  parentLocation={parentLocation}
                  user={this.props.user}
                  unleashProps={unleashProps}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                  locationType={this.getLocationType()}
                  isSuperAdmin={this.global.isSuperAdmin}
                  isTeacher={this.global.isTeacher}
                  isFacilityAdmin={this.global.isFacilityAdmin}
                />
              </>
            )}
          {this.getLocationType() === 'learner' &&
            this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.ResumeBuilder) &&
            (this.global.isSuperAdmin || this.global.isFacilityAdmin) && (
              <UnleashProvider flagName={UnleashService.FLAGS.LEARNER_TOGGLE_RESUME_BUILDER}>
                <>
                  <ReEntryPreparation
                    updateSettingsState={this.updateSettingsState}
                    {...this.state.reEntryPreparation}
                    {...this.state.loader}
                    disableFeatureToggle={this.disableFeatureToggle}
                    currentLocation={currentLocation}
                    parentLocation={parentLocation}
                    showWarningIfApplicable={this.showWarningIfApplicable}
                    locationType={this.getLocationType()}
                  />
                </>
              </UnleashProvider>
            )}
            {this.checkFeatureExist(this.props.features, ManagementService.MANAGEMENT_FEATURE_ENUM.CareerCompass) &&
            this.global.isSuperAdmin && (
              <UnleashProvider flagName={UnleashService.FLAGS.CAREER_COMPASS_ACCESS_TOGGLE}>
                <CareerCompass
                  updateSettingsState={this.updateSettingsState}
                  {...this.state.careerCompass}
                  {...this.state.loader}
                  disableFeatureToggle={this.disableFeatureToggle}
                  currentLocation={currentLocation}
                  parentLocation={parentLocation}
                  showWarningIfApplicable={this.showWarningIfApplicable}
                  locationType={this.getLocationType()}
                />
              </UnleashProvider>
            )}
        </Grid>
      </Suspense>
    );
  }
}

export default withStyles(styles)(withUnleash(Settings, UnleashService.FLAGS.TEACHER_LEARNER_SETTING));
