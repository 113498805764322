import useUnleashHook from './useUnleashHook';

/**
 * @name UnleashProvider
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render if flag is enabled
 * @param {string} props.flagName - Flag name to check if enabled
 * @param {React.ReactNode} [props.fallbackComponent] - Component to render if flag is disabled
 * @description Renders children if flag is enabled, otherwise renders fallback component.
 * @returns {React.ReactElement} The rendered component
 */

const UnleashProvider = ({ children, flagName, fallbackComponent = null }) => {
  const { isFlagEnabled } = useUnleashHook(flagName);
  return isFlagEnabled() && Boolean(children) ? children : fallbackComponent;
};

export default UnleashProvider;
