import EcoIcon from '@material-ui/icons/Eco';
import React from 'react';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import WorkIcon from '@material-ui/icons/Work';
import { Image } from '@react-pdf/renderer';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import { BooksIcon, EnrichmentIcon, ReentryIcon, RehabilitationIcon, SkillsIcon } from '../components/icons/index';

let TOKEN = null;
function setToken(token) {
  TOKEN = token;
}
export { TOKEN, setToken };

let JANUS_TOKEN = null;
function setJanusToken(token) {
  JANUS_TOKEN = token;
}
export { setJanusToken };

let JURISDICTION_WITH_CHILDREN = null;
function setJurisdictionWithChildren(jurisdictionWithChildren) {
  JURISDICTION_WITH_CHILDREN = jurisdictionWithChildren;
}
export { setJurisdictionWithChildren, JURISDICTION_WITH_CHILDREN };

let JANUS_PROFILE_TOKEN = null;
function setJanusProfileToken(token) {
  JANUS_PROFILE_TOKEN = token;
}
export { setJanusProfileToken };

export const getJanusToken = () => {
  if (JANUS_PROFILE_TOKEN) {
    return JANUS_PROFILE_TOKEN;
  }
  if (JANUS_TOKEN) {
    return JANUS_TOKEN;
  }
  return '';
};

let FACILITY_MESSAGING_PERMISSIONS = {
  isInitiallyFetched: false,
  facilities: [],
};
function setFacilityMessagePermission(collection) {
  FACILITY_MESSAGING_PERMISSIONS.facilities = collection;
}
export { FACILITY_MESSAGING_PERMISSIONS, setFacilityMessagePermission };

let FACILITY_LOCATION_SETTINGS_PERMISSIONS = {
  isInitiallyFetched: false,
  facilities: [],
};
function setFacilityLocationSettingsPermission(collection) {
  FACILITY_LOCATION_SETTINGS_PERMISSIONS.facilities = collection;
}
export { FACILITY_LOCATION_SETTINGS_PERMISSIONS, setFacilityLocationSettingsPermission };

let SELECTED_LOCATION = {};
function setLocation(value) {
  SELECTED_LOCATION = value;
}
export { SELECTED_LOCATION, setLocation };

let LOCATIONS = [];
function setLocations(value) {
  LOCATIONS = value;
}
export { LOCATIONS, setLocations };

let SELECTED_HOUSING_UNIT = {};
function setHousingUnit(value) {
  SELECTED_HOUSING_UNIT = value;
}
export { SELECTED_HOUSING_UNIT, setHousingUnit };

let HOUSING_UNITS = [];
function setHousingUnits(value) {
  HOUSING_UNITS = value;
}
export { HOUSING_UNITS, setHousingUnits };

let USERS = [];
function setUsers(value) {
  USERS = [];
}
export { USERS, setUsers };

let USER_CONTEXT = {};
function setUserContext(value) {
  USER_CONTEXT = value;
}
export { USER_CONTEXT, setUserContext };

let STAFF_DETAILS = {};
function setStaffDetails(data) {
  STAFF_DETAILS = data;
}
export { STAFF_DETAILS, setStaffDetails };

let CommunicationContactsData = null;
function setCommunicationContactsData(value) {
  CommunicationContactsData = value;
}
export { CommunicationContactsData, setCommunicationContactsData };

let UserCommunicationContactsData = null;
function setUserCommunicationContactsData(value) {
  UserCommunicationContactsData = value;
}
export { UserCommunicationContactsData, setUserCommunicationContactsData };

let RelationshipData = null;
function setRelationshipData(value) {
  RelationshipData = value;
}
export { RelationshipData, setRelationshipData };

//================== New Location Features ================//
let FEATURES = [
  { featureKey: 1, featureName: 'Play', status: true },
  { featureKey: 2, featureName: 'Play_Schedule', status: false },
  { featureKey: 3, featureName: 'Video', status: false },
  { featureKey: 4, featureName: 'Video_Schedule', status: false },
  { featureKey: 5, featureName: 'Game', status: true },
  { featureKey: 6, featureName: 'Game_Schedule', status: false },
  { featureKey: 7, featureName: 'Radio', status: true },
  { featureKey: 8, featureName: 'Radio_Schedule', status: false },
  { featureKey: 9, featureName: 'Learner_Portal_Access', status: true },
  { featureKey: 10, featureName: 'Admin_Portal_Access', status: true },
  { featureKey: 11, featureName: 'Incentive_Engine', status: false },
  { featureKey: 12, featureName: 'Pathways', status: true },
  { featureKey: 13, featureName: 'Explore', status: true },
  { featureKey: 15, featureName: 'Forms', status: true },
  { featureKey: 16, featureName: 'Messaging', status: true },
  { featureKey: 17, featureName: 'Messaging_Person', status: true },
  { featureKey: 18, featureName: 'Auto_Create_Relationships', status: false },
  { featureKey: 19, featureName: 'Manage_Relationships', status: true },
  { featureKey: 20, featureName: 'MESSAGING_ATTORNEY', status: false },
  { featureKey: 21, featureName: 'Learner_Portal_Access_Schedule', status: false },
  { featureKey: 22, featureName: 'Click_Tile_To_Launch', status: true },
  { featureKey: 23, featureName: 'Explore_Page_Individualized', status: true },
  { featureKey: 29, featureName: 'Voice_Calls', status: false },
  { featureKey: 30, featureName: 'Voice_Calls_Schedule', status: false },
];
function setFeatures(features) {
  FEATURES = features;
}
export { FEATURES, setFeatures };

/**
 * Temporary constants for Featured_Content and Milestone_Mapper feature.
 * Will be moved to the FEATURES array after the unleash feature flag cleanup.
 */
const FEATURED_CONTENT_FEATURE = { featureKey: 32, featureName: 'Featured_Content', status: true };
const MILESTONE_MAPPER_FEATURE = { featureKey: 33, featureName: 'Milestone_Mapper', status: true };
export { FEATURED_CONTENT_FEATURE, MILESTONE_MAPPER_FEATURE };

// =============== ATTRIBUTES ========================= //
let ATTRIBUTE_NAMES = {
  FACIAL_RECOGNITION: 'Facial Recognition',
  PORTAL_ACCESS: 'Portal_Access',
};
export { ATTRIBUTE_NAMES };

//================== UAT ENDPOINTS =====================//
export const CAREER_BASE_URL = process.env.REACT_APP_CAREER_API;
export const GATEWAY_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APDS_GATEWAY_BASE_URL = process.env.REACT_APP_APDS_API_BASE_URL;
export const HERMES_BASE_URL = process.env.REACT_APP_HERMES_URL;
export const JANUS_BASE_URL = process.env.REACT_APP_JANUS_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const PDQ_TEMPLATE_ID = process.env.REACT_APP_ASSESSMENT_ACTIVITY_TEMPLATE_ID_PDQ;
export const API_BASE_URL = 'https://argos-auth-ecs-uat.apds-services.com/spring-security-oauth-server';
export const APDS_LOCATION_ID = 1698197;
export const APDS_ROLE_KEY = 25;
export const LOCATION_TYPE_ID = 18;
export const SISENSE_CONFIGURATION =
  window.SISENSE_CONFIGURATION && window.SISENSE_CONFIGURATION[0]
    ? window.SISENSE_CONFIGURATION
    : [
        {
          url: null,
          dashboardID: null,
          settings: {
            showToolbar: true,
            showLeftPane: true,
            showRightPane: true,
          },
        },
      ];

export const SOCKET_EVENT_TYPES = {
  MESSAGE: 'message',
  ANNOUNCEMENT: 'announcement',
};

export const POLL_CONFIG = window.POLL_CONFIG
  ? window.POLL_CONFIG
  : {
      APPLICATION_WIDE_POLL_INTERVAL: 120000,
      ENTERTAINMENT_POLL_INTERVAL: 120000,
      USE_POLLING: true,
      USE_ANNOUNCEMENT_POLLING: true
    };

//================== PRODUCTION ENDPOINTS =======================//
// export const GATEWAY_BASE_URL = 'https://argos-resource-ecs-prod.apds-services.com'
// export const API_BASE_URL = 'https://argos-auth-ecs-prod.apds-services.com/spring-security-oauth-server'
// export const APDS_LOCATION_ID = 1697851
// export const APDS_ROLE_KEY = 24
// export const LOCATION_TYPE_ID = 17

//export const API_BASE_URL = '/api';
export const MESSAGING_POLL_INTERVAL = 20000;
export const SOCKETIO_RECONNECT_INTERVAL = 120000;

export const MANAGE_BALANCE_TYPE = {
  ADD: 0,
  DEDUCT: 1,
};

export const ATTORNEY_BLACK_BAR_TIME_OUT = 30000;

export const ACCESS_TOKEN = 'accessToken';

export const POLL_LIST_SIZE = 30;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const USER_CARD_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm a';
export const LOCATION_TYPES = [
  { id: 3, type: 'Correctional Facility' },
  { id: 15, type: 'Housing Unit' },
  { id: parseInt(process.env.REACT_APP_APDS_JURISDICTION_ID), type: 'Jurisdiction' },
];

export const ICONS = `${process.env.PUBLIC_URL}/assets/icons/`;

export const ROLES = {
  LEARNERS: 'apds_connected_corrections_role',
  APDS_HORIZON: 'apds_horizon_role',
  APDS_HORIZON_SUPER_ADMIN: 'apds_horizon_super_admin_role',
  APDS_HORIZON_FACILITY_ADMIN: 'apds_horizon_facility_admin_role',
  APDS_HORIZON_PROGRAMMING_STAFF: 'apds_horizon_programming_staff_role',
  APDS_HORIZON_ATTORNEY: 'apds_attorney_role',
  APDS_HORIZON_TEACHER: 'apds_teacher_role',
  SUPER_ADMIN: 'Super Admin',
  ATTORNEY: 'Attorney',
  STAFF: 'Staff',
  TEACHER: 'Teacher',
  MESSAGING_ONLY: 'Messaging Only'
};

export const ROLES_LIST = ['Super/APDS Admin', 'facility admins', 'Attorney', 'Teacher', 'Messaging Only'];

export const SERVICES_NAMES = {
  Paxen: 'Paxen/HMH',
  D2L: 'D2L',
  EsentialED: 'Essential Ed',
};

export const CONTENT_AREAS_ENUM = process.env.REACT_APP_CONTENT_AREAS
  ? JSON.parse(process.env.REACT_APP_CONTENT_AREAS)
  : {
      Rehab: 1,
      Entertainment: 3,
      ReEntry: 4,
      Education: 6,
      Enrichment: 2,
      Workforce: 5,
      Misc: 9,
      Communication: 15,
    };

export const CONTENT_AREA = {
  [CONTENT_AREAS_ENUM.Communication]: {
    id: CONTENT_AREAS_ENUM.Communication,
    icon: 'ic-commuincation',
    class: 'communication-chip',
    name: 'Communication',
    bgColor: '#d07f06',
    icons: {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-11 -6)">
              <g transform="translate(11.8 6)">
                <path d="M0 0L24 0 24 24 0 24z" />
                <circle cx="9" cy="9" r="4" fill="#d07f06" fill-rule="nonzero" />
                <path
                  fill="#d07f06"
                  fill-rule="nonzero"
                  d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
      round: (
        <div
          style={{
            width: 34,
            height: 34,
            borderRadius: 34,
            backgroundColor: '#fbf5ec',
            border: '1px solid #efd9b8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(-11 -6)">
                <g transform="translate(11.8 6)">
                  <path d="M0 0L24 0 24 24 0 24z" />
                  <circle cx="9" cy="9" r="4" fill="#d07f06" fill-rule="nonzero" />
                  <path
                    fill="#d07f06"
                    fill-rule="nonzero"
                    d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      ),
      white: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-11 -6)">
              <g transform="translate(11.8 6)">
                <path d="M0 0L24 0 24 24 0 24z" />
                <circle cx="9" cy="9" r="4" fill="#FFFFFF" fill-rule="nonzero" />
                <path
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
      dark: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-11 -6)">
              <g transform="translate(11.8 6)">
                <path d="M0 0L24 0 24 24 0 24z" />
                <circle cx="9" cy="9" r="4" fill="#d07f06" fill-rule="nonzero" />
                <path
                  fill="#d07f06"
                  fill-rule="nonzero"
                  d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
      darkCustom: (size) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-11 -6)">
              <g transform="translate(11.8 6)">
                <path d="M0 0L24 0 24 24 0 24z" />
                <circle cx="9" cy="9" r="4" fill="#d07f06" fill-rule="nonzero" />
                <path
                  fill="#d07f06"
                  fill-rule="nonzero"
                  d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
                />
              </g>
            </g>
          </g>
        </svg>
      ),
      pdf: (size) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/ic-communication.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      color: '#d07f06',
      borderColor: '#efd9b8',
    },
  },
  [CONTENT_AREAS_ENUM.Rehab]: {
    id: CONTENT_AREAS_ENUM.Rehab,
    icon: 'ic-widgets',
    class: 'rehab-chip',
    name: 'Rehabilitation',
    roundIcon: 'rehab-icon',
    bgColor: '#0a827d',
    iconFill: '#E3A634',
    shield: 'rehabilation-shield',
    icons: {
      mainCustom: (size, fill) => <RehabilitationIcon size={size} fill={fill} />,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
          <path
            fill="#0a827d"
            fill-rule="evenodd"
            d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
          />
        </svg>
      ),
      round: (
        <div
          style={{
            width: 34,
            height: 34,
            borderRadius: 34,
            backgroundColor: '#ebf7f3',
            border: '1px solid #b2dacf',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path
              fill="#0a827d"
              fill-rule="evenodd"
              d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
            />
          </svg>
        </div>
      ),
      white: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
          <path
            fill="#FFFFFF"
            fill-rule="evenodd"
            d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
          />
        </svg>
      ),
      dark: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
          <path
            fill="#0a827d"
            fill-rule="evenodd"
            d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
          />
        </svg>
      ),
      darkCustom: (size) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
          <path
            fill="#0a827d"
            fill-rule="evenodd"
            d="M12.8 1.25c4.644 0 8.4 3.756 8.4 8.4 0 3.348-1.968 6.24-4.8 7.584v5.616H8v-3.6H6.8c-1.32 0-2.4-1.08-2.4-2.4v-3.6H2.6c-.492 0-.78-.564-.48-.96l2.304-3.072C4.64 4.778 8.3 1.25 12.8 1.25zm2.352 4.884c-1.022 0-2.002.652-2.352 1.543-.35-.89-1.331-1.543-2.352-1.543-1.33 0-2.352.994-2.352 2.315 0 1.816 1.982 3.22 4.704 5.917 2.722-2.697 4.704-4.101 4.704-5.917 0-1.321-1.023-2.315-2.352-2.315z"
          />
        </svg>
      ),
      pdf: (size, fill) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/rehabilitation.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      color: '#017e5b',
      borderColor: '#b2dacf',
      backgroundColor: '#ebf7f3',
    },
  },
  [CONTENT_AREAS_ENUM.Entertainment]: {
    id: CONTENT_AREAS_ENUM.Entertainment,
    icon: 'ic-entertainment',
    class: 'entertainment-chip',
    name: 'Entertainment',
    roundIcon: 'entertainment-icon',
    bgColor: '#c92563',
    shield: null,
    icons: {
      icon: <img alt="icon" src={`/assets/icons/ic-entertainment.png`} />,
      round: <img alt="round-icon" src={`/assets/icons/entertainment-icon.png`} />,
      white: (
        <MusicNoteIcon
          style={{
            color: 'white',
            fontSize: 20,
          }}
        />
      ),
      dark: (
        <img
          alt="icon"
          style={{
            width: 20,
          }}
          src={`/assets/icons/ic-entertainment.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#f2c9d9',
      color: '#cb2061',
      backgroundColor: '#fcf3f6',
    },
  },
  [CONTENT_AREAS_ENUM.ReEntry]: {
    id: CONTENT_AREAS_ENUM.ReEntry,
    icon: 'ic-launch',
    class: 'reEntry-chip',
    name: 'Re-Entry',
    roundIcon: 're-entry-icon',
    bgColor: '#8a51dd',
    iconFill: '#7E8995',
    shield: 're-entry-shield',
    icons: {
      mainCustom: (size, fill) => <ReentryIcon size={size} fill={fill} />,
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
          <path
            fill="#8a51dd"
            fill-rule="evenodd"
            d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
            style="&#10;"
          />
        </svg>
      ),
      round: (
        <div
          style={{
            width: 34,
            height: 34,
            borderRadius: 34,
            backgroundColor: '#F4EEFC',
            border: '1px solid #8743DF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
            <path
              fill="#8A51DD"
              fill-rule="evenodd"
              d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
            />
          </svg>
        </div>
      ),
      white: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
          <path
            fill="#FFFFFF"
            fill-rule="evenodd"
            d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
          />
        </svg>
      ),
      dark: (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
          <path
            fill="#8A51DD"
            fill-rule="evenodd"
            d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
          />
        </svg>
      ),
      darkCustom: (size) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`}>
          <path
            fill="#8a51dd"
            fill-rule="evenodd"
            d="M16.304 2v.014h4.783v15.224H23v1.905h-3.826V3.919H9.66v15.224H7.696L1 22V4.789L7.696 2h8.608zm-3.347 8.571l.747 1.637 1.644.744-1.644.744-.747 1.637-.748-1.637-1.644-.744 1.644-.744.748-1.637zm-8.13 2.858H2.912v1.904h1.913V13.43zm11.477-5.715l.6 1.308 1.313.597-1.313.597-.6 1.308-.6-1.308-1.313-.597 1.314-.597.6-1.308z"
          />
        </svg>
      ),
      pdf: (size) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/re-entry.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#8743DF',
      color: '#8a51dd',
      backgroundColor: '#F4EEFC',
    },
  },
  [CONTENT_AREAS_ENUM.Education]: {
    id: CONTENT_AREAS_ENUM.Education,
    icon: 'ic-school',
    class: 'education-chip',
    name: 'Education',
    roundIcon: 'education-icon',
    bgColor: '#3c6a91',
    iconFill: '#4E9D97',
    shield: 'education-shield',
    icons: {
      mainCustom: (size, fill) => <BooksIcon size={size} fill={fill} />,
      icon: <img alt="icon" src={`/assets/icons/ic-school.png`} />,
      round: <img alt="round-icon" src={`/assets/icons/education-icon.png`} />,
      white: (
        <img
          alt="icon"
          style={{
            width: 20,
          }}
          src={`/assets/icons/ic-school-white.png`}
        />
      ),
      dark: (
        <img
          alt="icon"
          style={{
            width: 20,
          }}
          src={`/assets/icons/ic-school.png`}
        />
      ),
      darkCustom: (size) => (
        <img
          alt="icon"
          style={{
            width: size,
          }}
          src={`/assets/icons/ic-school.png`}
        />
      ),
      pdf: (size) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/books.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#B9CAD9',
      color: '#396993',
      backgroundColor: '#E5F1FF',
    },
  },
  [CONTENT_AREAS_ENUM.Enrichment]: {
    id: CONTENT_AREAS_ENUM.Enrichment,
    icon: 'ic-local-movies',
    class: 'enrichment-chip',
    name: 'Enrichment',
    roundIcon: 'enrich-icon',
    bgColor: '#737982',
    iconFill: '#1C79BE',
    shield: 'enrichment-shield',
    icons: {
      mainCustom: (size, fill) => <EnrichmentIcon size={size} fill={fill} />,
      icon: (
        <WbIncandescentIcon
          style={{
            color: '#737982',
            fontSize: 20,
            transform: 'rotate(180deg)',
            marginTop: -4,
          }}
        />
      ),
      round: (
        <div
          style={{
            width: 34,
            height: 34,
            borderRadius: 34,
            backgroundColor: '#F4F5F6',
            border: '1px solid #D2D4D7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <WbIncandescentIcon
            style={{
              color: '#737982',
              fontSize: 20,
              transform: 'rotate(180deg)',
              marginTop: -4,
            }}
          />
        </div>
      ),
      white: (
        <WbIncandescentIcon
          style={{
            color: 'white',
            fontSize: 20,
            transform: 'rotate(180deg)',
            marginTop: -4,
          }}
        />
      ),
      dark: (
        <WbIncandescentIcon
          style={{
            color: '#737982',
            fontSize: 20,
            transform: 'rotate(180deg)',
            marginTop: -4,
          }}
        />
      ),
      darkCustom: (size) => (
        <WbIncandescentIcon
          style={{
            color: '#737982',
            fontSize: size,
            transform: 'rotate(180deg)',
            marginTop: -4,
          }}
        />
      ),
      pdf: (size) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/enrichment.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#D2D4D7',
      color: '#696E77',
      backgroundColor: '#F4F5F6',
    },
  },
  [CONTENT_AREAS_ENUM.Workforce]: {
    id: CONTENT_AREAS_ENUM.Workforce,
    icon: 'ic-work',
    class: 'workforce-chip',
    name: 'Workforce Skills',
    roundIcon: 'work-icon',
    bgColor: '#f15b4a',
    iconFill: '#0F445A',
    shield: 'workforce-shield',
    icons: {
      mainCustom: (size, fill) => <SkillsIcon size={size} fill={fill} />,
      icon: (
        <WorkIcon
          style={{
            color: '#F14B32',
            fontSize: 20,
          }}
        />
      ),
      round: (
        <div
          style={{
            width: 34,
            height: 34,
            borderRadius: 34,
            backgroundColor: '#fdf4f2',
            border: '1px solid #FACAC2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <WorkIcon
            style={{
              color: '#F14B32',
              fontSize: 20,
            }}
          />
        </div>
      ),
      white: (
        <WorkIcon
          style={{
            color: 'white',
            fontSize: 20,
          }}
        />
      ),
      dark: (
        <WorkIcon
          style={{
            color: '#f15b4a',
            fontSize: 20,
          }}
        />
      ),
      darkCustom: (size) => (
        <WorkIcon
          style={{
            color: '#f15b4a',
            fontSize: size,
          }}
        />
      ),
      pdf: (size) => (
        <Image
          alt="icon"
          style={{
            width: size,
            height: size,
          }}
          src={`/assets/icons/skills.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#FACAC2',
      color: '#F14B32',
      backgroundColor: '#FDF4F2',
    },
  },
  [CONTENT_AREAS_ENUM.Misc]: {
    id: CONTENT_AREAS_ENUM.Misc,
    icon: 'ic-category',
    class: 'misc-chip',
    name: 'Miscellaneous',
    roundIcon: 'misc-icon',
    bgColor: '#2d2d2d',
    shield: 'misc-shield',
    icons: {
      icon: <img alt="icon" src={`/assets/icons/ic-category.png`} />,
      round: <img alt="round-icon" src={`/assets/icons/misc-icon.png`} />,
      white: (
        <img
          alt="icon"
          style={{
            width: 20,
          }}
          src={`/assets/icons/ic-category-white.png`}
        />
      ),
      dark: (
        <img
          alt="icon"
          style={{
            width: 20,
          }}
          src={`/assets/icons/ic-category.png`}
        />
      ),
    },
    chip: {
      border: '1px solid',
      borderColor: '#2d2d2d',
      color: '#000000',
      backgroundColor: '#2d2d2d61',
    },
  },
};

export const ROUTES = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/dashboard:id': 'Location',
  '/messages/:location': null,
  '/messages/:location/:threadId:': 'SELECTED_LOCATION',
  '/incentives': 'Incentive Engine',
  '/outcomes-and-progress': 'Outcomes & Progress',
  '/users': 'Users',
  '/todos': 'Todos',
  '/manageRoles': 'Roles & Permissions',
  '/manageLocations': 'Manage Locations',
  '/manageRoles/:roleName': null,
  '/manageRoles/:roleName/:roleId': (url, match) => `${decodeURIComponent(match[':roleName'])}`,
  '/outcomes-and-progress/:locationName': null,
  '/outcomes-and-progress/:locationName/:locationId': (url, match) => match[':locationName'],
  '/outcomes-and-progress/:locationName/:locationId/:unitName': null,
  '/outcomes-and-progress/:locationName/:locationId/:unitName/:unitId': (url, match) => match[':unitName'],
};

export const SIDE_MENU = {
  DRAWER_WIDTH: 249,
  DRAWER_CLOSE_WIDTH: 60,
  DRAWER_ITEM_HEIGHT: 120,
};

export const ASSIGNMENT_TYPE = {
  ASSIGNE: 1,
  UNASSIGNE: 2,
  DELETE: 3,
};

export const COMMUNICATION_FILTER = {
  SearchTerm: 0,
  People: 1,
  Location: 2,
  Staff: 3,
  EventType: 4,
  Status: 5,
};

export const IDLE_TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT;

export const MESSAGE_CONTAINER_FETCH_MSG_ENUM = {
  COMMUNICATION: 0,
  MESSAGE: 1,
};

export const COMMUNICATION_TAB_TYPE = {
  Staff: 0,
  Learner: 1,
  Location: 2,
};

export const CHART_FORMAT = {
  FORMAT_7: 'D/MM',
  FORMAT_30: 'M/D/YY',
  FORMAT_365: 'YYYY-MM-DD',
  FORMAT_DEFAULT: 'YYYY-MM-DD',
  CHECKS: {
    FORMAT_7: 'D/MM',
    FORMAT_30: 'M/D/YY',
    FORMAT_365: 'DD/MMM/YY',
    FORMAT_DEFAULT: 'DD/MMM/YY',
  },
};

export const TOT_DETAILS_ENUM = {
  Learner: 0,
  Location: 1,
};

export const D2L_SERVICE_CODE = {
  ROLE_ID: {
    key: 'roleId',
    serviceName: 'D2L',
    type: 'number',
  },
  FACILITY_ORG_UNIT: {
    key: 'facilityOrgUnitId',
    serviceName: 'D2L',
    type: 'number',
  },
};

export const SERVICE_CODE_DISPLAY_FORMAT = {
  ESSENTIAL_ED: {
    key: 'school_id',
    serviceName: 'Essential Ed',
    type: 'number',
  },
  FASTCASE: {
    key: 'locServiceUrl',
    serviceName: 'Fastcase',
    type: 'url',
  },
  CAREY_GUIDES: {
    key: 'CareyGuideSubAgencyStaffEmail',
    serviceName: 'Carey Guides',
    type: 'email',
  },
  BREAKING_FREE: {
    key: 'bfFacilityCode',
    serviceName: 'Breaking Free',
    type: 'ALPHA_NUMARIC',
  },
  VU_FIND: {
    key: 'vuFindFacilityCode',
    serviceName: 'VuFind',
    type: 'ALPHA_NUMARIC',
  },
};

export const COMMUNICATION_SORT_FIELDS = {
  recipientPersonName: 'recipient_name',
  senderPersonName: 'sender_name',
  createdDate: 'created_date',
  recordStatus: 'record_status',
  locationName: 'location_name',
};

export const SUPPORT_EMAIL = 'helpdesk@apds.works';

export const CDN_BASE_URL = 'https://content.connectedcorrections.com';

export const APDS_FEEDBACK_URL = `https://docs.google.com/forms/d/e/1FAIpQLSdA1pzeG6AxoyBhR07KELiX07yjkAv4MuQ5nADaP4xmrEA76A/viewform `;

export const SUPER_ADMIN_LOCATION_ID = '1699602';
export const PENDO_CONFIG = window.PENDO_CONFIG
  ? window.PENDO_CONFIG
  : {
      isEnable: false,
      ENVIRONMENT: '',
      API_KEY: '',
      SUPER_ADMIN_LOCATION_ID,
    };

// window["PENDO_CONFIG"] = {
//     isEnable: true,
//     ENVIRONMENT: "DEV",
//     API_KEY: "601887c1-a3fc-4004-6337-608a8b635caa"
//   };

export const PROD_URL = `https://admin.connectedcorrections.com`;
export const PRIVACY_POLICY = `${PROD_URL}/privacy-policy.html`;
export const TERMS_OF_SERVICE = `${PROD_URL}/terms-of-service.html`;
export const COPY_WRITE = `${PROD_URL}/copyrights.html`;

export const ANNOUNCEMENT_TYPE = {
  FACILITY: 0,
  LEARNER: 1,
  LEARNER_GROUP: 3,
};

export const ID_TYPES = {
  LOCATION_ID: 'locationId',
  CONTACT_ID: 'contactId',
};

export const NO_LEARNER_GROUPS_FOUND = `No active Learner Groups found. Create or manage Learner Groups in your Staff Profile.`;

export const MAX_ANNOUNCEMENT_LOCATIONS_THRESHOLD = 15;

export const MAX_ANNOUNCEMENT_THRESHOLD_ERROR = `You can send an announcement to up to ${MAX_ANNOUNCEMENT_LOCATIONS_THRESHOLD} locations at a time.`;

export const DATE_FORMAT = {
  DD_MM_YYYY: 'DD/MM/YYYY',
  MM_DD_YYYY: 'MM/DD/YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD-HH:mm',
  YYYY_MM_DD_HHMM: 'YYYY-MM-DD-HHmm',
  YYYY_MM_DD_HH_MM_A: 'YYYY-MM-DD hh:mm A',
  YYYY_MM_DD_HH_MM_A_Z: 'YYYY-MM-DD hh:mm A Z',
  YYYY_MM_DD_HH_MM_Z: 'YYYY-MM-DD HH:mm Z',
  YYYY_MM_DD_HH_MM_SS_Z: 'YYYY-MM-DD HH:mm:ss Z',
  YYYY_MM_DD_HH_MM_SS_A: 'YYYY-MM-DD hh:mm:ss A',
  YYYY_MM_DD_HH_MM_SS_A_Z: 'YYYY-MM-DD hh:mm:ss A Z',
  MM_DD_YYYY_HH_MM_A: 'MM-DD-YYYY hh:mm A',
  MM_DD_YYYY_HH_MM_A_Z: 'MM-DD-YYYY hh:mm A Z',
  MM_DD_YYYY_HH_MM_SS_A: 'MM-DD-YYYY hh:mm:ss A',
  MM_DD_YYYY_HH_MM_SS_A_Z: 'MM-DD-YYYY hh:mm:ss A Z',
  MM_DD_YYYY_HH_MM_SS: 'MM-DD-YYYY HH:mm:ss',
  MM_DD_YYYY_HH_MM: 'MM-DD-YYYY HH:mm',
  MM_DD_YYYY_HH_MM_Z: 'MM-DD-YYYY HH:mm Z',
  MM_DD_YYYY_HH_MM_SS_Z: 'MM-DD-YYYY HH:mm:ss Z',
};

export const LEARNER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const VOICE_PROFILE_STATUS = {
  PROFILE_CREATED: 'PROFILE_CREATED',
}