export const FILE_UPLOAD = {
  ALLOWED_TYPES: ['image/jpeg', 'image/png', 'image/webp'],
  MAX_SIZE: 1048576, // 1MB in bytes
  MIN_WIDTH: 800,
  ERROR_MESSAGES: {
    INVALID_TYPE: 'Please upload only JPG, PNG or WebP images',
    SIZE_EXCEEDED: 'File size should not exceed 1 MB',
    MIN_WIDTH: 'Image width should be at least 800px'
  }
};

export const CHARACTER_LIMITS = {
  DISPLAY_NAME: 100,
  SHORT_DESCRIPTION: 300,
  LONG_DESCRIPTION: 1000
};

export const FORM_FIELDS = {
  DISPLAY_NAME_EN: 'displayNameEn',
  DISPLAY_NAME_ES: 'displayNameEs',
  SHORT_DESCRIPTION_EN: 'shortDescriptionEn',
  SHORT_DESCRIPTION_ES: 'shortDescriptionEs',
  LONG_DESCRIPTION_EN: 'longDescriptionEn',
  LONG_DESCRIPTION_ES: 'longDescriptionEs',
  PROGRAM_LANGUAGE: 'programLanguage',
  CERTIFICATE_ELIGIBILITY: 'certificateEligibility',
  CONTENT_PROVIDER: 'contentProvider',
  PROGRAM_CATEGORY: 'programCategory',
  COURSE_DURATION: 'courseDuration',
  THUMBNAIL_IMAGE: 'thumbnailImage',
  THUMBNAIL_IMAGE_NAME: 'thumbnailImageName'
};
