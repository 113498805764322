import React from 'react';
import Home from './v2/modules/home/.';
import Locations from './components/Locations/locations';
import Users from './v2/shared/components/users/users';
import Settings from './components/settings/settings';
import SisenseDashboard from './components/sisense-dashboard';
import Incentives from './components/dashboard/incentiveengine/incentives';
import NotFound from './components/NotFound';
import Todos from './components/users/HorizonFriends';
import loadData from './helpers/loadData';
import UserAgreement from './components/legal/useragreement';
import CheckEvents from './components/users/checkEvents';
import Management from './components/management/management';
import { Message } from './v2/modules/messaging';
import { ManageRoleAndPermission } from './components/manage-roles-permissions';
import RolesDetail from './components/roles-detail';
import Facility from './components/Locations/facility';
import Unit from './components/Locations/unit';
import { ROLES } from './constants';
import { ManageLocations } from './components/manage-locations';
import { LocationSettings } from './components/manage-locations/edit';
import { Redirect } from 'react-router-dom';
import Profile from './components/management/staff/staff-profile/profile';
import Learner from './components/users/learner/Learner';
import { ContactsListContainer, LearnerProfileContainer } from './components/voice-call/containers';
import { ResumeBuilder } from './v2/modules/resume/resume-builder';
import SuperAdminModule from './components/superadmin-module';
import FriendsAndFamilyModule from './components/friends-and-family-module';
import PrivacyPolicy from './components/legal/privacypolicy';
import Copyright from './components/legal/copyright';
import TermsOfUse from './components/legal/termsofuse';

const AuthGuardsController = React.lazy(() =>
  import('./v2/core/auth-guards').then((module) => ({ default: module.AuthGuardsController }))
);

//this provides a list of static routes to be used by App.js and server.js to populate routes with server side rendering
export const SuperAdminRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/home" />,
    exact: true,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/locations',
    component: Locations,
  },
  {
    path: '/users',
    exact: true,
    component: Users,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/users/learner/:id',
    component: Learner,
  },
  {
    path: '/users/:id',
    exact: true,
    component: Profile,
  },
  {
    path: '/incentives',
    component: Incentives,
  },
  {
    path: '/manage',
    component: Management,
  },
  {
    path: '/manageRoles',
    component: ManageRoleAndPermission,
    exact: true,
  },
  {
    path: '/manageRoles/:roleName/:roleId',
    component: RolesDetail,
  },
  {
    path: '/messages',
    exact: true,
    component: Message,
  },
  {
    path: '/messages/:location/:threadId',
    exact: true,
    component: Message,
  },
  {
    path: '/manage/:location',
    component: Management,
  },
  {
    path: '/manage/:location/form',
    component: Management,
  },
  {
    path: '/outcomes-and-progress',
    component: Locations,
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId',
    component: Facility,
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId/:unitName/:unitId',
    component: Unit,
  },
  {
    path: '/checkevents',
    component: CheckEvents,
  },
  {
    path: '/roles',
    component: NotFound,
  },
  {
    path: '/todos',
    exact: true,
    component: Todos,
    loadData: () => loadData('people'),
  },
  {
    path: 'useragreement',
    exact: true,
    component: UserAgreement,
  },
  {
    path: '/manageLocations/:location',
    component: LocationSettings,
    exact: true,
  },
  {
    path: '/manageLocations',
    component: ManageLocations,
    exact: true,
  },
  {
    path: '/voiceCalls',
    component: ContactsListContainer,
    exact: true,
  },
  {
    path: '/voiceCalls/learnerProfile',
    component: LearnerProfileContainer,
    exact: true,
  },
  {
    path: '/superadmin',
    component: SuperAdminModule,
    exact: true,
  },
  {
    path: '/ffMessaging',
    component: FriendsAndFamilyModule,
    exact: true,
  },
  {
    component: NotFound,
  },
];

export const AdminRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/home" />,
    exact: true,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/locations',
    component: Locations,
  },
  {
    path: '/users',
    exact: true,
    component: Users,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/users/learner/:id',
    component: Learner,
  },
  {
    path: '/users/:id',
    exact: true,
    component: Profile,
  },
  {
    path: '/incentives',
    component: Incentives,
  },
  {
    path: '/manage',
    component: Management,
  },
  {
    path: '/messages',
    exact: true,
    component: Message,
  },
  {
    path: '/messages/:location/:threadId',
    exact: true,
    component: Message,
  },
  {
    path: '/manage/:location',
    component: Management,
  },
  {
    path: '/manage/:location/form',
    component: Management,
  },
  {
    path: '/outcomes-and-progress',
    component: Locations,
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId',
    component: Facility,
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId/:unitName/:unitId',
    component: Unit,
  },
  {
    path: '/checkevents',
    component: CheckEvents,
  },
  {
    path: '/roles',
    component: NotFound,
  },
  {
    path: '/todos',
    exact: true,
    component: Todos,
    loadData: () => loadData('people'),
  },
  {
    path: 'useragreement',
    exact: true,
    component: UserAgreement,
  },
  {
    path: '/voiceCalls',
    component: ContactsListContainer,
    exact: true,
  },
  {
    path: '/voiceCalls/learnerProfile',
    component: LearnerProfileContainer,
    exact: true,
  },

  {
    path: '/manageLocations/:location',
    component: LocationSettings,
    exact: true,
  },
  {
    path: '/manageLocations',
    component: ManageLocations,
    exact: true,
  },
  {
    component: NotFound,
  },
];

export const AttorneyRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/home" />,
    exact: true,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/messages',
    exact: true,
    component: Message,
  },
  {
    path: '/messages/:location/:threadId',
    exact: true,
    component: Message,
  },
  {
    path: 'useragreement',
    exact: true,
    component: UserAgreement,
  },
  {
    component: NotFound,
  },
];

export const TeacherRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/home" />,
    exact: true,
  },
  {
    path: '/messages',
    exact: true,
    component: Message,
  },
  {
    path: '/messages/:location/:threadId',
    exact: true,
    component: Message,
  },
  {
    path: 'useragreement',
    exact: true,
    component: UserAgreement,
  },
  {
    component: NotFound,
  },
];

export const PermittedRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/home" />,
    exact: true,
  },
  {
    path: '/home',
    exact: true,
    component: Home,
  },
  {
    path: '/analytics',
    exact: true,
    component: SisenseDashboard,
    permissions: ['View_Analytics', 'View_Progress_And_Outcomes'],
  },
  {
    path: '/voiceCalls',
    component: ContactsListContainer,
    exact: true,
  },
  {
    path: '/voiceCalls/learnerProfile',
    component: LearnerProfileContainer,
    exact: true,
  },
  {
    path: '/locations',
    component: Locations,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/users',
    exact: true,
    component: Users,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/users/learner/:id',
    component: Learner,
  },
  {
    path: '/users/:id',
    exact: true,
    component: Profile,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/incentives',
    component: Incentives,
  },
  {
    path: '/manage',
    component: Management,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/manageRoles',
    component: ManageRoleAndPermission,
    exact: true,
    roleTypes: [ROLES.SUPER_ADMIN],
  },
  {
    path: '/manageRoles/:roleName/:roleId',
    component: RolesDetail,
    roleTypes: [ROLES.SUPER_ADMIN],
  },
  {
    path: '/messages',
    exact: true,
    component: Message,
    permissions: ['Access_Messages'],
  },
  {
    path: '/messages/:location/:threadId',
    exact: true,
    component: Message,
    permissions: ['Access_Messages'],
  },
  {
    path: '/manage/:location',
    component: Management,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/manage/:location/form',
    component: Management,
    permissions: ['Access_User_And_Location_Management'],
  },
  {
    path: '/outcomes-and-progress',
    component: Locations,
    permissions: ['View_Progress_And_Outcomes'],
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId',
    component: Facility,
    permissions: ['View_Aggregate_Data_At_Facility'],
    exact: true,
  },
  {
    path: '/outcomes-and-progress/:locationName/:locationId/:unitName/:unitId',
    permissions: [['View_Aggregate_Data_At_Facility', 'View_Aggregate_Data_At_Hu']],
    component: Unit,
  },
  {
    path: '/checkevents',
    component: CheckEvents,
  },
  {
    path: '/roles',
    component: NotFound,
  },
  {
    path: '/todos',
    exact: true,
    component: Todos,
    loadData: () => loadData('people'),
  },
  {
    path: 'useragreement',
    exact: true,
    component: UserAgreement,
  },
  {
    path: '/manageLocations/:location',
    component: LocationSettings,
    exact: true,
  },
  {
    path: '/manageLocations',
    component: ManageLocations,
    exact: true,
  },
  {
    path: '/resume/:id',
    component: ResumeBuilder,
    exact: true,
    roleTypes: [ROLES.APDS_HORIZON_SUPER_ADMIN, ROLES.APDS_HORIZON_FACILITY_ADMIN, ROLES.SUPER_ADMIN, ROLES.STAFF, ROLES.TEACHER],
  },
  {
    path: '/superadmin',
    component: SuperAdminModule,
    exact: true,
  },
  {
    path: '/ffMessaging',
    component: FriendsAndFamilyModule,
    exact: true,
  },
  {
    component: NotFound,
  },
];

export const getPublicRoutes = () => {
  return [
    {
      path: '/useragreement',
      component: UserAgreement,
      exact: true,
    },
    {
      path: '/termsofuse',
      component: TermsOfUse,
      exact: true,
    },
    {
      path: '/copyright',
      component: Copyright,
      exact: true,
    },
    {
      path: '/privacypolicy',
      component: PrivacyPolicy,
      exact: true,
    },
    {
      path: '/auth-guards',
      component: AuthGuardsController,
      exact: true,
    },
  ];
};

export default SuperAdminRoutes;
