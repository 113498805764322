import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React from 'reactn';
import styles from './learner-management.component.style';
import useLearnerManagementComponentHook from './useLearnerManagementComponentHook';
import { Autocomplete, Modal, SnackBar, Typography } from '@orijinworks/frontend-commons';
import VoiceIdListeningModalComponent from '../voice-id-listening-modal/voice-id-listening-modal.component';

const LearnerManagementComponent = ({
  activeTab,
  locationId,
  classes,
  isDataRefresh,
  setDataRefresh,
  shouldExportCallHistory,
  setShouldExportCallHistory,
}) => {
  const {
    tableColumns,
    isLoading,
    pageSize,
    openSnackbar,
    snackbarAutoHideDuration,
    handleCloseSnackbar,
    snackbarMessage,
    isSuccessSnackbar,
    getLearnersList,
    tableRef,
    showScheduleAssignmentModal,
    setShowScheduleAssignmentModal,
    showRerecordConfirmationModal,
    setShowRerecordConfirmationModal,
    scheduleList,
    selecetedSchedule,
    submitScheduleChange,
    submitRerecordVoiceProfile,
    snackbarConfig,
    setSnackbarConfig,
    selectedLearner,
    disableActionButtons,
    handleScheduleChange,
    voiceProfileModal,
    handleClose,
    handleReRecordVoiceID,
    showEnableVrsConfirmationModal,
    setShowEnableVrsConfirmationModal,
    toggleVRSFlag
  } = useLearnerManagementComponentHook(
    activeTab,
    locationId,
    classes,
    isDataRefresh,
    setDataRefresh,
    shouldExportCallHistory,
    setShouldExportCallHistory
  );

  const tableColumnsRef = React.useRef(tableColumns);

  return (
    <>
      <SnackBar
        isShown={snackbarConfig.showSnackbar}
        type={snackbarConfig.snackbarType}
        onClose={() => {
          setSnackbarConfig((prev) => ({ ...prev, showSnackbar: false }));
        }}
      >
        {snackbarConfig.message}
      </SnackBar>

      {locationId ? (
        <MaterialTable
          tableRef={tableRef}
          data-id="contacts-table"
          columns={tableColumnsRef.current}
          title={''}
          data={getLearnersList}
          options={{
            filtering: true,
            pageSize: pageSize,
            search: false,
            thirdSortClick: false,
            debounceInterval: 1000,
          }}
        />
      ) : null}
      <Snackbar id="snackbar" open={openSnackbar} autoHideDuration={snackbarAutoHideDuration} onClose={handleCloseSnackbar}>
        <MuiAlert
          id="snackbar-alert"
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={isSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {showScheduleAssignmentModal && (
        <Modal
          id="schedule-assignment-modal"
          open={showScheduleAssignmentModal}
          onClose={() => {
            setShowScheduleAssignmentModal(false);
          }}
          onContinue={submitScheduleChange}
          continueButtonProps={{ disabled: disableActionButtons }}
          actionBtnText="Apply"
          cancelBtnText="Cancel"
          heading="Change Schedule"
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
        >
          <Typography id="schedule-change-label" variant="body1" className={classes.scheduleAutoCompleteTypography}>
            You are about to change schedule for <b>{selectedLearner.learnerName}</b>. Please select what schedule you would
            like to assign.
          </Typography>

          <Autocomplete
            id="schedule-assignment-autocomplete"
            className={classes.scheduleAutoComplete}
            value={selecetedSchedule}
            label="Select Schedule"
            options={scheduleList}
            getOptionLabel={(option) => option.scheduleName}
            renderOption={(props, option) => (
              <li {...props}>
                {option.isDefault ? <b>{`*Default Schedule (${option.scheduleName})`}</b> : option.scheduleName}
              </li>
            )}
            onChange={handleScheduleChange}
          />
        </Modal>
      )}

      {showRerecordConfirmationModal && (
        <Modal
          id="re-record-confirmation-modal"
          open={showRerecordConfirmationModal}
          onClose={() => {
            setShowRerecordConfirmationModal(false);
          }}
          continueButtonProps={{ disabled: disableActionButtons }}
          onContinue={submitRerecordVoiceProfile}
          actionBtnText="Confirm"
          cancelBtnText="Cancel"
          heading="Re-record Voice ID"
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
        >
          <Typography id="schedule-change-label" variant="body1" className={classes.scheduleAutoCompleteTypography}>
            Are you sure you want to require <b>{selectedLearner.learnerName}</b> to re-record their Voice ID?
            <br />
            <br />
            This action will prompt the resident to create a new Voice ID before they can make calls. The resident will receive a notification to complete this process.
          </Typography>

        </Modal>
      )}

      {showEnableVrsConfirmationModal && 
        <Modal
          id="enable-vrs-confirmation-modal"
          open={showEnableVrsConfirmationModal}
          onClose={() => {
            setShowEnableVrsConfirmationModal(false);
          }}
          continueButtonProps={{ disabled: disableActionButtons }}
          onContinue={toggleVRSFlag}
          actionBtnText="Confirm"
          cancelBtnText="Cancel"
          heading="Enable Video Relay Service"
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
       >
          <Typography id="enable-vrs-confirmation-text" variant="body1" className={classes.scheduleAutoCompleteTypography}>
            Are you sure you want to enable Video Relay Service (VRS) for {selectedLearner.learnerName}?
            <br />
            <br />
            This action will allow the resident to connect calls through a video interpreter who relays messages between sign language and spoken communication. 
          </Typography>

      </Modal>
       
      }

      {voiceProfileModal && (
        <>
          <VoiceIdListeningModalComponent
            voiceIDOpen={voiceProfileModal}
            handleClose={handleClose}
            handleReRecordVoiceID={handleReRecordVoiceID}
            learnerName={selectedLearner.learnerName}
            learnerId={selectedLearner.learnerId}
            voiceProfileId={selectedLearner.voiceProfileId}
          />
        </>
      )}
    </>
  );
};
export default withStyles(styles)(LearnerManagementComponent);
