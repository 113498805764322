export default theme => ({
  capitalize: {
    textTransform: 'capitalize'
  },
  success: {
    color: theme.status.success
  },
  danger: {
    color: theme.status.danger
  },
  warning: {
    color: theme.status.warning
  },
  pending: {
    color: 'orange'
  },
  exportButton:{
    color: theme.palette.button.blue.default,
    border: `1px solid ${theme.palette.button.blue.active}`,
  }
});
