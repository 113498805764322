import React from 'react';
import useUnleashHook from './useUnleashHook';

/**
 * @name withUnleash
 * @param {component} WrappedComponent
 * @param {string} flagName
 * @description - HOC to attach unleash props to a component
 * @returns WrappedComponent with unleash props
 */

const withUnleash = (WrappedComponent, flagName) => {
  const HOC = (props) => {
    const { isFlagEnabled, getClient, getVariant, getFlagsStatus } = useUnleashHook(flagName);
    return (
      <WrappedComponent
        {...props}
        unleashProps={{
          isFlagEnabled,
          getClient,
          getVariant,
          getFlagsStatus,
        }}
      />
    );
  };

  return HOC;
};

export default withUnleash;
