import { useUnleashClient } from '@unleash/proxy-client-react';

/**
 * Custom hook to get the unleash client
 *
 * @returns {function} getClient - The function to get the unleash client
 */
const useUnleashClientHook = () => {
  const unleashClient = useUnleashClient();

  /**
   * Function to get the unleash client
   *
   * @returns {object} unleashClient - The unleash client
   */
  const getClient = () => unleashClient;

  return { getClient };
};

export default useUnleashClientHook;
