export default (theme) => ({
  breadcrumb: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
  },
  breadcrumbLink: {
    cursor: 'pointer',
  },
  root: {
    width: '100%',
    maxWidth: '1000px',
    marginLeft: 0,
    padding: theme.spacing(3),
  },
  addProgramtext:{
    color: theme.palette.primary.navy,
  },
  backButton: {
    marginBottom: theme.spacing(4),
    gap: theme.spacing(2),
    '& h4': {
      fontSize: '32px',
      fontWeight: 800,
    },
  },

  arrowBackButton:{
    border: `1px solid ${theme.palette.colors.aquaDeep}`,
  },
  arrowBackIcon: {
    color: theme.palette.colors.aquaDeep,
    fontSize: 24,
    marginRight: '0px !important',
  },
  coursesSection: {
    marginTop: theme.spacing(6),
  },
  courseListHelper: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontStyle: 'italic',
    marginBottom: theme.spacing(2),
  },
  addCourseButton: {
    marginTop: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
    '& .MuiButton-root': {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '14px',
      padding: '8px 24px',
    },
    '& .MuiButton-containedSecondary': {
      backgroundColor: theme.palette.colors.redMain,
      '&:hover': {
        backgroundColor: theme.palette.colors.redDark,
      }
    },
    '& .MuiButton-outlined': {
      borderColor: 'black',
      color: 'black',
      '&:hover': {
        borderColor: 'black',
      }
    }
  },
});
