import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './contacts-tab.component.style';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { FolderNav as FolderNavComponent } from '@orijinworks/frontend-commons';
import { CallOutlined, ContactsOutlined, HistoryOutlined, PeopleOutline, SettingsInputComponent } from '@material-ui/icons';

const ContactsTabComponent = ({ activeTab, setActiveTab, classes }) => {
  const tabs = [
    {
      label: 'Live Calls',
      value: VOICE_CALL.TABS.LIVE_CALLS,
      component: () => <></>,
      Icon: CallOutlined,
    },
    {
      label: 'Call History',
      value: VOICE_CALL.TABS.CALL_HISTORY,
      component: () => <></>,
      Icon: HistoryOutlined,
    },
    {
      label: 'All Contacts',
      value: VOICE_CALL.TABS.ALL_CONTACTS,
      component: () => <></>,
      Icon: ContactsOutlined,
    },
    {
      label: 'Resident Management',
      value: VOICE_CALL.TABS.RESIDENT_MANAGEMENT,
      component: () => <></>,
      Icon: PeopleOutline,
    },
    {
      label: 'Communications Settings',
      value: VOICE_CALL.TABS.SETTINGS,
      component: () => <></>,
      Icon: SettingsInputComponent,
    },
  ];
  return (
    <Grid container item component="div" className={classes.content}>
      <Grid container item style={{ overflow: 'auto' }} xs={12}>
        <Box className={classes.folderWrapper}>
          <FolderNavComponent
            defaultActiveTab={activeTab}
            color="var(--blue-main)"
            tabs={tabs}
            onTabChange={(event, value) => setActiveTab(value)}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ContactsTabComponent);
