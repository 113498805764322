
/* eslint-disable import/no-anonymous-default-export */
export default theme => ({
  content: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px'
    },
    paddingBottom: 24,
  },
  folderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F8F9FC',
    '& .MuiBox-root': {
        '& .MuiPaper-root': {
            padding: 0,
        }
    },
    '& > div > div > div > .Mui-selected': {
        '& svg': {
            fill: 'var(--blue-main)',
        }
    },
    '& > div > div > div': {
        '& svg': {
            marginRight: 5,
        }
    }
}
  
});
