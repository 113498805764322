 import MaterialTable from 'material-table';
import React from 'reactn';
import { useCallHistoryListComponentHook } from './useCallHistoryListComponentHook';
import styles from './call-history.component.style';
import { withStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Box, Button, Snackbar } from '@material-ui/core';
import CallHistoryDetailsDrawer from '../call-history-details-v2/call-history-details-v2';
import CallHistoryDetailModal from '../call-history-details-modal/call-history-details-modal';
import { UnleashService } from '../../../../v2/services';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import useUnleashHook from '../../../../v2/core/unleash/useUnleashHook';


export const CallHistoryList = (props) => {
  const isKeywordFlagEnabled = useUnleashHook(UnleashService.FLAGS.KEYWORD_MATCH_CONFIG).isFlagEnabled();
  const {
    tableRef,
    tableColumns,
    defaultPageSize,
    openSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal,
    callDetails,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
    recordingUrl,
    handleCloseSnackbar,
    getCallHistoryListData,
    isPrivileged,
  } = useCallHistoryListComponentHook(props, isKeywordFlagEnabled);

  const tableColumnsRef = React.useRef(tableColumns);
  const isFlagEnabled = useUnleashHook(UnleashService.FLAGS.CALL_HISTORY_LIVE_CALLS_DESIGN_V2).isFlagEnabled();
  return (
    <>
      {isFlagEnabled ? (
        <CallHistoryDetailsDrawer
          isDetailModalOpen={isDetailModalOpen}
          handleCloseHistoryDetailsModal={handleCloseHistoryDetailsModal}
          {...callDetails}
          recordingUrl={recordingUrl}
          isPrivileged={isPrivileged}
          transcriptData={transcriptData}
          transcriptSummary={transcriptSummary}
          detectedKeywords={detectedKeywords}
        />
      ) : (
        <CallHistoryDetailModal
          isDetailModalOpen={isDetailModalOpen}
          handleCloseHistoryDetailsModal={handleCloseHistoryDetailsModal}
          {...callDetails}
          recordingUrl={recordingUrl}
          isPrivileged={isPrivileged}
          transcriptData={transcriptData}
          transcriptSummary={transcriptSummary}
        />
      )}
          <Box
            id="call-history-export-button"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '16px 0px',
              justifyContent: 'flex-end',
              gap: '16px',
              height: '40px',
              width: '100%',
            }}
          >
            <Button
              id="export-button"
              variant='contained' 
              color="primary"
              onClick={() => props.setShouldExportCallHistory(true)}
              startIcon={<CustomIcons icon={icons.FileDownload} />}
            >
              Export Call History
            </Button>
          </Box>
      
      {props.locationId ? (
        <MaterialTable
          tableRef={tableRef}
          columns={tableColumnsRef.current}
          data={getCallHistoryListData}
          options={{
            filtering: true,
            pageSize: defaultPageSize,
            thirdSortClick: false,
            search: false,
            toolbar: false,
          }}
        />
      ) : null}

      <Snackbar
        id={'call-history-snackbar'}
        open={openSnackbar}
        autoHideDuration={snackbarAutoHideDuration}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={isSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(CallHistoryList);
