import React from 'react';
import useUnleashClientHook from './useUnleashClientHook';

/**
 * HOC to attach the unleash client to the wrapped component.
 * Can be used to check the status of multiple unleash feature
 * flags in the wrapped component.
 *
 * @param {component} WrappedComponent - The component to wrap
 * @returns {component} HOC - The higher order component
 */
const withUnleashClient = (WrappedComponent) => {
  const HOC = (props) => {
    const { getClient } = useUnleashClientHook();

    return <WrappedComponent {...props} unleashClient={getClient} />;
  };

  return HOC;
};

export default withUnleashClient;
