const styles = (theme) => ({
  content: {
    padding: theme.spacing(3),
    height: '100%',
    overflow: 'auto'
  },
  addButton: {
    marginBottom: theme.spacing(2)
  },
  tableHeader: {
    '& thead tr th': {
      paddingTop: theme.spacing(1.5), // 12px
      paddingBottom: theme.spacing(1.5)
    }
  },
  breadcrumbLink: {
    color: theme.palette.colors.aquaDeep,
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  breadcrumbCurrent: {
    backgroundColor: theme.palette.colors.aquaDeep,
    color: 'white',
    padding: '4px 12px',
    borderRadius: '100px',
    fontWeight: 700
  },
  formPaper: {
    padding: theme.spacing(3)
  },
  uploadArea: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    marginTop: theme.spacing(2)
  }
});

export default styles;
