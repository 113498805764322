export default (theme) => ({
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldLabel: {
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.colors.lightCerulean,
    letterSpacing: '1.8px',
  },

  uploadArea: {
    padding: theme.spacing(4),
    border: `1px dashed ${theme.palette.colors.grey100}`,
    borderRadius: '10px',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
    transition: 'border-color 0.2s ease',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },

  uploadedImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },

  uploadedImageInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: theme.spacing(2),
  },

  uploadedImage: {
    width: '200px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },

  uploadedImageName: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  deleteButton: {
    border: `1px solid ${theme.palette.colors.redMain}`,
  },
  deleteIcon: {
    color: theme.palette.colors.redMain,
    fontSize: 24,
    marginRight: '0px !important',
  },

  uploadText: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.black,
    fontSize: '16px',
    fontWeight: 700,
  },
  uploadSubText: {
    color: theme.palette.colors.grey100,
    fontSize: '16px',
    marginBottom: theme.spacing(0.5),
    fontStyle: 'italic',
    fontWeight: 400,
  },
  uploadFileTypes: {
    color: theme.palette.colors.grey100,
    fontSize: '16px',
    marginBottom: theme.spacing(0.5),
    fontWeight: 400,

  },
  selectFileButton: {
    marginTop: theme.spacing(2),
    textTransform: 'none',
    color: theme.palette.colors.bluePrimaryMain,
    fontWeight: 500,
    border: `1px solid ${theme.palette.colors.bluePrimaryMain}`,
  },
});
