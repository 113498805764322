import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import styles from './metadata-select.style';

const MetadataSelect = ({
  classes,
  label,
  helperText,
  name,
  value,
  onChange,
  options,
optionLabels,
}) => (
  <Box className={classes.metadataField}>
    <Box className={classes.metadataLabelGroup}>
      <Typography data-testid="metadata-label" className={classes.metadataLabel}>{label}</Typography>
      <Typography data-testid="metadata-helper" className={classes.metadataHelper}>
        {helperText}
      </Typography>
    </Box>
    <FormControl fullWidth variant="outlined" className={classes.metadataSelect}>
      <InputLabel data-testid="select-label">{label}</InputLabel>
      <Select
        data-testid="metadata-select"
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {optionLabels ? optionLabels[option] : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

MetadataSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionLabels: PropTypes.object,
};

export default withStyles(styles)(MetadataSelect);
