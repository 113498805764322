import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POLL_CONFIG, SOCKET_EVENT_TYPES } from '../../../constants';
import {
  rsSetAnnouncements,
  rsSetDirectMessages,
  rsSetUnReadMsgAnnouCount,
  setUnreadCountByLocation,
} from '../../core/store/reducers/messageModuleReducer';
import { registerAction } from '../../socket';
import { MessageService } from '../../services/message.service';
import { emitMessageEvent } from '../../../util/Helpers';


/**
 * isContactSelected
 * @param {number} threadId thread ID in iteration
 * @param {object} selectedContact Contact that is selected
 * @return {Boolean}
 */
const isContactSelected = (threadId, selectedContact) => {
  return selectedContact && selectedContact.threadId === threadId;
};
/**
 * A method responsible to increase the unread count of the message
 * in redux store
 * @param {*} data
 */
const incrementUnreadCount = (data, rsMessageModuleStore, dispatch) => {
  const {
    rsUnreadMsgAnnoCountObj = {},
    rsMessageThread = [],
    rsAnnouncementsThread = [],
    rsSelectedContact,
  } = rsMessageModuleStore;
  let unreadCount = { ...rsUnreadMsgAnnoCountObj };
  if (data.type === SOCKET_EVENT_TYPES.MESSAGE) {
    rsMessageThread.map((item) => {
      if (item.threadId === data.threadId && !isContactSelected(data.threadId, rsSelectedContact)) {
        unreadCount.totalUnreadCount += 1;
        item.unreadMessageCount = 1;
      }
    });
    dispatch(rsSetDirectMessages({ rsMessageThread }));
  } else if (data.type === SOCKET_EVENT_TYPES.ANNOUNCEMENT) {
    rsAnnouncementsThread.map((item) => {
      if (item.locationId === data.locationKey && !Boolean(item.unreadAnnouncementCount)) {
        unreadCount.totalUnreadAnnouncementCount += 1;
        item.unreadAnnouncementCount = 1;
      }
    });
    dispatch(rsSetAnnouncements({ rsAnnouncementsThread }));
  }

  dispatch(rsSetUnReadMsgAnnouCount({ rsUnreadMsgAnnoCountObj: unreadCount }));
};

/**
 * Increment message count globally
 * @param {*} data
 * @return {void}
 */
const updateMessageCountInReducer = (data, rsMessageModuleStore, dispatch) => {
  const {body} = data;
  const {
    unreadCountByLocations,
    rsSelectedContact
  } = rsMessageModuleStore;
  if (!isContactSelected(data.threadId, rsSelectedContact)) {
    const locationIndex = unreadCountByLocations.findIndex(
      (item) => item.locationKey === body.learnerLocationId
    ); 
    if (locationIndex > -1) {
      unreadCountByLocations[locationIndex].totalUnreadCount += 1;
    } else {
      unreadCountByLocations.push({
        locationKey: body.learnerLocationId,
        totalUnreadCount: 1,
      });
    }
    dispatch(setUnreadCountByLocation([...unreadCountByLocations]));
  }


}

/**
 * A callback method to handle the socket event on NEW STAFF MESSAGING
 * @param {*} data
 * @param {*} rsMessageModuleStore
 * @param {*} dispatch
 */
const newMessage = (data, rsMessageModuleStore, dispatch) => {
  updateMessageCountInReducer(data, rsMessageModuleStore, dispatch);
  incrementUnreadCount(data, rsMessageModuleStore, dispatch);
  emitMessageEvent(data);
};

const useStaffMessageSocket = () => {
  const dispatch = useDispatch();
  const rsMessageModuleStore = useSelector((state) => {
    return state.rsMessageModuleStore;
  });
  React.useEffect(() => {
    if (!POLL_CONFIG.USE_POLLING) {
      registerAction({
        event: 'new_staff_message',
        action: (data) => newMessage(data, rsMessageModuleStore, dispatch),
      });
    }
  });
};

export default useStaffMessageSocket;
