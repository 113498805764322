import React from 'react';
import { Box, Typography, withStyles, Grid, Divider, Switch, Snackbar } from '@material-ui/core';
import styles from './individual-learner-settings.style';
import PersonIcon from '@mui/icons-material/Person';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useContactManagementHook } from '../../../contact-management/useContactManagementHook';
import { Stepper } from '@orijinworks/frontend-commons';
import { VOICE_CALL } from '../../../../constants/voice-call-constants';

const IndividualLearnerSettings = ({ classes, locationId }) => {
  const {
    handleAdminCanApproveContactsAttributeChange,
    adminCanApproveContactsAttribute,
    handleAllowContactRequestsAttributeChange,
    allowContactRequestsAttribute,
    handleIsMaxContactsPerLearnerLimitEnabledChange,
    isMaxContactsPerLearnerLimitEnabled,
    maxContactsPerLearnerLimit,
    handleMaxContactsPerLearnerLimitChange_V2,
    snackbarMessage,
    snackbarOpen,
    handleCloseSnackbar,
    isSuccessSnackbar,
  } = useContactManagementHook({
    locationId,
  });

  return (
    <>
      {(Object.keys(adminCanApproveContactsAttribute).length > 0 ||
        Object.keys(isMaxContactsPerLearnerLimitEnabled).length > 0 ||
        Object.keys(allowContactRequestsAttribute).length > 0) && (
        <>
          <Grid item xs={12}>
            <Box id="header" className={classes.header}>
              <PersonIcon className={classes.icon} />
              <Typography className={classes.individualLearnerSettingsHeading} variant="h2">
                Individual Resident Settings
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.dividerRow}>
              <Divider id="divider" className={classes.divider}></Divider>
            </Box>
          </Grid>
          {allowContactRequestsAttribute && Object.keys(allowContactRequestsAttribute).length > 0 && (
            <>
              <Grid item xs={12} className={classes.row}>
                <Box className={classes.stableTableCellOne}>
                  <Typography id="allow-contact-requests-heading" variant="body1" className={classes.label}>
                    Allow Contact Requests
                  </Typography>
                  <Typography variant="body2" className={classes.subText}>
                    Hides the contact request form from residents so they can't add their own contacts.
                  </Typography>
                </Box>

                <Box className={classes.stableTableCellTwo}>
                  <Switch
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: allowContactRequestsAttribute.attributeValue ? classes.trackOn : classes.trackOff,
                      checked: classes.checked,
                    }}
                    inputProps={{ 'aria-label': 'allow contact requests' }}
                    id="allow-contact-requests-switch"
                    onChange={(e) => handleAllowContactRequestsAttributeChange(e)}
                    checked={allowContactRequestsAttribute.attributeValue}
                    name="checkedC"
                    icon={<CancelRoundedIcon className={classes.thumbIcon} />}
                    checkedIcon={<CheckCircleIcon className={classes.thumbIcon} />}
                  />
                </Box>
              </Grid>
            </>
          )}

          {Object.keys(adminCanApproveContactsAttribute).length > 0 && allowContactRequestsAttribute.attributeValue && (
            <>
              <Grid item xs={12} className={classes.row}>
                <Box className={classes.stableTableCellOne}>
                  <Typography id="approval-status-required-heading" variant="body1" className={classes.label}>
                    Require Contact Approval by Admins
                  </Typography>
                  <Typography variant="body2" className={classes.subText}>
                    Contact requests must be approved by admins before calls may occur.
                  </Typography>
                </Box>

                <Box className={classes.stableTableCellTwo}>
                  <Switch
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: adminCanApproveContactsAttribute.attributeValue ? classes.trackOn : classes.trackOff,
                      checked: classes.checked,
                    }}
                    inputProps={{ 'aria-label': 'limit contact numbers' }}
                    id="admin-contact-approval-switch"
                    onChange={handleAdminCanApproveContactsAttributeChange}
                    checked={adminCanApproveContactsAttribute.attributeValue}
                    name="checkedA"
                    icon={<CancelRoundedIcon className={classes.thumbIcon} />}
                    checkedIcon={<CheckCircleIcon className={classes.thumbIcon} />}
                  />
                </Box>
              </Grid>
            </>
          )}

          {Object.keys(isMaxContactsPerLearnerLimitEnabled).length > 0 && (
            <>
              <Grid item xs={12} className={classes.row}>
                <Box className={classes.stableTableCellOne}>
                  <Typography id="max-contacts-per-learner-enabled-heading" variant="body1" className={classes.label}>
                    Limit Number of Contacts
                  </Typography>
                  <Typography variant="body2" className={classes.subText}>
                    Limits do not apply to privileged contacts.
                  </Typography>
                </Box>

                <Box id="limit-contact-number" className={classes.stableTableCellTwo}>
                  <Switch
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: isMaxContactsPerLearnerLimitEnabled.attributeValue ? classes.trackOn : classes.trackOff,
                      checked: classes.checked,
                    }}
                    inputProps={{ 'aria-label': 'limit contact numbers' }}
                    id="max-contacts-per-learner-enabled-switch"
                    onChange={handleIsMaxContactsPerLearnerLimitEnabledChange}
                    checked={isMaxContactsPerLearnerLimitEnabled.attributeValue}
                    name="checkedB"
                    icon={<CancelRoundedIcon className={classes.thumbIcon} />}
                    checkedIcon={<CheckCircleIcon className={classes.thumbIcon} />}
                  />
                </Box>
              </Grid>
              {isMaxContactsPerLearnerLimitEnabled && isMaxContactsPerLearnerLimitEnabled.attributeValue === true && (
                <>
                  <Grid item  xs={12} className={classes.row}>
                    <Box id="max-contacts-per-learner-enabled-heading" className={classes.stableTableCellOne}>
                      <Typography variant="body1" className={classes.label}>
                        Max Number of Contacts
                      </Typography>
                      <Typography variant="body2" className={classes.subText}>
                        Changing this limit may prompt residents to archive existing contacts to meet the new requirement.
                      </Typography>
                    </Box>

                    {Object.keys(maxContactsPerLearnerLimit).length > 0 && (
                      <>
                        <Box className={classes.stableTableCellTwo}>
                          <Stepper
                            id="max-contacts-per-learner-limit"
                            value={maxContactsPerLearnerLimit.attributeValue}
                            maxValue={VOICE_CALL.MAX_NO_OF_CONTACTS_PER_LEARNER}
                            minValue={VOICE_CALL.MIN_NO_OF_CONTACTS_PER_LEARNER}
                            step={1}
                            onChange={handleMaxContactsPerLearnerLimitChange_V2}
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}


        </>
      )}

      <Snackbar
        id="individual-learner-settings-snackbar"
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={isSuccessSnackbar ? 'success' : 'error'}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(IndividualLearnerSettings);
