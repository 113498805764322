import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/core/styles';
import styles from './image-upload.style';

const ImageUpload = ({ classes, formData, handleImageUpload, handleImageDelete, getImageUrl }) => (
  <Box className={classes.fieldGroup}>
    <Typography className={classes.fieldLabel}>PROGRAM THUMBNAIL IMAGE</Typography>
    <Box 
      data-testid="upload-area"
      className={classes.uploadArea}
      onDrop={handleImageUpload}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
    >
      {formData.thumbnailImage ? (
        <Box className={classes.uploadedImageContainer}>
          <Box className={classes.uploadedImageInfo}>
            <img
              data-testid="uploaded-image"
              src={getImageUrl(formData.thumbnailImage)}
              alt="Thumbnail preview"
              className={classes.uploadedImage}
            />
            <Typography className={`${classes.uploadedImageName} uploadedImageName`}>
              {formData.thumbnailImage instanceof File ? formData.thumbnailImage.name : formData.thumbnailImageName}
            </Typography>
          </Box>
          <IconButton
            data-testid="image-delete-button"
            className={classes.deleteButton}
            aria-label="Delete"
            onClick={() => handleImageDelete()}
          >
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        </Box>
      ) : (
        <>
          <Typography data-testid="upload-text" className={classes.uploadText}>
            Select a file or drag and drop here
          </Typography>
          <Typography data-testid="file-types-text" className={classes.uploadFileTypes}>
            JPG, PNG or WebP, file size no more than 1 MB
          </Typography>
          <Typography data-testid="min-width-text" className={classes.uploadSubText}>
            Recommended minimum image width 800px
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            component="label"
            className={classes.selectFileButton}
          >
            Select File
            <input
              data-testid="file-input"
              type="file"
              hidden
              accept="image/jpeg,image/png,image/webp"
              onChange={(e) => handleImageUpload(e.target.files[0])}
            />
          </Button>
        </>
      )}
    </Box>
  </Box>
);

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  handleImageDelete: PropTypes.func.isRequired,
  getImageUrl: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageUpload);
